<template>
    <div>
        <div class="card card-custom mb-2">
            <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a 
                            class="nav-link active text-black f-12" 
                            id="document-tab" 
                            data-toggle="tab" 
                            href="#document" 
                            role="tab" 
                            aria-controls="document" 
                            aria-selected="true"
                            @click="activeState = 'Document'">
                            Type Document
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12" 
                            id="section-tab" 
                            data-toggle="tab" 
                            href="#section" 
                            role="tab" 
                            aria-controls="section" 
                            aria-selected="true"
                            @click="activeState = 'Section'">
                            Section
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12" 
                            id="dashboard-tab" 
                            data-toggle="tab" 
                            href="#dashboard" 
                            role="tab" 
                            aria-controls="dashboard" 
                            aria-selected="true"
                            @click="activeState = 'Dashboard'">
                            Dashboard
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12" 
                            id="pic-tab" 
                            data-toggle="tab" 
                            href="#pic" 
                            role="tab" 
                            aria-controls="pic" 
                            aria-selected="true"
                            @click="activeState = 'pic'">
                            PIC Report
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="document" role="tabpanel" aria-labelledby="document-tab">
                    <TabDocument v-if="activeState == 'Document'"/>
                </div>
                <div class="tab-pane fade show" id="section" role="tabpanel" aria-labelledby="section-tab">
                    <TabSection v-if="activeState == 'Section'"/>
                </div>
                <div class="tab-pane fade show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <TabDashboard v-if="activeState == 'Dashboard'"/>
                </div>
                <div class="tab-pane fade show" id="pic" role="tabpanel" aria-labelledby="pic-tab">
                    <TabPic v-if="activeState == 'pic'"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TabDocument from './tab-document/Index'
import TabSection from './tab-section/Index'
import TabDashboard from './tab-dashboard/Index'
import TabPic from './tab-pic/Index'

export default {
    components: {
        TabDocument,
        TabSection,
        TabDashboard,
        TabPic
    },
    data() {
        return {
            activeState: 'Document'
        }
    },
}
</script> 