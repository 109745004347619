<template>
    <div>
        <Table
            :withCard="false"
            :dataTable="table"
            :filter="true"
            :limit="true"
            :search="true"
            :exportExcel="downloadParams"
            :pagination="true">

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th>Document Number</th>
                        <th>Title</th>
                        <th v-for="(data, index) in maxColumn.type" :key="makeid(10)+index">{{ index == 0 ? 'Type' : 'Sub Type ' + index }}</th>
                        <th v-for="(data, index) in maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Originator</th>
                        <th>Position</th>
                        <th>Effective Date</th>
                        <th>Next Review Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:tbody>
                <tbody v-if="table.lists">
                    <tr v-for="(data, index) in table.lists.data" :key="makeid(10)+index">
                        <td class="text-primary font-600">
                            <u>{{ data.document_number }}-{{ numToSSColumn(data.initial) }}</u>
                        </td>
                        <td style="overflow-wrap: anywhere;">{{ data.title }}</td>
                        <td v-for="(dt, i) in maxColumn.type" :key="makeid(10)+i">
                            <span >
                                {{ JSON.parse(data.type)[i] ? JSON.parse(data.type)[i].code : '-' }}
                            </span>
                        </td>
                        <td v-for="(dt, i) in maxColumn.section" :key="makeid(10)+i">
                            <span >
                                {{ data.parent_section && JSON.parse(data.parent_section)[i] ? JSON.parse(data.parent_section)[i].code : '-' }}
                            </span>
                        </td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="originator in JSON.parse(data.originator)" :key="makeid(10)+originator.id">
                                    {{originator.user_detail}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <div v-for="originator in JSON.parse(data.originator)" :key="makeid(10)+originator.id">
                                <ul class="mb-0 pl-0" style="list-style: none;">
                                    <li v-for="position in JSON.parse(originator.position_id)" :key="makeid(10)+position.id">
                                        {{ position.position }}
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td>{{ data.effective_date | moment("YYYY/MM/DD") }}</td>
                        <td>{{ data.next_review_date | moment("YYYY/MM/DD") }}</td>
                        <td>
                            <div class="btn-action-block row">
                                <div class="col-6 p-0 text-right">
                                    <button class="btn p-0 f-18 text-success mr-1" title="Edit" @click="showForm(data)" v-if="!data.is_edit_disable && (loggedUser.level == data.creator_level || loggedUser.level == 'super admin')">
                                        <img :src="$siteUrl + '/assets/img/icon/edit.png'" alt="" class="img-fluid">
                                    </button>
                                </div>
                                <div class="col-6 p-0 text-left" v-if="loggedUser.level == data.creator_level || loggedUser.level == 'super admin'">
                                    <button class="btn p-0 f-18 text-danger" title="Terminate" @click="modelRegistration = data" data-toggle="modal" data-target="#modalTerminateConfirm" v-if="!data.is_terminated">
                                        <img :src="$siteUrl + '/assets/img/icon/trash.png'" alt="" class="img-fluid">
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </Table>

        <div class="modal fade text-left show" id="modalTerminateConfirm" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Notification</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <p class="f-16 font-400 text-center">Are you sure terminate this document?</p>
                        <div class="text-center">
                            <button class="btn btn-primary" data-dismiss="modal">No</button>
                            <button class="btn text-primary" data-dismiss="modal" data-toggle="modal" data-target="#modalTerminate">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalTerminate" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Notification</p>
                        <span class="pull-right pointer" data-dismiss="modal" id="closeModalTerminate"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <ValidationObserver ref="observer">
                            <form @submit.prevent="terminate()">
                                <ValidationProvider name="Terminate Date" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label for="">Terminate Date</label>
                                        <date-picker
                                            :disabled-date="disabledBeforeToday"
                                            format="YYYY/MM/DD"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Tanggal"
                                            input-class="form-control"
                                            class="w-100"
                                            v-model="terminateModel.terminate_date">
                                        </date-picker>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Reason Terminate" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label for="">Reason Terminate</label>
                                        <textarea cols="30" rows="5" class="form-control" v-model="terminateModel.reason"></textarea>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="text-center">
                                    <button class="btn btn-primary mr-1" data-dismiss="modal" type="button">Cancel</button>
                                    <button class="btn btn-outline-primary" type="submit">Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/TableTypeSection'

export default {
    components: { 
        Table 
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
                modul: 'report_ab',
                search: _.search,
                effective_date_start: _.filter.effective_date_start,
                effective_date_end: _.filter.effective_date_end,
                type: _.filter.type,
                group: _.filter.group
            }
            return data
        }
    },
    data() {
        return {
            table: {
                headers: ['Document Number', 'Title', '', '', 'Originator', 'Position', 'Reserve Date', 'Status', 'Action'],
                columns: ['document_number', 'title', 'type', 'section', 'originator', 'position', 'created_at', 'terminated_status'],
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                group: ''
            },
            maxColumn: {
                type: 1,
                section: 1
            },
            terminateModel: {},
            modelRegistration: {},
            loggedUser: JSON.parse(localStorage.getItem('user'))
        }
    },
    methods: {
        async terminate() {
            let _ = this

            const isValid = await this.$refs.observer.validate()
            if (!isValid){
                return;
            }

            let data = {
                edm_document_id: _.modelRegistration.id,
                terminate_date: _.terminateModel.terminate_date,
                reason: _.terminateModel.reason,
                initial: _.modelRegistration.initial
            }

            _.axios.post('/v2/document/terminate', data)
                .then( resp => {
                    console.log( resp.data )
                    window.$('#closeModalTerminate').click()
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: 'Successfully save data'
                    })
                    _.state = 'index'
                    _.$root.$emit('page', 1)
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        index() {
            let _ = this
            _.axios.get('/v2/document/report_ab' +
                    '?search=' + _.search + 
                    '&page=' + _.page + 
                    '&limit=' + _.limit +
                    '&effective_date_start=' + _.filter.effective_date_start +
                    '&effective_date_end=' + _.filter.effective_date_end +
                    '&type=' + _.filter.type +
                    '&group=' + _.filter.group
                )
                .then(resp=>{
                    _.table.lists = resp.data.data

                    _.maxColumn = {
                        type: 1,
                        section: 1
                    }

                    _.table.lists.data.forEach(elm => {
                        if ( _.maxColumn.type < JSON.parse(elm.type).length ) {
                            _.maxColumn.type = JSON.parse(elm.type).length
                        }

                        if ( _.maxColumn.section < JSON.parse(elm.parent_section).length ) {
                            _.maxColumn.section = JSON.parse(elm.parent_section).length
                        }
                    })

                    _.table.lists.data = _.table.lists.data.map(function( x ) {
                        return {
                            ...x,
                            initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                            effective_date: x.is_edit_disable == true ? x.effective_date_history : x.effective_date
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        showForm( data ) {
            let _ = this
            _.$root.$emit('showForm', true)
            _.$root.$emit('showFormRevision', data)
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    mounted() {
        let _ = this        
        _.index()
        _.$root.$on("search", (data) => {
            _.search = data
            _.index()
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.index()
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.index()
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.index()
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")
    },
}
</script>