<template>
    <div>
        <div class="card card-custom mb-2">
            <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a 
                            class="nav-link active text-black f-12" 
                            id="role-tab" 
                            data-toggle="tab" 
                            href="#role" 
                            role="tab" 
                            aria-controls="role" 
                            aria-selected="true"
                            @click="activeState = 'role'">
                            Role Management
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12" 
                            id="user-tab" 
                            data-toggle="tab" 
                            href="#user" 
                            role="tab" 
                            aria-controls="user" 
                            aria-selected="true"
                            @click="activeState = 'user'">
                            Add New User
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="role" role="tabpanel" aria-labelledby="role-tab">
                    <TabRole/>
                </div>
                <div class="tab-pane fade show" id="user" role="tabpanel" aria-labelledby="user-tab">
                    <TabUser/>
                </div>
            </div>
        </div>

        <UserList v-if="activeState == 'user'"/>
    </div>
</template>
<script>
import TabRole from './tab-role/Index'
import TabUser from './tab-user/Index'
import UserList from './tab-user/List'

export default {
    components: {
        TabRole,
        TabUser,
        UserList
    },
    data() {
        return {
            activeState: ''
        }
    },
}
</script> 