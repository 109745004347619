<template>
    <div class="row h-100">
        <div class="col-md-5 d-flex justify-content-center align-items-center">
            <img :src="$siteUrl + '/assets/img/sumco-sidebar.png'" alt="" class="img-fluid mb-4" style="position: absolute;top: 4%;left: 13%;">
            <div>
                <h1 style="font-size:38px" class="text-primary font-700">Selamat Datang di EDM</h1>
                <p class="f-16">Silahkan Login dengan Employee ID dan Password Anda.</p>
                <ValidationObserver ref="observer">
                    <form @submit.prevent="login()">
                        <ValidationProvider name="Employee ID" rules="required" v-slot="{ errors }">
                            <div class="form-group mt-4 mb-2" :class="{ 'validation-warning': errors[0] }">
                                <label for="">Employee ID</label>
                                <input type="text" class="form-control" v-model="model.email">
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                            <label>Password</label>
                            <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                <input :type="showPassword ? 'text' : 'password'" class="form-control border-right-0" v-model="model.password">
                                <span class="input-group-append">
                                    <div class="input-group-text">
                                        <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                        </a>
                                    </div>
                                </span>
                            </div>
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                        <button type="submit" class="btn btn-primary waves-effect waves-light w-100 mt-4" :disabled="isLoading">
                            <div v-if="isLoading">Logging In <i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Log In</div>
                        </button>
                        
                    </form>
                </ValidationObserver>

            <a :href="$siteUrl + '/version'" style="text-decoration: none;position: absolute;bottom: 20px;left: 80px;"><small>check version</small></a>
            </div>
        </div>
        <div
            class="col text-white"
            :style="`background-image: url('` + $siteUrl + `/assets/img/sumco-login.png'); `"
            style="
                padding: 7%;
                line-height: 22px; 
                background: #1D4E9E;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 50%;">
            <p class="font-700 mb-4 text-white" style="font-size: 38px;">Sekilas Tentang EDM</p>
            
            <p class="f-12 font-400 text-white">
                <span class="f-14 font-700"><i>EDM (Electronic Document Management)</i></span> 
                adalah system aplikasi berbasis intranet untuk management dokumen. EDM ini dikembangkan untuk memudahkan proses publikasi dokumen.
            </p>

            
                
                
                
                
                
                
                
                
                
                
                
            

            <p class="f-12 text-white">Terima kasih dan selamat menggunakan system ini… <br>Salam</p>
            <p class="f-14 font-500 mt-4 text-white"><i>Information System Section -</i></p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showPassword: false,
            model: {
                email: '',
                password: ''
            },
            isLoading: false
        }
    },
    methods: {
        async login() {
            let _ = this
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/v1/auth/login', _.model)
                    .then( resp => {
                        _.$store.dispatch('login', resp.data.data)
                        _.isLoading = false
                        window.location = this.$siteUrl + '/'
                    })
                    .catch(err=>{
                        _.$swal.fire({
                            title: 'Terjadi Kesalahan',
                            text: err.response.data.error.message,
                            icon: 'error',
                            heightAuto: false
                        })
                        _.isLoading = false
                    })
            }
        }
    },
}
</script>