<template>
  <div>
    <div class="card card-custom mb-2">
      <div class="card-body">
        <div class="col-12">
          <p class="font-500 f-12">Related User</p>
          <div class="row">
              <div class="col" style="width: 45%;">
                  <div class="card card-custom mb-1">
                      <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                          <div class="row">
                              <div class="col-12 f-12 font-600">Possible User</div>
                          </div>
                      </div>
                      <div class="card-body p-1">
                          <input type="text" class="form-control" v-model="searchPossibleUser">
                      </div>
                      <div style="height: 250px; overflow:auto;" class="pl-1 pr-1 pb-1">
                          <p class="f-10 p-0 mb-0 pointer" 
                              v-for="user in possibleUsers" 
                              :key="user.id"
                              :style="selectedPossibleUsers.length > 0 && selectedPossibleUsers.map( x => x.id ).indexOf(user.id) != -1 ? { background: '#E8EDF5'} : ''"
                              @click="onClickPossibleUser(user.id)">

                              <img :src="$siteUrl + '/assets/img/icon/arrow-right-circle.png'" alt="" class="img-fluid mr-1">
                              [{{ user.employee_id }}] {{ user.user_detail }}
                          </p>
                      </div>
                  </div>
              </div>
              <div class="text-center align-self-center p-0" style="width: 50px;">
                  <img :src="$siteUrl + '/assets/img/icon/arrow-right.png'" alt="" class="img-fluid pointer" title="Move Right" @click="addToRelatedUsers()">
                  <img :src="$siteUrl + '/assets/img/icon/arrow-left.png'" alt="" class="img-fluid pointer" title="Move Left" @click="addToPossibleUsers()">
              </div>
              <div class="col" style="width: 45%;">
                  <div class="card card-custom mb-1">
                      <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                          <div class="row">
                              <div class="col-12 f-12 font-600">Selected User</div>
                          </div>
                      </div>
                      <div class="card-body p-1">
                          <input type="text" class="form-control" v-model="searchSelectedUser">
                      </div>
                      <div style="height: 250px; overflow:auto;" class="pl-1 pr-1 pb-1">
                          <p class="f-10 p-0 mb-0 pointer" 
                              v-for="user in selectedUsers" 
                              :key="user.id"
                              :style="selectedRelatedUsers.length > 0 && selectedRelatedUsers.map( x => x.id ).indexOf(user.id) != -1 ? { background: '#E8EDF5'} : ''"
                              @click="onClickRelatedUser(user.id)">

                              <img :src="$siteUrl + '/assets/img/icon/arrow-left-circle.png'" alt="" class="img-fluid mr-1">
                              [{{ user.employee_id }}] {{ user.user_detail }}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-12">
          <p class="font-500 f-12">Related Document</p>
          <div class="row">
              <div class="col" style="width: 45%;">
                  <div class="card card-custom mb-1">
                      <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                          <div class="row">
                              <div class="col-12 f-12 font-600">Possible Document</div>
                          </div>
                      </div>
                      <div class="card-body p-1">
                          <input type="text" class="form-control" v-model="searchPossibleDocument">
                      </div>
                      <div style="height: 250px; overflow:auto;" class="pl-1 pr-1 pb-1">
                          <p class="f-10 p-0 mb-0 pointer" 
                              v-for="document in possibleDocuments" 
                              :key="document.id"
                              :style="selectedPossibleDocuments.length > 0 && selectedPossibleDocuments.map( x => x.id ).indexOf(document.id) != -1 ? { background: '#E8EDF5'} : ''"
                              @click="onClickPossibleDocument(document.id)">

                              <img :src="$siteUrl + '/assets/img/icon/arrow-right-circle.png'" alt="" class="img-fluid mr-1">
                              [{{ document.document_number }}-{{ numToSSColumn(document.initial) }}] {{ document.title }}
                          </p>
                      </div>
                  </div>
              </div>
              <div class="text-center align-self-center p-0" style="width: 50px;">
                  <img :src="$siteUrl + '/assets/img/icon/arrow-right.png'" alt="" class="img-fluid pointer" title="Move Right" @click="addToRelatedDocuments()">
                  <img :src="$siteUrl + '/assets/img/icon/arrow-left.png'" alt="" class="img-fluid pointer" title="Move Left" @click="addToPossibleDocuments()">
              </div>
              <div class="col" style="width: 45%;">
                  <div class="card card-custom mb-1">
                      <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                          <div class="row">
                              <div class="col-12 f-12 font-600">Selected Document</div>
                          </div>
                      </div>
                      <div class="card-body p-1">
                          <input type="text" class="form-control" v-model="searchSelectedDocument">
                      </div>
                      <div style="height: 250px; overflow:auto;" class="pl-1 pr-1 pb-1">
                          <p class="f-10 p-0 mb-0 pointer" 
                              v-for="document in selectedDocuments" 
                              :key="document.id"
                              :style="selectedRelatedDocuments.length > 0 && selectedRelatedDocuments.map( x => x.id ).indexOf(document.id) != -1 ? { background: '#E8EDF5'} : ''"
                              @click="onClickRelatedDocument(document.id)">

                              <img :src="$siteUrl + '/assets/img/icon/arrow-left-circle.png'" alt="" class="img-fluid mr-1">
                              [{{ document.document_number }}-{{ numToSSColumn(document.initial) }}] {{ document.title }}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="text-center">
            <button class="btn btn-primary font-400 f-10" :disabled="selectedDocuments.length == 0 || selectedUsers.length == 0" @click="resetDocument()">Reset</button>&nbsp;
            <!-- <button class="btn btn-secondary font-400 f-10">Reset</button> -->
        </div>
      </div>
    </div>
    <!-- <json-viewer :value="request"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      documents: [],
      selectedPossibleDocuments: [],
      selectedRelatedDocuments: [],
      relatedDocuments: [],
      searchPossibleDocument: '',
      searchSelectedDocument: '',

      users: [],
      selectedPossibleUsers: [],
      selectedRelatedUsers: [],
      relatedUsers: [],
      searchPossibleUser: '',
      searchSelectedUser: '',
    }
  },
  computed: {
    possibleDocuments: function(){
      let _ = this
      return _.documents.filter( function (data) {
        return _.searchPossibleDocument ? 
            data.document_number && 
            data.title && 
            _.relatedDocuments.map( x => x.id ).indexOf(data.id) == -1  && 
            ( 
                data.document_number.toLowerCase().indexOf(_.searchPossibleDocument.toLowerCase()) > -1 || 
                data.title.toLowerCase().indexOf(_.searchPossibleDocument.toLowerCase()) > -1 
            )
        :
            data.document_number && 
            data.title && 
            _.relatedDocuments.map( x => x.id ).indexOf(data.id) == -1
      })
    },
    selectedDocuments: function(){
        let _ = this
        return _.documents.filter( function (data) {
            return _.searchSelectedDocument ? 
                        data.document_number && 
                        data.title && 
                        _.relatedDocuments.map( x => x.id ).indexOf(data.id) != -1  && 
                        ( 
                            data.document_number.toLowerCase().indexOf(_.searchSelectedDocument.toLowerCase()) > -1 || 
                            data.title.toLowerCase().indexOf(_.searchSelectedDocument.toLowerCase()) > -1 
                        )
                    :
                        data.document_number && 
                        data.title && 
                        _.relatedDocuments.map( x => x.id ).indexOf(data.id) != -1
        })
    },
    possibleUsers: function(){
      let _ = this
      return _.users.filter( function (data) {
        return _.searchPossibleUser ? 
            data.level == 'user' &&
            _.relatedUsers.map( x => x.id ).indexOf(data.id) == -1  && 
            ( 
                data.employee_id.toLowerCase().indexOf(_.searchPossibleUser.toLowerCase()) > -1 || 
                data.user_detail.toLowerCase().indexOf(_.searchPossibleUser.toLowerCase()) > -1 
            )
        :
            data.level == 'user' &&
            _.relatedUsers.map( x => x.id ).indexOf(data.id) == -1
      })
    },
    selectedUsers: function(){
        let _ = this
        return _.users.filter( function (data) {
            return _.searchSelectedUser ? 
                        // data.user_number && 
                        // data.title && 
                        _.relatedUsers.map( x => x.id ).indexOf(data.id) != -1  && 
                        ( 
                            data.employee_id.toLowerCase().indexOf(_.searchSelectedUser.toLowerCase()) > -1 || 
                            data.user_detail.toLowerCase().indexOf(_.searchSelectedUser.toLowerCase()) > -1 
                        )
                    :
                        // data.user_number && 
                        // data.title && 
                        _.relatedUsers.map( x => x.id ).indexOf(data.id) != -1
        })
    },
    request: function(){
      return {
        users: this.selectedUsers.map( x => x.id ),
        documents: this.selectedDocuments.map( x => x.id )
      }
    }
  },
  methods: {
    getDocuments() {
        let _ = this
        _.axios.get('/v2/document/report_ab?page=all')
            .then(resp=>{
                const dateNow = _.$moment()
                
                _.documents = resp.data.data.map( x => {
                    return {
                        ...x,
                        initial: _.$moment(x.effective_date) > dateNow ? parseInt(x.initial)-1 : parseInt((x.initial)),
                    }
                })
            })
            .catch(err=>{
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: err.response.data.errors
                })
            })
    },
    onClickPossibleDocument( id ) {
        let _ = this
        let searchDocument = _.selectedPossibleDocuments.map( x => x.id ).indexOf(id)
        if (searchDocument != -1) { // If Exists
            _.selectedPossibleDocuments.splice( searchDocument, 1 )
        } else {
            _.selectedPossibleDocuments.push({ id: id })
        }
    },
    onClickRelatedDocument( id ) {
        let _ = this
        let searchDocument = _.selectedRelatedDocuments.map( x => x.id ).indexOf(id)
        if (searchDocument != -1) { // If Exists
            _.selectedRelatedDocuments.splice( searchDocument, 1 )
        } else {
            _.selectedRelatedDocuments.push({ id: id })
        }
    },
    addToRelatedDocuments() {
        let _ = this

        _.selectedPossibleDocuments.forEach(elm => {
            _.relatedDocuments.push({ id: elm.id })
        });

        _.selectedPossibleDocuments = []
        _.selectedRelatedDocuments = []
    },
    addToPossibleDocuments() {
        let _ = this

        _.selectedRelatedDocuments.forEach(elm => {
            let searchDocument = _.relatedDocuments.map( x => x.id ).indexOf(elm.id)
            _.relatedDocuments.splice( searchDocument, 1)
        });

        _.selectedPossibleDocuments = []
        _.selectedRelatedDocuments = []
    },
    getUsers() {
        let _ = this
        _.axios.get('/v2/reset-doc/list_user')
            .then(resp=>{
                _.users = resp.data.data
            })
            .catch(err=>{
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: err.response.data.errors
                })
            })
    },
    onClickPossibleUser( id ) {
        let _ = this
        let searchUser = _.selectedPossibleUsers.map( x => x.id ).indexOf(id)
        if (searchUser != -1) { // If Exists
            _.selectedPossibleUsers.splice( searchUser, 1 )
        } else {
            _.selectedPossibleUsers.push({ id: id })
        }
    },
    onClickRelatedUser( id ) {
        let _ = this
        let searchUser = _.selectedRelatedUsers.map( x => x.id ).indexOf(id)
        if (searchUser != -1) { // If Exists
            _.selectedRelatedUsers.splice( searchUser, 1 )
        } else {
            _.selectedRelatedUsers.push({ id: id })
        }
    },
    addToRelatedUsers() {
        let _ = this

        _.selectedPossibleUsers.forEach(elm => {
            _.relatedUsers.push({ id: elm.id })
        });

        _.selectedPossibleUsers = []
        _.selectedRelatedUsers = []
    },
    addToPossibleUsers() {
        let _ = this

        _.selectedRelatedUsers.forEach(elm => {
            let searchUser = _.relatedUsers.map( x => x.id ).indexOf(elm.id)
            _.relatedUsers.splice( searchUser, 1)
        });

        _.selectedPossibleUsers = []
        _.selectedRelatedUsers = []
    },
    resetDocument() {
      let _ = this
        _.axios.post('/v2/reset-doc', _.request)
            .then(()=>{
                _.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: 'You have successfully reset of doc must read: Based on document'
                })
                _.resetSelected()
            })
            .catch(err=>{
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: err.response.data.errors
                })
            })
    },
    resetSelected() {
      this.relatedDocuments = []
      this.relatedUsers = []
      this.searchPossibleDocument = ''
      this.searchSelectedDocument = ''
      this.searchPossibleUser = ''
      this.searchSelectedUser = ''
    }
  },
  mounted() {
    let _ = this

    _.getDocuments()
    _.getUsers()
  },
}
</script>
