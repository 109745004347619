<template>
    <div>
        <Table
            :withCard="false"
            :dataTable="table"
            :limit="true"
            :search="true"
            :exportExcelPIC="downloadParams"
            :pagination="true">

            <template v-slot:addButton>
                <button class="btn btn-primary btn-sm ml-1" data-toggle="modal" data-target="#addPicReport" @click="resetModel()">Add PIC Report</button>
                <button class="btn btn-outline-primary btn-sm ml-1" @click="sendBulkEmail()">Send Email</button>
            </template> 

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th v-for="(data, index) in maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:tbody>
                <tbody v-if="table.lists">
                    <tr v-for="(data, index) in table.lists.data" :key="makeid(10)+index">
                        <td v-for="(dt, i) in maxColumn.section" :key="makeid(10)+i">
                            {{ data.sec_id && data.sectionDetails.length > 0 && data.sectionDetails[i] ? data.sectionDetails[i] : '-' }}
                        </td>
                        <td>
                            <ul class="m-0 pl-1">
                                <li v-for="(email, index) in JSON.parse(data.user_id)" :key="makeid(10)+index">{{email}}</li>
                            </ul>
                        </td>
                        <td class="text-center">
                            <div class="btn-action-block">
                                <button class="btn p-0 f-18 text-primary mr-1" title="Edit" data-target="#addPicReport" data-toggle="modal" @click="setModel(data)">
                                    <img :src="$siteUrl + '/assets/img/icon/edit.png'" alt="" class="img-fluid">
                                </button>
                                <button class="btn p-0 f-18 text-primary" title="Send Email" @click="sendEmail(data)">
                                    <i class="fa fa-send"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </Table>

        <div class="modal fade text-left show" id="addPicReport" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">{{model.id ? 'Edit' : 'Add'}} PIC Report</p>
                        <span class="pull-right pointer" data-dismiss="modal" id="closeFormPic"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <ValidationObserver ref="observer">
                            <form @submit.prevent="store()">
                                <div v-for="(section, index) in sections" :key="makeid(10)+index">
                                    <ValidationProvider :name="'Section (Level ' + (index+1) + ')'" :rules="index == 0 ? 'required' : ''" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label for="">Section (Level {{index+1}})</label>
                                            <select class="form-control" v-model="model.sections[index]" @change="onChangeSection($event, index)">
                                                <option value="">Pilih Section</option>
                                                <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="makeid(10)+indexSection">
                                                    {{ choice.code }} - {{ choice.title }}
                                                </option>
                                            </select>
                                            <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>

                                <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label for="">Email</label>
                                            <input type="text" class="form-control" v-model="model.email">
                                            <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                    <button 
                                        type="button" 
                                        class="btn text-primary font-600 btn-sm" 
                                        :disabled="!model.email || errors[0]" 
                                        @click="addUser()">
                                        Add
                                    </button>
                                </div>
                                </ValidationProvider>
                                <div style="max-height: 300px; overflow-y:auto">
                                    <table class="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th style="width: 10%;position: sticky; top: 0; z-index: 1;">No.</th>
                                                <th style="position: sticky; top: 0; z-index: 1;">Email</th>
                                                <th style="width: 10%;position: sticky; top: 0; z-index: 1;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="model.user_id.length > 0">
                                            <tr v-for="(user, index) in model.user_id" :key="makeid(10)+index">
                                                <td>{{ index+1 }}</td>
                                                <td>{{ user }}</td>
                                                <td class="text-center">
                                                    <button class="btn text-danger" type="button" @click="removeUser(index)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3" class="text-center">Tidak ada email</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <input type="hidden" class="form-control" v-model="model.user_id">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <div class="text-center mt-1">
                                    <button class="btn btn-primary text-primary btn-sm" type="submit">Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <!-- <json-viewer :value="inputData"></json-viewer> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/TableTypeSection'

export default {
    components: { 
        Table 
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                search: _.search,
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
            }
            return data
        },
        inputData() {
            let _ = this

            if (this.model.sections.length > 0) {
                const parsedSections = this.model.sections.map(x => {
                    let parsed = JSON.parse(x);
                    delete parsed.children;
                    return parsed;
                });

                return {
                    section_id: JSON.parse(this.model.sections[this.model.sections.length-1]).id,
                    section: JSON.stringify([parsedSections[this.model.sections.length-1]]),
                    sec_id: JSON.stringify(parsedSections.map(x => x.id)),
                    user_id: JSON.stringify(_.model.user_id)
                }
            }
            
            return null;
        }
    },
    data() {
        return {
            table: {
                headers: ['No.', 'Section', 'Email', 'Action'],
                columns: ['counter', 'section', 'user_id'],
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                group: '',
                section: '',
                position: ''
            },
            model: {
                section_id: null,
                sections: [],
                user_id: []
            },
            sections: [],
            registeredPIC: [],
            backupSections: [],
            maxColumn: {
                section: 1
            },
        }
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/v2/pic?search=' + _.search + '&page=' + _.page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.lists = resp.data.data
                    
                    _.maxColumn = {
                        section: 1
                    }

                    _.table.lists.data.forEach(elm => {
                        if (elm.section && elm.sec_id) {
                            if (_.maxColumn.section < JSON.parse(elm.sec_id).length ) {
                                _.maxColumn.section = JSON.parse(elm.sec_id).length
                            }
                        }
                    })

                    _.table.lists.data = _.table.lists.data.map((data) => {
                        let foundSection = []
                        if (data.sec_id) {
                            const sec_id = JSON.parse(data.sec_id);
                            const sections = JSON.parse(JSON.stringify(_.sections));

                            for (let i = 0; i < sec_id.length; i++) {
                                if ( sections[i] ) {
                                    let section = sections[i].choices.map( x => x.id ).indexOf( sec_id[i] )
                                    if (section != -1) {
                                        foundSection.push(sections[i].choices[section].code)

                                        if (sections[i].choices[section].children.length > 0) {
                                            sections.push({ choices: sections[i].choices[section].children })
                                        }
                                    }
                                }
                            }
                        }

                        return {
                            ...data,
                            sectionDetails: foundSection
                        }
                    })
                    _.getRegisteredPIC(resp.data.data.meta.total)
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getRegisteredPIC( limit ) {
            let _ = this
            _.axios.get('/v2/pic?page=1&limit=' + limit)
                .then(resp=>{
                    // let registered = resp.data.data.data.map( x => x.section_id )
                    // console.log(registered)

                    var names = resp.data.data.data.map( x => x.section_id )
                    var uniqueNames = [];
                    window.$.each(names, function(i, el){
                        if(window.$.inArray(el, uniqueNames) === -1) uniqueNames.push(parseInt(el));
                    });

                    _.registeredPIC = uniqueNames
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus pic ini?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/v2/pic/delete/' + x.id, { is_delete: 'true' })
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (!isValid){
                return;
            }

            if (_.model.id) {
                _.update()
                return
            }

            _.axios.post('/v2/pic/create', this.inputData)
                .then(() => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                    _.index()
                    _.resetModel()
                    _.hide_modal()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                })
        },
        update() {
            let _ = this

            _.axios.post('/v2/pic/update/' + _.model.id, this.inputData)
                .then(() => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                    _.index()
                    _.resetModel()
                    _.hide_modal()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                })
        },
        getSection() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then( resp => {
                    this.sections = [];
                    _.sections.push({ choices: resp.data.data });
                    _.backupSections = resp.data.data;
                    _.index();
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        addUser() {
            let _ = this

            if ( _.model.user_id.indexOf(_.model.email.toLowerCase()) == -1 ) {
                _.model.user_id.push( _.model.email.toLowerCase() )
                _.model.email = null
            } else {
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: 'Email sudah terdaftar sebagai PIC'
                })
            }
        },
        removeUser( index ) {
            let _ = this
            _.model.user_id.splice( index, 1)
        },
        resetModel() {
            let _ = this
            _.model = {
                section_id: null,
                sections: [],
                user_id: []
            }
            _.$refs.observer.reset()

            this.sections = []
            this.sections.push({ choices: this.backupSections })
        },
        hide_modal() {
            window.$('#closeFormPic').click()
        },
        setModel( data ) {
            let _ = this

            _.resetModel()

            data.sec_id = JSON.parse(data.sec_id);
            // data.sec_id = JSON.parse("[1, 10, 18]");

            let foundSection = []
            for (let i = 0; i < data.sec_id.length; i++) {
                if ( _.sections[i] ) {
                    let section = _.sections[i].choices.map( x => x.id ).indexOf( data.sec_id[i] )
                    if (section != -1) {
                        foundSection.push(JSON.stringify(_.sections[i].choices[section]))

                        if (_.sections[i].choices[section].children.length > 0) {
                            _.sections.push({ choices: _.sections[i].choices[section].children })
                        }
                    }
                }
            }

            _.model = {
                ...data,
                sections: foundSection,
                user_id: JSON.parse(data.user_id),
            }
        },
        sendBulkEmail()
        {
            let _ = this
            _.axios.get('v2/pic/send_email_v2')
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })     
        },
        sendEmail(data)
        {
            let _ = this
            _.axios.get('v2/pic/send_email_v2?code=' + JSON.parse(data.section)[0].code_unique)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        onChangeSection( ev, index ) {
            try {
                let section = JSON.parse(ev.target.value)

                this.model.sections.splice(index+1, this.sections.length)

                this.sections.splice(index+1, this.sections.length)

                if (section.children && section.children.length > 0) {
                    this.sections.push( { choices: section.children } )
                }    
            } catch (error) {
                console.log(error)
            }
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    mounted() {
        let _ = this        
        _.getSection()
        _.$root.$on("search", (data) => {
            _.search = data
            _.index()
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.index()
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.index()
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.index()
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")
    },
}
</script>