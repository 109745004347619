<template>
	<div class="col mb-1">
		<div class="row pl-2">
			<p class="f-10 mb-0 align-self-center" v-if="data.current_page != data.last_page">
				Showing {{ (data.current_page - 1) * data.per_page + 1 }} to {{ (data.current_page * data.per_page) }} from {{ data.total ? data.total : 0 }} entries
			</p>
			<p class="f-10 mb-0 align-self-center" v-else>
				Showing {{ (data.current_page - 1) * data.per_page + 1 }} to {{ (data.current_page * data.per_page) - ( data.per_page*data.current_page - data.total) }} from {{ data.total ? data.total : 0 }} entries
			</p>
			<div class="col text-right">
				<ul class="pagination mb-0 pull-right">
					<li class="paginate_button page-item previous" :class="{ disabled: data.current_page <= 1 }">
						<button type="button" class="page-link" @click.prevent="changePage(data.current_page - 1)"></button>
					</li>

					<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
						<button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
					</li>

					<li class="paginate_button page-item next" :class="{ disabled: data.current_page >= data.last_page }">
						<button type="button" class="page-link" @click.prevent="changePage(data.current_page + 1)"></button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [ 'data', 'id' ],
	methods: {
		isCurrentPage(page){
			return this.data.current_page === page
		},
		changePage(page) {
			if (page > this.data.last_page) {
				page = this.data.last_page;
			}
			this.data.current_page = page;
			this.$root.$emit('page' + this.id, page);
		}
	},
	computed: {
		pages() {
			let current         = this.data.current_page
			let last            = this.data.last_page
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	}
}
</script>