<template>
	<div :class="withCard ? 'card card-custom' : ''">
        <div v-if="filter">
            <div class="pl-1" :class="withCard ? 'pt-1' : ''">
                <p class="f-12 font-600 mb-0">Filter By:
                    <span class="pull-right text-primary mr-1 pointer" data-target="#modalFilterDocument" data-toggle="modal">
                        Show Filter
                    </span>
                </p>
            </div>
            <hr>
        </div>
        <div v-if="filterUser">
            <div class="pl-1" :class="withCard ? 'pt-1' : ''">
                <p class="f-12 font-600 mb-0">Filter By:
                    <span class="pull-right text-primary mr-1 pointer" data-target="#modalFilterUser" data-toggle="modal">
                        Show Filter
                    </span>
                </p>
            </div>
            <hr>
        </div>
        <div class="card-body pl-1 pr-1 pt-0 pb-0 mb-1">
            <div class="col">
                <div class="row">
                    <div class="col" v-if="limit">
                        <div class="row">
                            <p class="f-10 mb-0 align-self-center mr-1">Entries</p>
                            <select class="form-control col-2 f-10" v-model="model.limit">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="all">Show All</option>
                            </select>

                            <slot name="addButton"></slot>
                        </div>
                    </div>

                    <div class="col text-right">
                        <div class="row justify-content-end">
                            <input v-if="search" type="text" class="form-control col-6 mr-1" placeholder="Search..." v-model="model.search">
                            <button v-if="exportExcel" class="btn btn-outline-primary btn-sm" @click="download(exportExcel)">Export Excel</button>
                            <button v-if="exportExcelUser" class="btn btn-outline-primary btn-sm" @click="downloadUser(exportExcelUser)">Export Excel</button>
                            <button v-if="exportExcelObster" class="btn btn-outline-primary btn-sm" @click="downloadObster(exportExcelObster)">Export Excel</button>
                            <button v-if="exportExcelPIC" class="btn btn-outline-primary btn-sm" @click="downloadUserPIC(exportExcelPIC)">Export Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table mb-0">
                <slot name="thead"></slot>
                <slot name="tbody"></slot>
            </table>
            <div class="col text-center mt-1">
                <p class="f-12" v-if="dataTable.lists && dataTable.lists.data && dataTable.lists.data.length == 0">There is no data</p>
            </div>                                                                           
        </div>
        <PaginateList :data="dataTable.lists.meta" v-if="pagination && ( dataTable.lists && dataTable.lists.meta && dataTable.lists.meta.total != 0)"/>

        <div class="modal fade text-left show" id="modalFilterDocument" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Filter</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Effective Date</label>
                                    <div class="row">
                                        <div class="col-6">
                                            <date-picker
                                                v-model="model.filter.effective_date_start"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="From"
                                                input-class="form-control"
                                                class="w-100">
                                            </date-picker>
                                        </div>
                                        <div class="col-6">
                                            <date-picker
                                                v-model="model.filter.effective_date_end"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="To"
                                                input-class="form-control"
                                                class="w-100">
                                            </date-picker>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-6" style="border-right: 1px solid #f5f5f5">
                                <div v-for="(type, index) in types" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Type' : 'Sub Type ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.types[index]" @change="onChangeType($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexType) in type.choices" :key="indexType">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(section, index) in sections" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Section' : 'Sub Section ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.sections[index]" @change="onChangeSection($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="indexSection">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-1">
                            <button class="btn btn-outline-primary f-12" @click="resetFilter()">Reset</button> &nbsp;
                            <button class="btn btn-primary f-12" data-dismiss="modal" @click="doFilter()">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalFilterUser" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Filter</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="">Position</label>
                                    <select name="" id="" class="form-control" v-model="model.filter.position">
                                        <option value="">Pilih Position</option>
                                        <option v-for="role in roles" :key="role.id" :value="role.position">{{ role.position }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(section, index) in sections" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Section' : 'Sub Section ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.sections[index]" @change="onChangeSection($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="indexSection">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-1">
                            <button class="btn btn-outline-primary f-12" @click="resetFilter()">Reset</button> &nbsp;
                            <button class="btn btn-primary f-12" data-dismiss="modal" @click="doFilter()">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
    props: [ 
        'dataTable',
        'filter',
        'filterUser',
        'limit',
        'search',
        'exportExcel',
        'exportExcelUser',
        'exportExcelObster',
        'exportExcelPIC',
        'pagination',
        'withCard'
    ],
    data() {
        return {
            model: {
                search: '',
                limit: 10,
                filter: {
                    effective_date_start: '',
                    effective_date_end: '',
                    type: '',
                    group: '',
                    position: '',
                    section: ''
                }
            },
            types: [],
            sections: [],
            roles: [],
            dataFilter: {
                sections: [],
                types: [],
                position: ''
            },
            backup: {
                sections: [],
                types: []
            }            
        }
    },
    components: {
        PaginateList
    },
    methods: {
        getDiff(x)
        {
            let _ = this
            let expiredAt = _.$moment(x).format('YYYY-MM-DD') + ' 00:00:00'
            let now = _.$moment().format('YYYY-MM-DD') + ' 00:00:00'
            let diff = (_.$moment(expiredAt).diff(_.$moment(now), 'days')) * -1

            return diff > 0 ? '+' + diff : diff
        },
        isJsonString(str) {
            try {
                JSON.parse(str)
            } catch (e) {
                return false
            }
            return true
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded p-1'
                }
            })
        },
        html_striptag(str) {
            return str.replace(/(<([^>]+)>)/gi, "")
        },
        getDocumentType() {
            let _ = this
            _.axios.get('/v2/repositories/master-file?showFile=false')
                .then(resp=>{
                    _.types.push({ choices: resp.data.data })
                    _.backup.types.push({ choices: resp.data.data })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocumentSection() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then(resp=>{
                    _.sections.push({ choices: resp.data.data })
                    _.backup.sections.push({ choices: resp.data.data })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getRole() {
            let _ = this
            _.axios.get('/v1/repositories/role-all')
                .then(resp => {
                    _.roles = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        onChangeType( ev, index ) {
            let type = JSON.parse(ev.target.value)

            this.types.splice(index+1, this.types.length)
            this.dataFilter.types.splice(index+1, this.types.length)

            if (type.children.length > 0) {
                this.types.push( { choices: type.children } )
            }
        },
        onChangeSection( ev, index ) {
            let section = JSON.parse(ev.target.value)

            this.sections.splice(index+1, this.sections.length)
            this.dataFilter.sections.splice(index+1, this.sections.length)

            if (section.children.length > 0) {
                this.sections.push( { choices: section.children } )
            }
        },
        resetFilter() {
            this.model.filter = {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                group: '',
                section: '',
                position: ''
            },
            this.dataFilter = {
                sections: [],
                types: [],
                position: ''
            }
            this.types = JSON.parse(JSON.stringify(this.backup.types))
            this.sections = JSON.parse(JSON.stringify(this.backup.sections))
        },
        doFilter() {
            let _ = this
            _.model.filter.type = ''
            _.dataFilter.types.forEach(elm => {
                let type = JSON.parse(elm)
                _.model.filter.type  = type.code
            })
            _.model.filter.type = _.model.filter.type.trim()

            _.model.filter.group = ''
            _.dataFilter.sections.forEach(elm => {
                let group = JSON.parse(elm)
                _.model.filter.group  = group.code_unique
            })
            _.model.filter.group = _.model.filter.group.trim()

            _.model.filter.section = _.model.filter.group

            this.$root.$emit('filter', this.model.filter)
            this.$root.$emit('page', 1)
        },
        downloadConsult(url) {
            fetch(url, { headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken')}})
                .then(res => res.blob())
                .then(blob => {
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = this.consult.consultId + '.pdf'
                    link.click()
                })
        },
        download(params) {
            let _ = this
            _.axios.get('/v2/document/export' +
                '?modul=' + params.modul + 
                '&search=' + params.search + 
                '&page=' + params.page + 
                '&limit=' + params.limit +
                '&effective_date_start=' + params.effective_date_start +
                '&effective_date_end=' + params.effective_date_end +
                '&type=' + params.type +
                '&group=' + params.group
            ,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = params.modul + '-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        downloadUser(params) {
            let _ = this

            _.axios.get('/v1/repositories/export_user' +
                '?search=' + params.search + 
                '&page=' + params.page + 
                '&limit=' + params.limit +
                '&position=' + params.position +
                '&section=' + params.group
            ,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'User-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        downloadObster(params) {
            let _ = this
            _.axios.get('/v2/document/obster_export' +
                '?modul=' + params.modul + 
                '&search=' + params.search + 
                '&page=' + params.page + 
                '&limit=' + params.limit +
                '&effective_date_start=' + params.effective_date_start +
                '&effective_date_end=' + params.effective_date_end +
                '&type=' + params.type +
                '&group=' + params.group
            ,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = params.modul + '-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        downloadUserPIC(params) {
            let _ = this

            _.axios.get('/v2/pic/export' + 
                '?page=' + params.page + 
                '&limit=' + params.limit
            ,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'PIC-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        previewDocument( data ) {
            let _ = this
            _.$root.$emit('previewFile', data)
        },
    },
    watch: {
        'model.search': function() {
            this.$root.$emit('search', this.model.search)
        },
        'model.limit': function() {
            this.$root.$emit('limit', this.model.limit)
            this.$root.$emit('page', 1)
        }
    },
    mounted() {
        let _ = this
        if (_.filter) {
            _.getDocumentType()
        }

        if (_.filterUser) {
            _.getRole()
        }

        _.getDocumentSection()
    },
}
</script>