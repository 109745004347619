<template>
    <div class="row h-100">
        <h2 class="col-md-5 d-flex justify-content-center align-items-center">
            API version: {{ apiVersion }}<br/>
            <!-- FE version: v1.0.0-dec182023 -->
            FE version: v1.7.7-feb132024
        </h2>
    </div>
</template>
<script>
export default {
    data() {
        return {
            apiVersion: null
        }
    },
    mounted() {
        let _ = this

        _.axios.get('/version')
                .then(resp => {
                    _.apiVersion = resp.data.api_version+'-'+resp.data.updated_date
                })
                .catch(err=>{
                    console.log(err)
                })
    }
}
</script>