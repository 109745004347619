<template>
    <div>
        <div class="card-body pl-1 pr-1 pt-0 pb-1">
            <div class="row">
                <div class="col align-self-center">
                    <button class="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalParent" @click="resetModelParent()">
                        Add New Section
                    </button>
                </div>
                <!-- <div class="col text-right">
                    <div class="row justify-content-end pr-1">
                        <input type="text" class="form-control col-6 mr-1" placeholder="Search...">
                        <button class="btn btn-outline-primary btn-sm">Export Excel</button>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="card card-custom mb-1" style="border-radius: 0px; border: 0px;">
            <div class="card-title pl-1 pr-1 pt-1 pb-1 mb-0" style="background:#E8EDF5">
                <div class="row">
                    <div class="col-11 f-12 font-600">Section 
                        <button class="btn btn-sm btn-primary ml-1" @click="isExpanded ? isExpanded = false : isExpanded = true">{{isExpanded ? 'Collapse' : 'Expand'}} All</button>
                    </div>
                    <div class="col-1 f-12 font-600 p-0 align-self-center">Action</div>
                </div>
            </div>
            
            <vue-tree-list
                v-if="isShow && data"
                :model="data"
                v-bind:default-expanded="isExpanded">
                <template v-slot:leafNameDisplay="slotProps">
                    <span style="vertical-align: sub;">
                        <span class="f-16 text-primary" style="margin-right: 5px;" v-if="slotProps.model.main_id"></span>
                        <img :src="$siteUrl + '/assets/img/icon/folder.png'" alt="" class="img-fluid"> 
                        {{ slotProps.model.code }} - {{ slotProps.model.title }}
                    </span>
                    <div class="pull-right">
                        <!-- <button class="btn p-0 f-18 text-primary mr-1" title="Edit"
                            @click="modelParent = slotProps.model, toggleModalParent()">
                            <img :src="$siteUrl + '/assets/img/icon/edit.png'" alt="" class="img-fluid">
                        </button> -->
                        <button class="btn p-0 f-18 text-success" title="Add Sub Type"
                            @click="setModelChildren(slotProps.model), toggleModalChildren()">
                            <img :src="$siteUrl + '/assets/img/icon/add-file.png'" alt="" class="img-fluid">
                        </button>
                        <button class="btn p-0 f-18 text-danger ml-1" title="Delete" 
                            v-if="(slotProps.model.children && slotProps.model.children.length == 0) || !slotProps.model.children"
                            @click="destroy(slotProps.model)">
                            <img :src="$siteUrl + '/assets/img/icon/trash.png'" alt="" class="img-fluid">
                        </button>
                    </div>
                </template>
                <span class="icon" slot="treeNodeIcon"></span>
            </vue-tree-list>
        </div>

        <div class="modal fade text-left show" id="modalParent" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">{{ modelParent.id ? 'Edit' : 'Add New'}} Section</p>
                        <span class="pull-right pointer" id="closeModalParent" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <ValidationObserver ref="observerParent">
                            <form @submit.prevent="postParent()">
                                <ValidationProvider name="Kode Section" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Kode Section</label>
                                        <input type="text" class="form-control" v-model="modelParent.code">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Keterangan" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Keterangan</label>
                                        <input type="text" class="form-control" v-model="modelParent.title">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="text-center">
                                    <button class="btn font-400 text-primary f-10" type="button" @click="resetModelParent()">Reset</button>&nbsp;
                                    <button class="btn btn-primary font-400 f-10" type="submit">Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalChildren" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">{{ modelChildren.id ? 'Edit' : 'Add New'}} Sub Section</p>
                        <span class="pull-right pointer" id="closeModalChildren" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <ValidationObserver ref="observerChildren">
                            <form @submit.prevent="postChildren()">
                                <ValidationProvider name="Kode Section" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Kode Section</label>
                                        <input type="text" class="form-control" :value="modelChildren.main_code" disabled>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Kode Sub Section" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Kode Sub Section</label>
                                        <input type="text" class="form-control" v-model="modelChildren.code">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Keterangan" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Keterangan</label>
                                        <input type="text" class="form-control" v-model="modelChildren.title">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="text-center">
                                    <button class="btn font-400 text-primary f-10" type="button" @click="resetModelChildren()">Reset</button>&nbsp;
                                    <button class="btn btn-primary font-400 f-10" type="submit">Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VueTreeList, Tree } from 'vue-tree-list'
export default {
    components: {
        VueTreeList
    },
    data() {
        return {
            data: null,
            isExpanded: false,
            isShow: true,
            modelParent: {
                title: null,
                code: null,
                email: JSON.parse(localStorage.getItem('user')).user_email
            },
            modelChildren: {
                main_id: null,
                code: null,
                title: null,
                email: JSON.parse(localStorage.getItem('user')).user_email
            }
        }
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then( resp => {
                    _.data = new Tree(resp.data.data)
                    _.hide_modal()

                    setTimeout(() => {
                        window.$(".vtl-node-main").attr('draggable', false);
                    }, 100);
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async postParent() {
            let _ = this

            const isValid = await this.$refs.observerParent.validate()
            if (!isValid){
                return
            }

            if (_.modelParent.id) {
                _.updateParent()
                return
            }

            _.axios.post('/v1/repositories/section', _.modelParent)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success',
                        text: resp.data.status
                    })
                    _.index()
                    _.resetModelParent()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        updateParent() {
            let _ = this
            _.axios.put('/v1/repositories/update-data/' + _.modelParent.id, {
                    type_code: _.modelParent.code,
                    code: _.modelParent.code,
                    title: _.modelParent.title,
                    type: 'sub'
                })
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success',
                        text: resp.data.status
                    })
                    _.index()
                    _.resetModelParent()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async postChildren() {
            let _ = this
            
            const isValid = await this.$refs.observerChildren.validate()
            if (!isValid){
                return
            }

            _.axios.post('/v1/repositories/sub-section', _.modelChildren)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success',
                        text: resp.data.status
                    })
                    _.index()
                    _.setModelChildren()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        resetModelParent() {
            this.modelParent = {
                title: null,
                code: null,
                email: JSON.parse(localStorage.getItem('user')).user_email
            }
        },
        resetModelChildren( ) {
            this.modelChildren.code = null
            this.modelChildren.title = null
            this.modelChildren.email = JSON.parse(localStorage.getItem('user')).user_email
        },
        setModelChildren( data ) {
            this.modelChildren = {
                main_id: data.id,
                main_code: data.code + " - " + data.title,
                code: null,
                title: null
            }
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus section ini?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/v1/repositories/section/delete/' + x.id)
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$swal.fire({
                                    title: 'Terjadi Kesalahan',
                                    text: err.response.data.message,
                                    icon: 'error',
                                    heightAuto: false
                                })
                                _.hide_modal()
                            })                       
                    }
            })
        },
        toggleModalParent() {
            window.$('#modalParent').modal();
        },
        toggleModalChildren() {
            window.$('#modalChildren').modal();
        },
        hide_modal() {
            this.$refs.observerParent.reset()
            this.$refs.observerChildren.reset()
            window.$('#closeModalParent').click()
            window.$('#closeModalChildren').click()
        },
    },
    watch: {
        isExpanded: function()
        {
            let _ = this
            _.isShow = false
            setTimeout(() => {
                _.isShow = true
            }, 100)
        },
        isShow: function()
        {
            setTimeout(() => {
                window.$(".vtl-node-main").attr('draggable', false);
            }, 100);
        }
    },
    mounted() {
        let _ = this
        _.index()
    },
}
</script> 