export default {
	login({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},
	tryAutoLogin({ commit }) {
		let updateUser = {
			token: localStorage.getItem('token'),	
			user: JSON.parse(localStorage.getItem('user'))				
		}
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	}
}