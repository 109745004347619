<template>
    <div>
        <div class="pl-1 pr-1 mb-1">
            <div class="card card-custom-grey mb-0" v-if="state == 'index'">
                <div class="card-body p-1">
                    <div class="row">
                        <div class="col align-self-center">
                            <p class="font-500 f-12 mb-0">Add New User</p>
                        </div>
                        <div class="col text-right">
                            <button class="btn btn-primary font-400 f-10" data-target="#modalFormUser" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="model.id = null, resetModel()">Add New</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade text-left show" id="modalFormUser" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">{{ model.id ? 'Edit' : 'Add New' }} User</p>
                        <span class="pull-right pointer" data-dismiss="modal" id="closeFormUser"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <ValidationObserver ref="observer">
                            <form @submit.prevent="postUser()">
                                <div class="row">
                                    <div class="col-6">
                                        <ValidationProvider name="Employee ID" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Employee ID</label>
                                                <input type="text" class="form-control" v-model="model.employee_id" :disabled="model.id">
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="Email" :rules="{email: true, required: model.level == 'admin-level' || model.level == 'admin' ? true : false}" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label for="">Email Address</label>
                                                <input type="email" class="form-control" v-model="model.email">
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="Password" :rules="{ required: model.id ? false : true, min: 8 }" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label for="">Password</label>
                                                <input type="password" class="form-control" v-model="model.password">
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="Password Confirmation" :rules="{ required: model.id ? false : true, confirmed:'Password' }" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label for="">Password Confirmation</label>
                                                <input type="password" class="form-control" v-model="model.password_confirmation">
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="User Detail" rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label for="">User Detail (Full Name)</label>
                                                <input type="text" class="form-control" v-model="model.user_detail">
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6" v-can="'super admin'">
                                        <ValidationProvider name="Level" rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label for="">Level</label>
                                                <select class="form-control" v-model="model.level">
                                                    <option value="user">User</option>
                                                    <option value="user-dokumen">User Dokumen</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="admin-level">Admin Level</option>
                                                </select>
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="Position" rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label for="">Position</label>
                                                <select name="" id="" class="form-control" v-model="model.position" @change="addPosition">
                                                    <option value="">Pilih Position</option>
                                                    <option v-for="role in roles" :key="role.id" :value="JSON.stringify(role)">{{ role.position }}</option>
                                                </select>
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <div v-for="(section, index) in sections" :key="index">
                                            <ValidationProvider :name="'Section (Level ' + (index+1) + ')'" :rules="index == 0 ? 'required' : ''" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label for="">Section (Level {{index+1}})</label>
                                                    <select class="form-control" v-model="model.sections[index]" @change="onChangeSection($event, index)">
                                                        <option value="">Pilih Section</option>
                                                        <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="indexSection">
                                                            {{ choice.code }} - {{ choice.title }}
                                                        </option>
                                                    </select>
                                                    <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-1">
                                    <!-- <button class="btn text-primary btn-sm" type="button" @click="resetModel()">Reset</button> -->
                                    <button class="btn btn-primary text-primary btn-sm" type="submit">Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            state: 'index',
            model: {
                level: 'user',
                position_id: [],
                position: null,
                sections: []
            },
            roles: [],
            sections: [],
            backupSections: [],
            backupModel: {}
        }
    },
    methods: {
        async postUser() {
            let _ = this

            const isValid = await this.$refs.observer.validate()
            if (!isValid){
                return;
            }

            if (_.model.id) {
                _.updateUser()
                return;
            }

            let sections = []
            _.model.sections.forEach(elm => {
                let parseElm = JSON.parse(elm)
                sections.push({
                    id: parseElm.id,
                    code: parseElm.code,
                    code_unique: parseElm.code_unique
                })
            })

            let data = {
                employee_id: _.model.employee_id,
                password: _.model.password,
                user_detail: _.model.user_detail,
                email: _.model.employee_id,
                user_email: _.model.email,
                level: _.model.level,
                position_id: JSON.stringify(_.model.position_id),
                section: JSON.stringify(sections),
                password_confirmation: _.model.password_confirmation
            }

            _.axios.post('/v1/repositories/register', data)
                .then(() => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                    _.$root.$emit('page', 1)
                    _.resetModel()
                    window.$('#modalFormUser').modal();
                    _.hide_modal()
                })
                .catch(err=>{
                    let msg = ''
                    if (err.response.data.errors) {
                        if (err.response.data.errors[0].field == 'employee_id' && err.response.data.errors[0].rule == 'unique') {
                            msg = 'Employee ID sudah terdaftar'
                        } else {
                            msg = err.response.data.errors[0].field + ' is ' + err.response.data.errors[0].rule
                        }
                    }
                    _.$swal.fire({
                        title: 'Terjadi Kesalahan',
                        text: msg,
                        icon: 'error',
                        heightAuto: false
                    })
                    _.hide_modal()
                })
        },
        isJsonString(str) {
            try {
                JSON.parse(str)
            } catch (e) {
                return false
            }
            return true
        },
        updateUser() {
            let _ = this

            let sections = []
            _.model.sections.forEach(elm => {
                let parseElm = JSON.parse(elm)
                sections.push({
                    id: parseElm.id,
                    code: parseElm.code,
                    code_unique: parseElm.code_unique
                })
            })

            let data = {
                employee_id: _.model.employee_id,
                password: _.model.password,
                user_detail: _.model.user_detail,
                email: _.model.employee_id,
                user_email: _.model.email,
                level: _.model.level,
                position_id: _.isJsonString(_.model.position_id) ? _.model.position_id : JSON.stringify(_.model.position_id),
                section: JSON.stringify(sections),
                password_confirmation: _.model.password_confirmation
            }

            _.axios.put('/v1/repositories/user/' + _.model.id, data)
                .then(() => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                    _.$root.$emit('page', 1)
                    _.resetModel()
                    window.$('#modalFormUser').modal();
                    _.hide_modal()
                })
                .catch(err=>{
                    let msg = ''
                    if (err.response.data.errors) {
                        if (err.response.data.errors[0].field == 'employee_id' && err.response.data.errors[0].rule == 'unique') {
                            msg = 'Employee ID already used'
                        } else {
                            msg = err.response.data.errors[0].field + ' is ' + err.response.data.errors[0].rule
                        }
                    }

                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: msg
                    })
                })
        },
        getRole() {
            let _ = this
            _.axios.get('/v1/repositories/role-all')
                .then(resp => {
                    _.roles = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        addPosition(ev) {
            let _ = this
            _.model.position_id = []
            _.model.position_id.push( JSON.parse(ev.target.value) )
        },
        getDocumentSection() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then(resp=>{
                    _.sections.push({ choices: resp.data.data })
                    _.backupSections = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        onChangeSection( ev, index ) {
            let section = JSON.parse(ev.target.value)

            this.model.sections.splice(index+1, this.sections.length)

            this.sections.splice(index+1, this.sections.length)

            if (section.children && section.children.length > 0) {
                this.sections.push( { choices: section.children } )
            }
        },
        resetModel() {
            if (this.model.id) {
                this.model = this.backupModel
            } else {
                this.model = {
                    level: 'user',
                    position_id: [],
                    position: null,
                    sections: []
                }
                this.$refs.observer.reset()
            }

            this.sections = []
            this.sections.push({ choices: this.backupSections })
        },
        hide_modal() {
            window.$('#closeFormUser').click()
        },
    },
    mounted() {
        let _ = this
        _.getRole()
        _.getDocumentSection()

        _.$root.$on("setModel", (data) => {
            _.resetModel()
            let foundPosition = null
            let position = _.roles.map( x=> x.id ).indexOf( data.posId )
            if (position != -1) {
                foundPosition = JSON.stringify(_.roles[position])
            }

            let foundSection = []
            
            for (let i = 0; i < data.secId.length; i++) {
                if ( _.sections[i] ) {
                    let section = _.sections[i].choices.map( x => x.id ).indexOf( data.secId[i] )
                    if (section != -1) {
                        foundSection.push(JSON.stringify(_.sections[i].choices[section]))

                        if (_.sections[i].choices[section].children.length > 0) {
                            _.sections.push({ choices: _.sections[i].choices[section].children })
                        }
                    }
                }
            }

            let model = {
                ...data,
                email: data.user_email,
                position: foundPosition,
                sections: foundSection
            }

            _.model = model
            _.backupModel = JSON.parse(JSON.stringify(model))
        })
    },
}
</script>