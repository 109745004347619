<template>
    <div>
        <div class="card card-custom mb-2">
            <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist" 
                    style="white-space: nowrap;
                        flex-flow: nowrap;
                        overflow-y: hidden;">
                    <li class="nav-item">
                        <a 
                            class="nav-link active f-12" 
                            id="registered-tab" 
                            data-toggle="tab" 
                            href="#registered" 
                            role="tab" 
                            aria-controls="registered" 
                            aria-selected="true"
                            @click="state = 'registered'"
                            :class="state == 'registered' ? 'text-primary font-600' : 'text-black'">
                            Registered
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="before-effective-tab" 
                            data-toggle="tab" 
                            href="#before-effective" 
                            role="tab" 
                            aria-controls="before-effective" 
                            aria-selected="false"
                            @click="state = 'before-effective'"
                            :class="state == 'before-effective' ? 'text-primary font-600' : 'text-black'">
                            Before Effective
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="almost-expired-tab" 
                            data-toggle="tab" 
                            href="#almost-expired" 
                            role="tab" 
                            aria-controls="almost-expired" 
                            aria-selected="false"
                            @click="state = 'almost-expired'"
                            :class="state == 'almost-expired' ? 'text-primary font-600' : 'text-black'">
                            (Almost) Expire
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="already-expired-tab" 
                            data-toggle="tab" 
                            href="#already-expired" 
                            role="tab" 
                            aria-controls="already-expired" 
                            aria-selected="false"
                            @click="state = 'already-expired'"
                            :class="state == 'already-expired' ? 'text-primary font-600' : 'text-black'">
                            (Already) Expired
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="obsolete-tab" 
                            data-toggle="tab" 
                            href="#obsolete" 
                            role="tab" 
                            aria-controls="obsolete" 
                            aria-selected="false"
                            @click="state = 'obsolete'"
                            :class="state == 'obsolete' ? 'text-primary font-600' : 'text-black'">
                            Obsoleted
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="terminated-tab" 
                            data-toggle="tab" 
                            href="#terminated" 
                            role="tab" 
                            aria-controls="terminated" 
                            aria-selected="false"
                            @click="state = 'terminated'"
                            :class="state == 'terminated' ? 'text-primary font-600' : 'text-black'">
                            Terminated
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="documents-report-tab" 
                            data-toggle="tab" 
                            href="#documents-report" 
                            role="tab" 
                            aria-controls="documents-report" 
                            aria-selected="false"
                            @click="state = 'documents-report'"
                            :class="state == 'documents-report' ? 'text-primary font-600' : 'text-black'">
                            Doc. Report
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="documents-belum-baca-tab" 
                            data-toggle="tab" 
                            href="#documents-belum-baca" 
                            role="tab" 
                            aria-controls="documents-belum-baca" 
                            aria-selected="false"
                            @click="state = 'documents-belum-baca'"
                            :class="state == 'documents-belum-baca' ? 'text-primary font-600' : 'text-black'">
                            Doc. Belum Baca
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="documents-belum-mengerti-tab" 
                            data-toggle="tab" 
                            href="#documents-belum-mengerti" 
                            role="tab" 
                            aria-controls="documents-belum-mengerti" 
                            aria-selected="false"
                            @click="state = 'documents-belum-mengerti'"
                            :class="state == 'documents-belum-mengerti' ? 'text-primary font-600' : 'text-black'">
                            Doc. Belum Mengerti
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="documents-user-tab" 
                            data-toggle="tab" 
                            href="#documents-user" 
                            role="tab" 
                            aria-controls="documents-user" 
                            aria-selected="false"
                            @click="state = 'documents-user'"
                            :class="state == 'documents-user' ? 'text-primary font-600' : 'text-black'">
                            Doc. User
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="user-belum-baca-tab" 
                            data-toggle="tab" 
                            href="#user-belum-baca" 
                            role="tab" 
                            aria-controls="user-belum-baca" 
                            aria-selected="false"
                            @click="state = 'user-belum-baca'"
                            :class="state == 'user-belum-baca' ? 'text-primary font-600' : 'text-black'">
                            User Belum Baca
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link f-12" 
                            id="user-belum-mengerti-tab" 
                            data-toggle="tab" 
                            href="#user-belum-mengerti" 
                            role="tab" 
                            aria-controls="user-belum-mengerti" 
                            aria-selected="false"
                            @click="state = 'user-belum-mengerti'"
                            :class="state == 'user-belum-mengerti' ? 'text-primary font-600' : 'text-black'">
                            User Belum Mengerti
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show" :class="state == 'registered' ? 'active' : ''" id="registered" role="tabpanel" aria-labelledby="registered-tab">
                        <ListRegistered v-if="state == 'registered' && !isShowForm"/>
                        <RevisionTab v-if="state == 'registered'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'before-effective' ? 'active' : ''" id="before-effective" role="tabpanel" aria-labelledby="before-effective-tab">
                        <ListBeforeEffective v-if="state == 'before-effective'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'almost-expired' ? 'active' : ''" id="almost-expired" role="tabpanel" aria-labelledby="almost-expired-tab">
                        <ListAlmostExpired v-if="state == 'almost-expired'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'already-expired' ? 'active' : ''" id="already-expired" role="tabpanel" aria-labelledby="already-expired-tab">
                        <ListAlreadyExpired v-if="state == 'already-expired'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'obsolete' ? 'active' : ''" id="obsolete" role="tabpanel" aria-labelledby="obsolete-tab">
                        <ListObsolete v-if="state == 'obsolete'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'terminated' ? 'active' : ''" id="terminated" role="tabpanel" aria-labelledby="terminated-tab">
                        <ListTerminated v-if="state == 'terminated'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'documents-report' ? 'active' : ''" id="documents-report" role="tabpanel" aria-labelledby="documents-report-tab">
                        <ListDocumentsReport v-if="state == 'documents-report'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'documents-user' ? 'active' : ''" id="documents-user" role="tabpanel" aria-labelledby="documents-user-tab">
                        <ListDocumentsUser v-if="state == 'documents-user'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'documents-belum-baca' ? 'active' : ''" id="documents-belum-baca" role="tabpanel" aria-labelledby="documents-belum-baca-tab">
                        <ListDocumentsBelumBaca v-if="state == 'documents-belum-baca'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'documents-belum-mengerti' ? 'active' : ''" id="documents-belum-mengerti" role="tabpanel" aria-labelledby="documents-belum-mengerti-tab">
                        <ListDocumentsBelumMengerti v-if="state == 'documents-belum-mengerti'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'user-belum-baca' ? 'active' : ''" id="user-belum-baca" role="tabpanel" aria-labelledby="user-belum-baca-tab">
                        <ListUserBelumBaca v-if="state == 'user-belum-baca'"/>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'user-belum-mengerti' ? 'active' : ''" id="user-belum-mengerti" role="tabpanel" aria-labelledby="user-belum-mengerti-tab">
                        <ListUserBelumMengerti v-if="state == 'user-belum-mengerti'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ListRegistered from './ListRegistered'
import ListBeforeEffective from './ListBeforeEffective'
import ListAlmostExpired from './ListAlmostExpired'
import ListAlreadyExpired from './ListAlreadyExpired'
import ListObsolete from './ListObsolete'
import ListTerminated from './ListTerminated'
import ListDocumentsReport from './ListDocumentsReport'
import ListDocumentsUser from './ListDocumentsUser'
import ListDocumentsBelumBaca from './ListDocumentsBelumBaca'
import ListDocumentsBelumMengerti from './ListDocumentsBelumMengerti'
import ListUserBelumBaca from './ListUserBelumBaca'
import ListUserBelumMengerti from './ListUserBelumMengerti'

import RevisionTab from '../../manage-document/reserve-and-registration/tab-revision/Index'

export default {
    components: {
        ListRegistered,
        ListBeforeEffective,
        ListAlmostExpired,
        ListAlreadyExpired,
        ListObsolete,
        ListTerminated,
        ListDocumentsReport,
        ListDocumentsUser,
        RevisionTab,
        ListDocumentsBelumBaca,
        ListDocumentsBelumMengerti,
        ListUserBelumBaca,
        ListUserBelumMengerti,
    },
    data() {
        return {
            state: 'registered',
            isShowForm: false
        }
    },
    mounted() {
        let _ = this
        _.$root.$on("showForm", (data) => {
            _.isShowForm = data
        })
    },
}
</script>