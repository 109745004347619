<template>
    <div>
        <div class="pl-1">
            <p class="f-12 font-600 mb-0">Filter By:
                <span class="pull-right text-primary mr-1 pointer" data-target="#modalFilterDocument" data-toggle="modal">
                    Show Filter
                </span>
            </p>
        </div>
        <hr>
        <div class="card-body pl-1 pr-1 pt-0 pb-0 mb-1">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <p class="f-10 mb-0 align-self-center mr-1">Entries</p>
                            <select class="form-control col-2 f-10 mr-1" v-model="perPage">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option :value="table.lists.length">Show All</option>
                            </select>
                        </div>
                    </div>
                    <div class="col text-right">
                        <div class="row justify-content-end">
                            <input type="text" class="form-control col-6 mr-1" placeholder="Search..." v-model="search">
                            <button class="btn btn-outline-primary btn-sm" @click="download('export_user_not_read')">Export Excel</button>
                            <!-- <button v-if="exportExcelUser" class="btn btn-outline-primary btn-sm" @click="downloadUser(exportExcelUser)">Export Excel</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Position</th>
                        <th>Section</th>
                        <th>Document Number</th>
                        <th>Title</th>
                        <th v-for="(data, index) in maxColumn.edm_document_parent_section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Effective Date</th>
                        <th>Baca</th>
                        <th>Mengerti</th>
                        <th>Akses</th>
                        <th>Overdue</th>
                    </tr>
                </thead>
                <tbody v-if="dataToDisplay.length > 0">
                    <tr v-for="(data, index) in dataToDisplay" :key="makeid(10)+index">
                        <td>{{ data.user_employee_id }}</td>
                        <td>{{ data.user_name }}</td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="(position, index) in JSON.parse(data.user_position)" :key="makeid(10)+index">
                                    {{ position.position }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="(section, index) in JSON.parse(data.user_section)" :key="makeid(10)+index">
                                    {{ section.code }}
                                </li>
                            </ul>
                        </td>
                        <td class="text-primary font-600">
                            <u>{{ data.edm_document_number }}-{{ numToSSColumn(data.edm_document_initial) }}</u>
                        </td>
                        <td style="overflow-wrap: normal;">{{ data.edm_document_title }}</td>
                        <td v-for="(dt, i) in maxColumn.edm_document_parent_section" :key="makeid(10)+i">
                            <span >
                                {{ data.edm_document_parent_section && JSON.parse(data.edm_document_parent_section)[i] ? JSON.parse(data.edm_document_parent_section)[i].code : '-' }}
                            </span>
                        </td>
                        <td v-if="data.edm_document_effective_date">{{ data.edm_document_effective_date | moment("YYYY/MM/DD") }}</td>
                        <td v-else>-</td>
                        <td v-if="data.edm_document_first_access_date">{{ data.edm_document_first_access_date | moment('YYYY/MM/DD') }}</td>
                        <td v-else>-</td>
                        <td v-if="data.edm_document_has_understand">{{ data.edm_document_understand_date | moment('YYYY/MM/DD') }}</td>
                        <td v-else>-</td>
                        <td v-if="data.edm_document_last_access_date">{{ data.edm_document_last_access_date | moment('YYYY/MM/DD') }}</td>
                        <td v-else>-</td>
                        <td v-if="data.edm_document_effective_date">{{ getDiff(data.edm_document_effective_date) }} Days</td>
                        <td v-else>-</td>
                    </tr>
                </tbody>
            </table>
            <div class="col mb-1" v-if="dataToDisplay.length > 0">
                <div class="row pl-2">
                    <p class="f-10 mb-0 align-self-center" v-if="currentPage != totalPages">
                        Showing {{ (currentPage - 1) * perPage + 1 }} to {{ (currentPage * perPage) }} from {{ dataTable.length ? dataTable.length : 0 }} entries
                    </p>
                    <p class="f-10 mb-0 align-self-center" v-else>
                        Showing {{ (currentPage - 1) * perPage + 1 }} to {{ (currentPage * perPage) - ( perPage*currentPage - dataTable.length) }} from {{ dataTable.length ? dataTable.length : 0 }} entries
                    </p>
                    <div class="col text-right">
                        <ul class="pagination mb-0 pull-right">
                            <li class="paginate_button page-item previous" :class="{ disabled: currentPage <= 1 }">
                                <button type="button" class="page-link" @click.prevent="renderList(currentPage - 1)"></button>
                            </li>

                            <li class="paginate_button page-item" v-for="(page, key) in pages" :key="makeid(10)+key" :class="isCurrentPage(page) ? 'active' : ''">
                                <button type="button" class="page-link" @click.prevent="renderList(page)">{{ page }}</button>
                            </li>

                            <li class="paginate_button page-item next" :class="{ disabled: currentPage >= totalPages }">
                                <button type="button" class="page-link" @click.prevent="renderList(currentPage + 1)"></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="f-12 text-center" v-if="dataToDisplay.length == 0">There is no data</p>
        </div>
        <div class="modal fade text-left show" id="modalFilterDocument" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Filter</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Effective Date</label>
                                    <div class="row">
                                        <div class="col-6">
                                            <date-picker
                                                v-model="model.filter.effective_date_start"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="From"
                                                input-class="form-control"
                                                class="w-100">
                                            </date-picker>
                                        </div>
                                        <div class="col-6">
                                            <date-picker
                                                v-model="model.filter.effective_date_end"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="To"
                                                input-class="form-control"
                                                class="w-100">
                                            </date-picker>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-6" style="border-right: 1px solid #f5f5f5">
                                <div v-for="(type, index) in types" :key="makeid(10)+index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Type' : 'Sub Type ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.types[index]" @change="onChangeType($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexType) in type.choices" :key="makeid(10)+indexType">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(section, index) in sections" :key="makeid(10)+index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Section' : 'Sub Section ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.sections[index]" @change="onChangeSection($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="makeid(10)+indexSection">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-1">
                            <button class="btn btn-outline-primary f-12" @click="resetFilter()">Reset</button> &nbsp;
                            <button class="btn btn-primary f-12" data-dismiss="modal" @click="doFilter()">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            table: {
                lists: []
            },
            maxColumn: {
                edm_document_parent_section: 1
            },
            perPage: 10,
            pageToOpen: 1,
            currentPage: 1,
            dataToDisplay: [],
            
            
            search: '',
            model: {
                filter: {
                    effective_date_start: '',
                    effective_date_end: '',
                    type: '',
                    group: '',
                    position: '',
                    section: ''
                }
            },
            types: [],
            sections: [],
            dataFilter: {
                sections: [],
                types: [],
            },
            backup: {
                sections: [],
                types: [],
            }
        }
    },
    computed: {
        dataTable() {
            let _ = this
            let allData = _.table.lists

            if ( _.search ) {
                allData = allData.filter(function(data) {
                    return data.edm_document_number.toLowerCase().indexOf(_.search.toLowerCase()) > -1 || 
                        data.edm_document_title.toLowerCase().indexOf(_.search.toLowerCase()) > -1 ||
                        data.user_employee_id.toLowerCase().indexOf(_.search.toLowerCase()) > -1 ||
                        data.user_name.toLowerCase().indexOf(_.search.toLowerCase()) > -1
                })
            }

            if ( _.model.filter.effective_date_start && _.model.filter.effective_date_end ) {
                allData = allData.filter(function(data) {
                    return data.edm_document_effective_date >= _.model.filter.effective_date_start && 
                        data.edm_document_effective_date <= _.model.filter.effective_date_end
                })
            } else if ( _.model.filter.effective_date_start ) {
                allData = allData.filter(function(data) {
                    return data.edm_document_effective_date >= _.model.filter.effective_date_start
                })
            } else if ( _.model.filter.effective_date_end ) {
                allData = allData.filter(function(data) {
                    return data.edm_document_effective_date <= _.model.filter.effective_date_end
                })
            }

            if ( _.model.filter.section ) {
                allData = allData.filter(function(data) {
                    return JSON.parse(data.edm_document_parent_section).map( x => x.code_unique ).includes(_.model.filter.section)
                })
            }

            if ( _.model.filter.type ) {
                allData = allData.filter(function(data) {
                    return JSON.parse(data.edm_document_type).map( x => x.code ).includes(_.model.filter.type)
                })
            }

            return allData
        },
        totalPages(){
            return this.dataTable.length && (this.dataTable.length > this.perPage) ? Math.ceil(this.dataTable.length/this.perPage) : 1;
        },
        start(){
            return (this.pageToOpen - 1) * this.perPage;
        },
        stop(){
            if((this.dataTable.length - this.start) >= this.perPage){
                return (this.pageToOpen * this.perPage) - 1;
            } else{
                return this.dataTable.length - 1;
            }
        },
        pages() {
			let current         = this.currentPage
			let last            = this.totalPages
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/v2/document/user_not_read')
                .then(resp=>{
                    const dateNow = _.$moment()

                    _.table.lists = resp.data.data.map(function( x ) {
                        return {
                            ...x,
                            edm_document_initial: _.$moment(x.edm_document_effective_date) > dateNow ? parseInt(x.edm_document_initial)-1 : parseInt((x.edm_document_initial)),
                            edm_document_effective_date: _.$moment(x.edm_document_effective_date) > dateNow ? x.edm_document_effective_date_history : x.edm_document_effective_date,
                        }
                    })
                    this.renderList()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        renderList(pageNumber=1){
            this.dataToDisplay = [];
            
            if(this.dataTable.length){
                let _ = this;
                return new Promise(function(res){
                    _.pageToOpen = pageNumber;
                    for(let i = _.start; i <= _.stop; i++){
                        _.dataToDisplay.push(_.dataTable[i]);
                    }
                    res();
                }).then(function(){
                    _.currentPage = _.pageToOpen;
                    _.maxColumn = {
                        edm_document_parent_section: 1
                    }
                    _.dataToDisplay.forEach(elm => {
                        if ( _.maxColumn.edm_document_parent_section < JSON.parse(elm.edm_document_parent_section).length ) {
                            _.maxColumn.edm_document_parent_section = JSON.parse(elm.edm_document_parent_section).length
                        }
                    })
                }).catch(function(){
                    console.log('render err');
                });                  
            }
        },
        getDiff(x) {
            let _ = this
            let expiredAt = _.$moment(x).format('YYYY-MM-DD') + ' 00:00:00'
            let now = _.$moment().format('YYYY-MM-DD') + ' 00:00:00'
            let diff = (_.$moment(expiredAt).diff(_.$moment(now), 'days')) * -1

            return diff > 0 ? '+' + diff : diff
        },
        isCurrentPage(page){
			return this.currentPage === page
		},
        getDocumentType() {
            let _ = this
            _.axios.get('/v2/repositories/master-file')
                .then(resp=>{
                    _.types.push({ choices: resp.data.data })
                    _.backup.types.push({ choices: resp.data.data })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocumentSection() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then(resp=>{
                    _.sections.push({ choices: resp.data.data })
                    _.backup.sections.push({ choices: resp.data.data })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        download(params) {
            let _ = this
            _.axios.get('/v2/document/' + params
                + '?search=' + _.search
                + '&effective_date_start=' + _.model.filter.effective_date_start
                + '&effective_date_end=' + _.model.filter.effective_date_end
                + '&group=' + _.model.filter.section
                + '&type=' + _.model.filter.type
                ,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = params + '-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        onChangeType( ev, index ) {
            let type = JSON.parse(ev.target.value)

            for (let i = index+1; i < this.types.length; i++) {
                this.types.splice(i, 1)
            }

            if (type.children.length > 0) {
                this.types.push( { choices: type.children } )
            }
        },
        onChangeSection( ev, index ) {
            let section = JSON.parse(ev.target.value)

            this.dataFilter.sections.splice(index+1, this.sections.length)

            this.sections.splice(index+1, this.sections.length)

            if (section.children && section.children.length > 0) {
                this.sections.push( { choices: section.children } )
            }
        },
        resetFilter() {
            this.model.filter = {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                section: '',
            },
            this.dataFilter = {
                types: [],
                sections: [],
            }
            this.types = JSON.parse(JSON.stringify(this.backup.types))
            this.sections = JSON.parse(JSON.stringify(this.backup.sections))
            this.renderList()
        },
        doFilter() {
            let _ = this

            _.model.filter.type = ''
            _.dataFilter.types.forEach(elm => {
                let type = JSON.parse(elm)
                _.model.filter.type  = type.code
            })
            _.model.filter.type = _.model.filter.type.trim()

            _.model.filter.section = ''
            _.dataFilter.sections.forEach(elm => {
                let section = JSON.parse(elm)
                _.model.filter.section  = section.code_unique
            })
            this.renderList()
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    watch: {
        perPage: function(){
            this.renderList();
        },
        search: function(){
            this.renderList();
        },
    },
    mounted() {
        let _ = this        

        _.index()
        _.getDocumentType()
        _.getDocumentSection()
    },
}
</script>