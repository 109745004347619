import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// import Undermaintenance from '../views/errors/503.vue'

import Login from '../views/auth/Login.vue'
import Version from '../views/auth/Version.vue'
import Dashboard from '../views/dashboard/Index.vue'
import ReserveAndRegistrationIndex from '../views/manage-document/reserve-and-registration/Index.vue'
import ReportsIndex from '../views/manage-document/reports/Index.vue'
import MasterFileIndex from '../views/master-file/Index.vue'
import UserAndRoleIndex from '../views/user-and-role/Index.vue'
import ChangePassword from '../views/ChangePassword.vue'

import ResetDocument from '../views/manage-document/reset-document/Index.vue'
import ResetUser from '../views/manage-document/reset-user/Index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    pageTitle: '',
                    icon: ''
                }
            },
            {
                path: '/document',
                redirect: '/document/reserve-and-registration'
            },
            {
                path: '/document/reserve-and-registration',
                name: 'ReserveAndRegistrationIndex',
                component: ReserveAndRegistrationIndex,
                meta: {
                    pageTitle: 'Reserve & Registration',
                    icon: ''
                }
            },
            {
                path: '/document/reports',
                name: 'ReportsIndex',
                component: ReportsIndex,
                meta: {
                    pageTitle: 'Reports',
                    icon: ''
                }
            },
            {
                path: '/document/reset-document',
                name: 'ResetDocument',
                component: ResetDocument,
                meta: {
                    pageTitle: 'Reset of Doc Must Read: Based On Document',
                    icon: ''
                }
            },
            {
                path: '/document/reset-user',
                name: 'ResetUser',
                component: ResetUser,
                meta: {
                    pageTitle: 'Reset of Doc Must Read: Based On User',
                    icon: ''
                }
            },
            {
                path: '/master-file',
                name: 'MasterFileIndex',
                component: MasterFileIndex,
                meta: {
                    pageTitle: 'Master File',
                    icon: ''
                }
            },
            {
                path: '/user-and-role-management',
                name: 'UserAndRoleIndex',
                component: UserAndRoleIndex,
                meta: {
                    pageTitle: 'User & Role Management',
                    icon: ''
                }
            },
            {
                path: '/change-password',
                name: 'ChangePassword',
                component: ChangePassword,
                meta: {
                    pageTitle: 'Change Password',
                    icon: ''
                }
            },
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            }
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/version',
                name: 'App Version',
                component: Version,
                meta: {
                    isGuest: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.path == '/' ) {
        if (!store.state.token && !localStorage.getItem('token')) {
            next({name: 'Login'})
        }
        
        let user = JSON.parse(localStorage.getItem('user'))
        if (user.level == 'user' || user.level == 'user-dokumen') {
            next({path: '/dashboard'})
        } else {
            next({path: '/document/reserve-and-registration'})
        }
    } else if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.token && !localStorage.getItem('token') ) {
        next({name: 'Login'})
    } else {
        next()
    }
})

export default router
