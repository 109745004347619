<template>
    <div>
        <Table
            :withCard="true"
            :dataTable="table"
            :filterUser="true"
            :limit="true"
            :search="true"
            :exportExcelUser="downloadParams"
            :pagination="true">

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>User Detail</th>
                        <th>Email</th>
                        <th>Position</th>
                        <th>Role</th>
                        <th v-for="(data, index) in maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:tbody>
                <tbody v-if="table.lists">
                    <tr v-for="(data, index) in table.lists.data" :key="makeid(10)+index">
                        <td>{{ data.employee_id }}</td>
                        <td>{{ data.user_detail }}</td>
                        <td>{{ data.user_email ? data.user_email : '-' }}</td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="position in JSON.parse(data.position_id)" :key="makeid(10)+position.id">
                                    {{ position.position }}
                                </li>
                            </ul>
                            <span v-if="JSON.parse(data.position_id).length == 0">-</span>
                        </td>
                        <td>{{ data.level }}</td>
                        <td v-for="(dt, i) in maxColumn.section" :key="makeid(10)+i">
                            {{ data.section && JSON.parse(data.section)[i] ? JSON.parse(data.section)[i].code : '-' }}
                        </td>
                        <td class="text-center">
                            <div class="btn-action-block">
                                <button class="btn p-0 f-18 text-primary mr-1" title="Edit" data-target="#modalFormUser" data-toggle="modal" @click="setModel(data)">
                                    <img :src="$siteUrl + '/assets/img/icon/edit.png'" alt="" class="img-fluid">
                                </button>
                                <button class="btn p-0 f-18 text-danger" title="Delete" @click="destroy(data)">
                                    <img :src="$siteUrl + '/assets/img/icon/trash.png'" alt="" class="img-fluid">
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </Table>
    </div>
</template>
<script>
import Table from '@/components/TableTypeSection'

export default {
    components: { 
        Table 
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
                search: _.search,
                position: _.filter.position,
                section: _.filter.group,
                group: _.filter.group
            }
            return data
        }
    },
    data() {
        return {
            table: {
                headers: ['Employee ID', 'User Detail', 'Email', 'Position', '', 'Action'],
                columns: ['employee_id', 'user_detail', 'user_email', 'position_id', 'section'],
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                group: '',
                section: '',
                position: ''
            },
            maxColumn: {
                section: 1
            },
        }
    },
    methods: {
        showForm(data) {
            let _ = this
            _.$root.$emit('showForm', true)
            _.$root.$emit('showFormRegistration', data)
        },
        index() {
            let _ = this

            _.axios.get('/v1/repositories/user?search=' + _.search + '&page=' + _.page + '&limit=' + _.limit + '&position=' + _.filter.position + '&group=' + _.filter.group + '&section=' + _.filter.group)
                .then(resp=>{
                    _.table.lists = resp.data.data

                    _.maxColumn = {
                        section: 1
                    }

                    _.table.lists.data.forEach(elm => {
                        if (elm.section) {
                            if ( _.maxColumn.section < JSON.parse(elm.section).length ) {
                                _.maxColumn.section = JSON.parse(elm.section).length
                            }
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus user ini?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/v1/repositories/user/delete/' + x.id, { is_delete: 'true' })
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        setModel( model ) {
            this.$root.$emit('setModel', model)
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    mounted() {
        let _ = this        
        _.index()
        _.$root.$on("search", (data) => {
            _.search = data
            _.index()
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.index()
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.index()
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.index()
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")
    },
}
</script>