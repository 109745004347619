<template>
    <div>
        <Table v-if="!userDetail"
            :withCard="false"
            :dataTable="table"
            :filterUser="true"
            :limit="true"
            :search="true"
            :pagination="true"
            :exportExcelUser="downloadParams">

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>User Detail</th>
                        <th>Email</th>
                        <th>Position</th>
                        <th v-for="(data, index) in maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:tbody>
                <tbody v-if="table.lists">
                    <tr v-for="(data, index) in table.lists.data" :key="makeid(10)+index">
                        <td>{{ data.employee_id }}</td>
                        <td>{{ data.user_detail }}</td>
                        <td>{{ data.user_email ? data.user_email : '-' }}</td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="position in JSON.parse(data.position_id)" :key="makeid(10)+position.id">
                                    {{ position.position }}
                                </li>
                            </ul>
                            <span v-if="JSON.parse(data.position_id).length == 0">-</span>
                        </td>
                        <td v-for="(dt, i) in maxColumn.section" :key="makeid(10)+i">
                            {{ data.section && JSON.parse(data.section)[i] ? JSON.parse(data.section)[i].code : '-' }}
                        </td>
                        <td>
                            <div class="btn-action-block" v-if="JSON.parse(data.position_id).length > 0 ">
                                <button class="btn p-0 f-18 text-primary mr-1" title="Detail" @click="getDocuments(data)">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </Table>

        <section v-if="userDetail">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <button class="btn btn-sm font-600" style="font-size: 16px;" @click="back()">
                            <i class="fa fa-arrow-left mr-1"></i> Documents User
                        </button>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-outline-primary" @click="download()">Export Excel</button>
                    </div>
                </div>
            </div>

            <div class="card mb-0">
                <div class="card-body pb-0">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left">ID Number</th>
                                    <th class="text-left">User</th>
                                    <th class="text-left" v-for="(data, index) in userDetailMaxColumn" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                                    <th class="text-left">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ userDetail.employee_id }}</td>
                                    <td class="text-left">{{ userDetail.user_detail }}</td>
                                    <td v-for="(dt, i) in userDetailMaxColumn" :key="makeid(10)+i">
                                        {{ JSON.parse(userDetail.section)[i] ? JSON.parse(userDetail.section)[i].code : '-' }}
                                    </td>
                                    <td class="text-left">{{ userDetail.user_email ? userDetail.user_email : '-' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card mb-0">
                <div class="card-body pb-0">
                    <p class="font-600 f-10" style="padding: 5px 10px;" :style="{ background: '#FFEFEF', color: '#FF5D5D' }">Belum Baca</p>
                    <TableCustom
                        :id="'tableBelumBaca'"
                        :withCard="false"
                        :dataTable="tableBelumBaca"
                        :limit="true"
                        :pagination="true">
                    </TableCustom>
                </div>
            </div>
            <hr>
            <div class="card mb-0">
                <div class="card-body pb-0">
                    <p class="font-600 f-10" style="padding: 5px 10px;" :style="{ background: '#E9F0F8', color: '#1D4E9E' }">Belum Mengerti</p>
                    <TableCustom
                        :id="'tableBelumMengerti'"
                        :withCard="false"
                        :dataTable="tableBelumMengerti"
                        :limit="true"
                        :pagination="true">
                    </TableCustom>
                </div>
            </div>
            <hr>
            <div class="card mb-0">
                <div class="card-body pb-0">
                    <p class="font-600 f-10" style="padding: 5px 10px;" :style="{ background: '#EBF9F5', color: '#36C697' }">Sudah Mengerti</p>
                    <TableCustom
                        :id="'tableSudahMengerti'"
                        :withCard="false"
                        :dataTable="tableSudahMengerti"
                        :limit="true"
                        :pagination="true">
                    </TableCustom>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Table from '@/components/TableTypeSection'
import TableCustom from '@/components/TableCustom'

export default {
    components: { 
        Table,
        TableCustom 
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
                search: _.search,
                position: _.filter.position,
                section: _.filter.group,
                group: _.filter.group
            }
            return data
        }
    },
    data() {
        return {
            table: {
                headers: ['Employee ID', 'User Detail', 'Email', 'Position', '', 'Action'],
                columns: ['employee_id', 'user_detail', 'user_email', 'position_id', 'section'],
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                group: '',
                position: ''
            },
            userDetail: null,
            dashboards: [],
            tableBelumBaca: {
                headers: ['Document Number', 'Title', 'Baca', 'Mengerti', 'Akses', 'Overdue'],
                columns: ['document_number', 'title', 'first_access_date', 'understand_date', 'last_access_date', 'overdue'],
                lists: []
            },
            tableBelumMengerti: {
                headers: ['Document Number', 'Title', 'Baca', 'Mengerti', 'Akses', 'Overdue'],
                columns: ['document_number', 'title', 'first_access_date', 'understand_date', 'last_access_date', 'overdue'],
                lists: []
            },
            tableSudahMengerti: {
                headers: ['Document Number', 'Title', 'Baca', 'Mengerti', 'Akses', 'Overdue'],
                columns: ['document_number', 'title', 'first_access_date', 'understand_date', 'last_access_date', 'overdue'],
                lists: []
            },
            
            pagetableBelumBaca: 1,
            limittableBelumBaca: 10,

            pagetableBelumMengerti: 1,
            limittableBelumMengerti: 10,

            pagetableSudahMengerti: 1,
            limittableSudahMengerti: 10,
            maxColumn: {
                section: 1
            },
            userDetailMaxColumn: 1
        }
    },
    methods: {
        isJsonString(str) {
            try {
                JSON.parse(str)
            } catch (e) {
                return false
            }
            return true
        },
        index() {
            let _ = this

            _.axios.get('/v1/repositories/user' + 
                '?search=' + _.search + 
                '&page=' + _.page + 
                '&limit=' + _.limit + 
                '&position=' + _.filter.position + 
                '&group=' + _.filter.group + 
                '&section=' + _.filter.group + 
                '&hide_user_dokumen=true')
                .then(resp=>{
                    _.table.lists = resp.data.data

                    _.maxColumn = {
                        section: 1
                    }

                    _.table.lists.data.forEach(elm => {
                        if (elm.section) {
                            if ( _.maxColumn.section < JSON.parse(elm.section).length ) {
                                _.maxColumn.section = JSON.parse(elm.section).length
                            }
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocuments(data) {
            let _ = this
            _.userDetail = data

            _.userDetailMaxColumn = 1

            if ( _.userDetailMaxColumn < JSON.parse(_.userDetail.section).length ) {
                _.userDetailMaxColumn = JSON.parse(_.userDetail.section).length
            }

            _.getTableBelumBaca()
            _.getTableBelumMengerti()
            _.getTableSudahMengerti()
        },
        getTableBelumBaca() {
            let _ = this
            _.axios.get('/v2/document/user_report_must_read/' + _.userDetail.id + '?page=' + _.pagetableBelumBaca + '&limit=' + _.limittableBelumBaca)
                .then(resp=>{
                    _.tableBelumBaca.lists = resp.data.data
                    _.tableBelumBaca.lists.data = _.tableBelumBaca.lists.data.map(function( x ) {
                        return {
                            ...x,
                            initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getTableBelumMengerti() {
            let _ = this
            _.axios.get('/v2/document/user_report_not_understand/' + _.userDetail.id + '?page=' + _.pagetableBelumMengerti + '&limit=' + _.limittableBelumMengerti)
                .then(resp=>{
                    _.tableBelumMengerti.lists = resp.data.data
                    _.tableBelumMengerti.lists.data = _.tableBelumMengerti.lists.data.map(function( x ) {
                        return {
                            ...x,
                            initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getTableSudahMengerti() {
            let _ = this
            _.axios.get('/v2/document/user_report_understand/' + _.userDetail.id + '?page=' + _.pagetableSudahMengerti + '&limit=' + _.limittableSudahMengerti)
                .then(resp=>{
                    _.tableSudahMengerti.lists = resp.data.data
                    _.tableSudahMengerti.lists.data = _.tableSudahMengerti.lists.data.map(function( x ) {
                        return {
                            ...x,
                            initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        back() {
            let _ = this
            _.userDetail = null
            _.pagetableBelumBaca = 1
            _.limittableBelumBaca = 10
            _.pagetableBelumMengerti = 1
            _.limittableBelumMengerti = 10
            _.pagetableSudahMengerti = 1
            _.limittableSudahMengerti = 10
        },
        download() {
            let _ = this

            _.axios.get('/v2/document/user_threepoint_export/' + _.userDetail.id,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'document-user-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    mounted() {
        let _ = this        
        _.index()
        _.$root.$on("search", (data) => {
            _.search = data
            _.index()
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.index()
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.index()
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.index()
        })

        _.$root.$on("pagetableBelumBaca", (data) => {
            _.pagetableBelumBaca = data
            _.getTableBelumBaca()
        })
        _.$root.$on("limittableBelumBaca", (data) => {
            _.limittableBelumBaca = data
            _.pagetableBelumBaca = 1
            _.getTableBelumBaca()
        })
        
        _.$root.$on("pagetableBelumMengerti", (data) => {
            _.pagetableBelumMengerti = data
            _.getTableBelumMengerti()
        })
        _.$root.$on("limittableBelumMengerti", (data) => {
            _.limittableBelumMengerti = data
            _.pagetableBelumMengerti = 1
            _.getTableBelumMengerti()
        })
        
        _.$root.$on("pagetableSudahMengerti", (data) => {
            _.pagetableSudahMengerti = data
            _.getTableSudahMengerti()
        })
        _.$root.$on("limittableSudahMengerti", (data) => {
            _.limittableSudahMengerti = data
            _.pagetableSudahMengerti = 1
            _.getTableSudahMengerti()
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")

        _.$root.$off("pagetableBelumBaca")
        _.$root.$off("limittableBelumBaca")

        _.$root.$off("pagetableBelumMengerti")
        _.$root.$off("limittableBelumMengerti")

        _.$root.$off("pagetableSudahMengerti")
        _.$root.$off("limittableSudahMengerti")
    },
}
</script>