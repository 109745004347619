<template>
    <div>
        <div class="card card-custom mb-2">
            <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a 
                            class="nav-link active text-black f-12" 
                            id="reservation-tab" 
                            data-toggle="tab" 
                            href="#reservation" 
                            role="tab" 
                            aria-controls="reservation" 
                            aria-selected="true" 
                            @click="activeState = 'document-reservation'">
                            Document Reservation
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12" 
                            id="revision-tab" 
                            data-toggle="tab" 
                            href="#revision" 
                            role="tab" 
                            aria-controls="revision" 
                            aria-selected="false" 
                            @click="activeState = 'document-revision'">
                            Document Revision
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="reservation" role="tabpanel" aria-labelledby="reservation-tab">
                        <ReservationTab v-if="activeState == 'document-reservation'"/>
                    </div>
                    <div class="tab-pane fade show" id="revision" role="tabpanel" aria-labelledby="revision-tab">
                        <RevisionTab v-if="activeState == 'document-revision'"/>
                    </div>
                </div>
            </div>
        </div>
        
        <ReservationList v-if="!isShowForm && activeState == 'document-reservation'"/>
        <RevisionList v-if="!isShowForm && activeState == 'document-revision'"/>
    </div>
</template>
<script>
import ReservationTab from './tab-reservation/Index'
import ReservationList from './tab-reservation/List'
import RevisionTab from './tab-revision/Index'
import RevisionList from './tab-revision/List'

export default {
    components: {
        ReservationTab,
        ReservationList,
        RevisionTab,
        RevisionList
    },
    data() {
        return {
            activeState: 'document-reservation',
            isShowForm: false
        }
    },
    mounted() {
        let _ = this
        _.$root.$on("showForm", (data) => {
            _.isShowForm = data
        })
    },
    watch: {
        activeState: function() {
            this.isShowForm = false
        }
    }
}
</script>