import Vue from 'vue'

Vue.mixin({
  methods: {
    numToSSColumn(num){
      var s = '', t;

      while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t)/26 | 0;
      }
      return s || 'A';
    }
  }
})