export default {
	UPDATE_USER(state, payload) {
		state.token = payload.token
    	state.user = payload.user

    	localStorage.setItem('token', payload.token)
    	localStorage.setItem('user', JSON.stringify(payload.user))
    },
    LOGOUT_USER(state) {
    	state.token = null
    	state.user = null
    	localStorage.removeItem('token')
		localStorage.removeItem('user')
    },
}