<template>
    <div class="pl-1 pr-1 pb-1">
        <div class="card card-custom-grey mb-0" v-if="state == 'index'">
            <div class="card-body p-1">
                <div class="row">
                    <div class="col align-self-center">
                        <p class="font-500 f-12 mb-0">Create New Document Reservation</p>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-primary font-400 f-10" data-target="#modalDocumentType" data-toggle="modal">Get Number</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-0" v-if="state == 'formGetNumber'">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col" style="border-right: 1px solid #f5f5f5">
                        <ValidationObserver ref="observerReserveNumber">
                            <form @submit.prevent="getDocumentNumber()">
                                <p class="font-500 f-12">Enter Reserve Number</p>
                                <div class="row">
                                    <div class="col-12" v-can="'admin-level'">
                                        <ValidationProvider name="Document Access Level (Up)" v-slot="{ errors }">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Document Access Level (Up)</label>
                                                <select class="form-control" v-model="modelReserveNumber.accessLevel">
                                                    <option v-for="level in accessLevels" :key="level.id" :value="JSON.stringify(level)">
                                                        {{ level.position }}
                                                    </option>
                                                </select>
                                                <span class="f-10 text-warning"><img :src="$siteUrl + '/assets/img/icon/info-warning.png'" alt="" class="img-fluid"> Levels below the selected level are unable to access the document</span>
                                                <p class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</p>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6" style="border-right: 1px solid #f5f5f5;">
                                        <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Type</label>
                                                <select class="form-control" v-model="modelReserveNumber.types[0]" disabled>
                                                    <option :value="JSON.stringify(type)" v-for="type in types" :key="type.id">{{ type.code }} - {{ type.title }}</option>
                                                </select>
                                                <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>

                                        <div v-for="(subs, index) in subTypes" :key="index">
                                            <ValidationProvider :name="'Sub Type'" v-slot="{ errors }">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Sub Type {{ index+1 }}</label>
                                                    <select class="form-control" v-model="modelReserveNumber.types[index+1]" @change="onChangeSubType($event, index)">
                                                        <option :value="JSON.stringify(sub)" v-for="(sub, indexSub) in subs.children" :key="indexSub">
                                                            {{ sub.code }} - {{ sub.title }}
                                                        </option>
                                                    </select>
                                                    <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div v-for="(section, index) in sections" :key="index">
                                            <ValidationProvider :name="'Sub Section'" :rules="{ required: index == 0 ? true : false }" v-slot="{ errors }">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">{{ index == 0 ? 'Section' : 'Sub Section ' + (index)}}</label>
                                                    <select class="form-control" v-model="modelReserveNumber.sections[index]" @change="onChangeSection($event, index)">
                                                        <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="indexSection">
                                                            {{ choice.code }} - {{ choice.title }}
                                                        </option>
                                                    </select>
                                                    <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <!-- <json-viewer :value="selectedAccessLevel" :expand-depth="5"></json-viewer> -->
                                <div class="text-center mt-1">
                                    <button class="btn btn-primary font-400 f-10" type="submit">Get Number</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="col">
                        <ValidationObserver ref="observerExecute">
                            <form @submit.prevent="execute()">
                                <p class="font-500 f-12">Enter Document Reserve Number</p>
                                <ValidationProvider name="Document Number" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Document Number</label>
                                        <input type="text" class="form-control" v-model="modelReserveNumber.document_number" disabled>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Originator" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Originator</label>
                                        <multiselect 
                                            v-model="modelReserveNumber.originators"
                                            label="user_detail"
                                            track-by="id" 
                                            placeholder=""
                                            :options="originators.filter( x => JSON.parse(x.position_id).length != 0 )"
                                            :multiple="true" 
                                            :clear-on-select="false" 
                                            :close-on-select="false"
                                            :custom-label="customLabel">
                                            <span slot="noResult">Oops! Data not found.</span>
                                        </multiselect>
                                        <input type="hidden" v-model="modelReserveNumber.originators">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Title</label>
                                        <input type="text" class="form-control" v-model="modelReserveNumber.title">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="text-center">
                                    <button class="btn btn-primary font-400 f-10" type="submit" :disabled="!modelReserveNumber.document_number">
                                        Execute
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-0" v-show="state == 'formNewRegistration' && modelRegistration != null">
            <div class="card-body p-0">
                <p class="font-500 f-12"><span class="fa fa-arrow-left mr-1 pointer" @click="state = 'index', back()"></span> New Document Registration</p>
                <ValidationObserver ref="formNewRegistration">
                    <form @submit.prevent="register()">
                        <div class="row" v-if="modelRegistration">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="">Document No.</label>
                                    <input type="text" class="form-control" v-model="modelRegistration.document_number" disabled>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="">Formerly (If Any)</label>
                                    <input type="text" class="form-control" v-model="modelRegistration.formerly">
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(type, index) in modelRegistration.type" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Type' : 'Sub Type ' + (index)}}</label>
                                        <input type="text" class="form-control" :value="type.code + ' - ' + type.title" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(section, index) in modelRegistration.parent_section" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Section' : 'Sub Section ' + (index)}}</label>
                                        <input type="text" class="form-control" :value="section.code + ' - ' + section.title" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Revision Code</label>
                                    <input type="text" class="form-control" v-model="revision_code" disabled>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Document Title</label>
                                    <input type="text" class="form-control" v-model="modelRegistration.title" disabled>
                                </div>
                            </div>
                            <div class="col-6">
                                <ValidationProvider name="Effective Date" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label for="">Effective Date</label>
                                        <date-picker
                                            v-model="modelRegistration.effective_date"
                                            @change="modelRegistration.next_review_date = $moment(modelRegistration.effective_date).add(modelRegistration.type[modelRegistration.type.length-1].expire, (modelRegistration.type[modelRegistration.type.length-1].periode ? modelRegistration.type[modelRegistration.type.length-1].periode : 'week')).subtract(1, 'days').format('YYYY-MM-DD')"
                                            :disabled-date="disabledBeforeToday"
                                            format="YYYY/MM/DD"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Tanggal"
                                            input-class="form-control"
                                            class="w-100">
                                        </date-picker>
                                        <span class="f-10 text-warning"><img :src="$siteUrl + '/assets/img/icon/info-warning.png'" alt="" class="img-fluid"> The selected document type has an expiration date of {{modelRegistration.type[modelRegistration.type.length-1].expire}} {{ modelRegistration.type[modelRegistration.type.length-1].periode ? modelRegistration.type[modelRegistration.type.length-1].periode : 'week' }}{{modelRegistration.type[modelRegistration.type.length-1].expire > 1 ? 's' : ''}}</span>
                                        <br><small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-6">
                                <ValidationProvider name="Next Review Date" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label for="">Next Review Date</label>
                                        <date-picker
                                            :disabled="!modelRegistration.effective_date"
                                            v-model="modelRegistration.next_review_date"
                                            :disabled-date="disabledBeforeEffectiveDate"
                                            format="YYYY/MM/DD"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Tanggal"
                                            input-class="form-control"
                                            class="w-100">
                                        </date-picker>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="History Of Document" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                        <label for="">History Of Document</label>
                                        <textarea name="" id="" cols="30" rows="3" class="form-control" v-model="modelRegistration.history_document"></textarea>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Document Access Level (Up)</label>
                                    <!-- <select class="form-control" v-model="modelRegistration.access_level" disabled>
                                        <option v-for="level in accessLevels" :key="level.id" :value="level.id">
                                            {{ level.position }}
                                        </option>
                                    </select> -->
                                    <input type="text" class="form-control" disabled v-model="showAccessLevel">
                                    <span class="f-10 text-warning"><img :src="$siteUrl + '/assets/img/icon/info-warning.png'" alt="" class="img-fluid"> Levels below the selected level are unable to access the document</span>
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="">Owner</label>
                                    <input type="text" class="form-control" disabled>
                                </div>
                            </div> -->
                            <div class="col-12">
                                <hr style="border-style:dashed;">
                            </div>
                            <div class="col-12">
                                <p class="font-500 f-12">Document Original Section/Team</p>
                                <div class="form-group">
                                    <label for="">Approver
                                        <span class="text-primary pointer" data-target="#modalApprover" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="backupData = JSON.parse(JSON.stringify(modelRegistration.approval))">
                                            <img :src="$siteUrl + '/assets/img/icon/circle-plus.png'" alt="" class="img-fluid"> Add Approver
                                        </span>
                                    </label>
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-8 f-12 font-600">Approver Name</div>
                                                <div class="col-4 f-12 font-600">Approval Date</div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-1 pr-1 pl-1 pb-0">
                                            <div class="mb-1" v-if="!modelRegistration.approval">
                                                <div class="card card-custom mb-0">
                                                    <div class="card-body pl-1 pt-1 pb-1">
                                                        <p class="mb-0 f-10">No one has been choosen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row mb-1" v-for="(approver, index) in modelRegistration.approval" :key="index">
                                                    <div class="col-8">
                                                        <div class="card card-custom mb-0">
                                                            <div class="card-body pl-1 pt-1 pb-1">
                                                                <div class="row">
                                                                    <img :src="$siteUrl + '/assets/img/icon/x-circle.png'" alt="" class="pointer img-fluid ml-1 align-self-center" style="height:fit-content;"
                                                                        @click="deleteModelRegistrationApprover(index)" title="Hapus">
                                                                    <p class="f-10 mb-0 ml-1">{{ approver.user_detail }} - {{ JSON.parse(approver.position_id)[0].position }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider :name="'Approval Date ' + (index + 1)" rules="required" v-slot="{ errors }">
                                                            <date-picker
                                                                format="YYYY/MM/DD"
                                                                type="date"
                                                                value-type="YYYY-MM-DD"
                                                                placeholder="Pilih Tanggal"
                                                                input-class="form-control h-100"
                                                                class="w-100"
                                                                style="height: 51px;"
                                                                v-model="approver.date"
                                                                :disabled-date="disabledDateForApprovalDate">
                                                            </date-picker>
                                                            <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ValidationProvider name="Approver" rules="required" v-slot="{ errors }">
                                        <input type="hidden" v-model="modelRegistration.approval">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <label for="">Reviewer 
                                        <span class="text-primary pointer" data-target="#modalReviewer" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="backupData = JSON.parse(JSON.stringify(modelRegistration.reviewer))">
                                            <img :src="$siteUrl + '/assets/img/icon/circle-plus.png'" alt="" class="img-fluid"> Add Reviewer
                                        </span>
                                    </label>
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-8 f-12 font-600">Reviewer Name</div>
                                                <div class="col-4 f-12 font-600">Review Date</div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-1 pr-1 pl-1 pb-0">
                                            <div class="mb-1" v-if="!modelRegistration.reviewer">
                                                <div class="card card-custom mb-0">
                                                    <div class="card-body pl-1 pt-1 pb-1">
                                                        <p class="mb-0 f-10">No one has been choosen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row mb-1" v-for="(reviewer, index) in modelRegistration.reviewer" :key="index">
                                                    <div class="col-8">
                                                        <div class="card card-custom mb-0">
                                                            <div class="card-body pl-1 pt-1 pb-1">
                                                                <div class="row">
                                                                    <img :src="$siteUrl + '/assets/img/icon/x-circle.png'" alt="" class="pointer img-fluid ml-1 align-self-center" style="height:fit-content;"
                                                                        @click="deleteModelRegistrationReviewer(index)" title="Hapus">
                                                                    <p class="f-10 mb-0 ml-1">{{ reviewer.user_detail }} - {{ JSON.parse(reviewer.position_id)[0].position }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider :name="'Review Date ' + (index + 1)" rules="required" v-slot="{ errors }">
                                                            <date-picker
                                                                format="YYYY/MM/DD"
                                                                type="date"
                                                                value-type="YYYY-MM-DD"
                                                                placeholder="Pilih Tanggal"
                                                                input-class="form-control h-100"
                                                                class="w-100"
                                                                style="height: 51px;"
                                                                v-model="reviewer.date"
                                                                :disabled-date="disabledDateForReviewerDate">
                                                            </date-picker>
                                                            <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ValidationProvider name="Reviewer" rules="required" v-slot="{ errors }">
                                        <input type="hidden" v-model="modelRegistration.reviewer">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <label for="">Originator 
                                        <span class="text-primary pointer" data-target="#modalOriginator" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="backupData = JSON.parse(JSON.stringify(modelRegistration.originator))">
                                            <img :src="$siteUrl + '/assets/img/icon/circle-plus.png'" alt="" class="img-fluid"> Add Originator
                                        </span>
                                    </label>
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-8 f-12 font-600">Originator Name</div>
                                                <div class="col-4 f-12 font-600">Origin Date</div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-1 pr-1 pl-1 pb-0">
                                            <div class="mb-1" v-if="modelRegistration.originator.length == 0">
                                                <div class="card card-custom mb-0">
                                                    <div class="card-body pl-1 pt-1 pb-1">
                                                        <p class="mb-0 f-10">No one has been choosen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row mb-1" v-for="(originator, index) in modelRegistration.originator" :key="index">
                                                    <div class="col-8">
                                                        <div class="card card-custom mb-0">
                                                            <div class="card-body pl-1 pt-1 pb-1">
                                                                <div class="row">
                                                                    <img :src="$siteUrl + '/assets/img/icon/x-circle.png'" alt="" class="pointer img-fluid ml-1 align-self-center" style="height:fit-content;"
                                                                        @click="deleteModelRegistrationOriginator(index)" title="Hapus">
                                                                    <p class="f-10 mb-0 ml-1">{{ originator.user_detail }} - {{ JSON.parse(originator.position_id)[0].position }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider :name="'Origin Date ' + (index + 1)" rules="required" v-slot="{ errors }">
                                                            <date-picker
                                                                format="YYYY/MM/DD"
                                                                type="date"
                                                                value-type="YYYY-MM-DD"
                                                                placeholder="Pilih Tanggal"
                                                                input-class="form-control h-100"
                                                                class="w-100"
                                                                style="height: 51px;"
                                                                v-model="originator.date"
                                                                :disabled-date="disabledDateForOriginatorDate">
                                                            </date-picker>
                                                            <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ValidationProvider name="Originator" rules="required" v-slot="{ errors }">
                                        <input type="hidden" v-model="modelRegistration.originator">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Related Department 
                                        <span class="text-primary pointer" data-target="#modalDepartment" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="backupData = JSON.parse(JSON.stringify(modelRegistration.departement))">
                                            <img :src="$siteUrl + '/assets/img/icon/circle-plus.png'" alt="" class="img-fluid"> Add Section
                                        </span>
                                    </label>
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-12 f-12 font-600">Department Name</div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-1 pr-1 pl-1 pb-0">
                                            <div class="mb-1" v-if="modelRegistration.parent_departement.length == 0">
                                                <div class="card card-custom mb-0">
                                                    <div class="card-body pl-1 pt-1 pb-1">
                                                        <p class="mb-0 f-10">No department has been choosen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card card-custom mb-1" v-for="(parent_departement, parentIndex) in modelRegistration.parent_departement" :key="parentIndex">
                                                <div class="col">
                                                    <div class="row pl-1">
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="mb-1 mt-1 mr-1" v-for="(departement, childrenIndex) in parent_departement" :key="childrenIndex" >
                                                                    <div class="card card-custom mb-0" style="width: fit-content;">
                                                                        <div class="card-body pl-1 pt-1 pb-1">
                                                                            <div class="row">
                                                                                <img :src="$siteUrl + '/assets/img/icon/x-circle.png'" alt="" class="pointer img-fluid ml-1 align-self-center" style="height:fit-content;"
                                                                                        @click="deleteChildrenDepartement(parentIndex, childrenIndex)" title="Hapus">
                                                                                <p class="f-10 mb-0 ml-1 mr-1">{{ departement.code }} - {{ departement.title }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="align-self-center mr-1">
                                                            <button class="btn btn-sm btn-danger" title="Hapus" type="button" @click="deleteParentDepartement(parentIndex)">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="">Pelaksana (Jabatan/Level) 
                                        <span class="text-primary pointer" data-target="#modalPelaksana" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="backupData = JSON.parse(JSON.stringify(modelRegistration.pelaksana))">
                                            <img :src="$siteUrl + '/assets/img/icon/circle-plus.png'" alt="" class="img-fluid"> Add Position
                                        </span>
                                    </label>
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-12 f-12 font-600">Position Name</div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-1 pr-1 pl-1 pb-0">
                                            <div class="mb-1" v-if="!modelRegistration.pelaksana">
                                                <div class="card card-custom mb-0">
                                                    <div class="card-body pl-1 pt-1 pb-1">
                                                        <p class="mb-0 f-10">No position has been choosen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-12 mb-1" v-for="(pelaksana, index) in modelRegistration.pelaksana" :key="index">
                                                    <div class="card card-custom mb-0">
                                                        <div class="card-body pl-1 pt-1 pb-1">
                                                            <div class="row">
                                                                <img :src="$siteUrl + '/assets/img/icon/x-circle.png'" alt="" class="pointer img-fluid ml-1 align-self-center" style="height:fit-content;"
                                                                        @click="deleteModelRegistrationPosition(index)" title="Hapus">
                                                                <p class="f-10 mb-0 ml-1">{{ pelaksana.position }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ValidationProvider name="Pelaksana" rules="required" v-slot="{ errors }">
                                        <input type="hidden" v-model="modelRegistration.pelaksana">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr style="border-style:dashed;">
                            </div>
                            <div class="col-12">
                                <p class="font-500 f-12">Related Document</p>
                                <div class="row">
                                    <div class="col" style="width: 45%;">
                                        <div class="card card-custom mb-1">
                                            <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                                <div class="row">
                                                    <div class="col-12 f-12 font-600">Possible Document</div>
                                                </div>
                                            </div>
                                            <div class="card-body p-1">
                                                <input type="text" class="form-control" v-model="searchPossibleDocument">
                                            </div>
                                            <div style="height: 250px; overflow:auto;" class="pl-1 pr-1 pb-1">
                                                <p class="f-10 p-0 mb-0 pointer" 
                                                    v-for="document in possibleDocuments" 
                                                    :key="document.id"
                                                    :style="selectedPossibleDocuments.length > 0 && selectedPossibleDocuments.map( x => x.id ).indexOf(document.id) != -1 ? { background: '#E8EDF5'} : ''"
                                                    @click="onClickPossibleDocument(document.id)">

                                                    <img :src="$siteUrl + '/assets/img/icon/arrow-right-circle.png'" alt="" class="img-fluid mr-1">
                                                    [{{ document.document_number }}-{{ numToSSColumn(document.initial) }}] {{ document.title }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center align-self-center p-0" style="width: 50px;">
                                        <img :src="$siteUrl + '/assets/img/icon/arrow-right.png'" alt="" class="img-fluid pointer" title="Move Right" @click="addToRelatedDocuments()">
                                        <img :src="$siteUrl + '/assets/img/icon/arrow-left.png'" alt="" class="img-fluid pointer" title="Move Left" @click="addToPossibleDocuments()">
                                    </div>
                                    <div class="col" style="width: 45%;">
                                        <div class="card card-custom mb-1">
                                            <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                                <div class="row">
                                                    <div class="col-12 f-12 font-600">Selected Document</div>
                                                </div>
                                            </div>
                                            <div class="card-body p-1">
                                                <input type="text" class="form-control" v-model="searchSelectedDocument">
                                            </div>
                                            <div style="height: 250px; overflow:auto;" class="pl-1 pr-1 pb-1">
                                                <p class="f-10 p-0 mb-0 pointer" 
                                                    v-for="document in selectedDocuments" 
                                                    :key="document.id"
                                                    :style="selectedRelatedDocuments.length > 0 && selectedRelatedDocuments.map( x => x.id ).indexOf(document.id) != -1 ? { background: '#E8EDF5'} : ''"
                                                    @click="onClickRelatedDocument(document.id)">

                                                    <img :src="$siteUrl + '/assets/img/icon/arrow-left-circle.png'" alt="" class="img-fluid mr-1">
                                                    [{{ document.document_number }}-{{ numToSSColumn(document.initial) }}] {{ document.title }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr style="border-style:dashed;">
                            </div>
                            <div class="col-12">
                                <p class="font-500 f-12">Document Files</p>
                                <div class="form-group">
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-12 f-12 font-600">Choose Document</div>
                                            </div>
                                        </div>
                                        <div class="card-body p-1">
                                            <div class="row">
                                                <div class="col">
                                                    <label for="">PDF File</label>
                                                    <ValidationProvider name="PDF File" rules="required" v-slot="{ errors }">
                                                        <label class="form-group input-group mb-0" for="file-upload">
                                                            <input type="text" class="form-control border-right-0 bg-white" v-model="modelRegistration.file_name" disabled>
                                                            <span class="input-group-append pointer">
                                                                <div class="input-group-text bg-primary f-12">
                                                                    Upload
                                                                </div>
                                                            </span>
                                                        </label>
                                                        <input id="file-upload" class="d-none" type="file" @change="handleFilePDFUpload( $event )">
                                                        <small class="f-10" v-if="uploadProgressPDF > 0 && uploadProgressPDF < 100">{{ uploadProgressPDF + '% Uploading' }}</small>
                                                        <small class="f-10" v-if="uploadProgressPDF == 100 && modelRegistration.file_name">Successfully Uploaded</small>
                                                        <small class="f-10 text-danger" v-else-if="isShowErrorPDFFile == true">Failed to upload file</small>
                                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <label for="">Original File</label>
                                                    <label class="form-group input-group mb-0" for="file-upload-original">
                                                        <input type="text" class="form-control border-right-0 bg-white" v-model="modelRegistration.file_original_name" disabled>
                                                        <span class="input-group-append pointer">
                                                            <div class="input-group-text bg-primary f-12">
                                                                Upload
                                                            </div>
                                                        </span>
                                                    </label>
                                                    <input id="file-upload-original" class="d-none" type="file" @change="handleFileOriginalUpload ( $event )">
                                                    <small class="f-10" v-if="uploadProgressOriginal > 0 && uploadProgressOriginal < 100">{{ uploadProgressOriginal + '% Uploading' }}</small>
                                                    <small class="f-10" v-if="uploadProgressOriginal == 100 && modelRegistration.file_original_name">Successfully Uploaded</small>
                                                    <small class="f-10 text-danger" v-else-if="isShowErrorOriFile == true">Failed to upload file</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr style="border-style:dashed;">
                            </div>
                            <div class="col-12">
                                <p class="font-500 f-12">Additional Information</p>
                                <div class="form-group">
                                    <div class="card card-custom mb-1">
                                        <div class="card-title p-1 mb-0" style="background:#E8EDF5">
                                            <div class="row">
                                                <div class="col-12 f-12 font-600">Note</div>
                                            </div>
                                        </div>
                                        <textarea cols="30" rows="4" class="form-control" style="border:0px;" placeholder="Type here..." v-model="modelRegistration.note"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary font-400 f-10" type="submit">Save</button>&nbsp;
                            <!-- <button class="btn btn-secondary font-400 f-10">Reset</button> -->
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalDocumentType" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Pilih Type Dokumen</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <p v-for="type in types" :key="type.id" class="f-10 pointer" data-dismiss="modal"
                            @click="chooseType(type), state = 'formGetNumber'">
                            {{ type.code + ' - ' + type.title }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalExecute" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th style="background: #1D4E9E;border-top-left-radius:0.6rem;" class="text-white font-500">Document Number</th>
                                <th style="background: #1D4E9E;" class="text-white font-500">Title</th>
                                <th style="background: #1D4E9E;" class="text-white font-500"></th>
                                <th style="background: #1D4E9E;" class="text-white font-500"></th>
                                <th style="background: #1D4E9E;" class="text-white font-500">Originator</th>
                                <th style="background: #1D4E9E;" class="text-white font-500">Position</th>
                                <th style="background: #1D4E9E;border-top-right-radius:0.6rem;" class="text-white font-500">Reserve Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="detailDocument">
                            <td class="text-primary font-600"><u>{{ detailDocument.document_number }}-{{ numToSSColumn(detailDocument.initial) }}</u></td>
                            <td>{{ detailDocument.title }}</td>
                            <td>
                                <span class="mr-1" v-for="type in JSON.parse(detailDocument.type)" :key="type.id">
                                    {{ type.code }}
                                </span>
                            </td>
                            <td>
                                <span class="mr-1" v-for="section in JSON.parse(detailDocument.section)" :key="section.id">
                                    {{ section.code }}
                                </span>
                            </td>
                            <td>
                                <ul class="mb-0 pl-0" style="list-style: none;">
                                    <li v-for="originator in JSON.parse(detailDocument.originator)" :key="originator.id">
                                        {{originator.user_detail}}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <div v-for="originator in JSON.parse(detailDocument.originator)" :key="originator.id">
                                    <ul class="mb-0 pl-0" style="list-style: none;">
                                        <li v-for="position in JSON.parse(originator.position_id)" :key="position.id">
                                            {{ position.position }}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td>{{ detailDocument.created_at | moment("YYYY/MM/DD") }}</td>
                        </tbody>
                    </table>
                    <div class="modal-footer w-100">
                        <div class="text-center w-100">
                            <button class="btn btn-success btn-sm f-10" title="Register" 
                                @click="state = 'formNewRegistration'"
                                data-dismiss="modal">
                                <img :src="$siteUrl + '/assets/img/icon/send-white.png'" alt="" class="img-fluid" @click="setModelRegistration(detailDocument)"> Register
                            </button>&nbsp;
                            <button class="btn btn-danger btn-sm f-10" title="Delete" @click="destroy(detailDocument)">
                                <img :src="$siteUrl + '/assets/img/icon/trash-white.png'" alt="" class="img-fluid"> Delete
                            </button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalOriginator" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Pilih Originator</p>
                        <span class="pull-right pointer" data-dismiss="modal" @click="modelRegistration.originator = backupData, backupData = null"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="form-group">
                            <label for="">Originator</label>
                            <multiselect 
                                v-model="modelRegistration.originator"
                                label="user_detail"
                                track-by="id" 
                                placeholder=""
                                :options="originators.filter( x => JSON.parse(x.position_id).length != 0 )"
                                :multiple="true" 
                                :clear-on-select="false" 
                                :close-on-select="false"
                                :custom-label="customLabel">
                                <span slot="noResult">Oops! Data not found.</span>
                            </multiselect>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn" data-dismiss="modal" @click="modelRegistration.originator = backupData, backupData = null">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalReviewer" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Pilih Reviewer</p>
                        <span class="pull-right pointer" data-dismiss="modal" @click="modelRegistration.reviewer = backupData, backupData = null"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="form-group">
                            <label for="">Reviewer</label>
                            <multiselect 
                                v-model="modelRegistration.reviewer"
                                label="user_detail"
                                track-by="id" 
                                placeholder=""
                                :options="originators.filter( x => JSON.parse(x.position_id).length != 0 )"
                                :multiple="true" 
                                :clear-on-select="false" 
                                :close-on-select="false"
                                :custom-label="customLabel">
                                <span slot="noResult">Oops! Data not found.</span>
                            </multiselect>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn" data-dismiss="modal" @click="modelRegistration.reviewer = backupData, backupData = null">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalApprover" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Pilih Approver</p>
                        <span class="pull-right pointer" data-dismiss="modal" @click="modelRegistration.approval = backupData, backupData = null"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="form-group">
                            <label for="">Approver</label>
                            <multiselect 
                                v-model="modelRegistration.approval"
                                label="user_detail"
                                track-by="id" 
                                placeholder=""
                                :options="originators.filter( x => JSON.parse(x.position_id).length != 0 )"
                                :multiple="true" 
                                :clear-on-select="false" 
                                :close-on-select="false"
                                :custom-label="customLabel">
                                <span slot="noResult">Oops! Data not found.</span>
                            </multiselect>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn" data-dismiss="modal" @click="modelRegistration.approval = backupData, backupData = null">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalDepartment" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Pilih Department</p>
                        <span class="pull-right pointer" data-dismiss="modal" @click="addDepartement = [], getDocumentSection()"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="form-group">
                            <div v-for="(section, index) in sections" :key="index">
                                <ValidationProvider :name="'Sub Departement'" :rules="{ required: index == 0 ? true : false }" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">{{ index == 0 ? 'Departement' : 'Sub Departement ' + (index)}}</label>
                                        <select class="form-control" v-model="addDepartement[index]" @change="onChangeSection($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices.filter( x => selectedParentDepartement.indexOf(x.id) == -1 )" :key="indexSection">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn" data-dismiss="modal" @click="addDepartement = [], getDocumentSection()">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal" @click="addToSelectedDepartement()">Add</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalPelaksana" tabindex="-1" role="dialog" v-if="modelRegistration">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Pilih Pelaksana</p>
                        <span class="pull-right pointer" data-dismiss="modal" @click="modelRegistration.pelaksana = backupData, backupData = null"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="form-group">
                            <label for="">Pelaksana</label>
                            <multiselect 
                                v-model="modelRegistration.pelaksana"
                                track-by="id" 
                                placeholder=""
                                :options="accessLevels"
                                :multiple="true" 
                                :clear-on-select="false" 
                                :close-on-select="false"
                                label="position">
                                <span slot="noResult">Oops! Data not found.</span>
                            </multiselect>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn" data-dismiss="modal" @click="modelRegistration.pelaksana = backupData, backupData = null">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            state: 'index',
            modelReserveNumber: {
                initial: null,
                title: null,
                document_number: null,
                document_number_id: null,
                accessLevels: null,
                types: [],
                sections: [],
                originators: []
            },
            modelRegistration: null,
            types: [],
            subTypes: [],
            sections: [],
            backupSections: [],
            accessLevels: [],
            originators: [],
            detailDocument: null,
            documents: [],
            selectedPossibleDocuments: [],
            selectedRelatedDocuments: [],
            relatedDocuments: [],
            searchPossibleDocument: '',
            searchSelectedDocument: '',
            revision_code: '',
            code: '',
            backupSections2: [],
            showAccessLevel: '',
            selectedAccessLevel: [],
            uploadProgressPDF: 0,
            uploadProgressOriginal: 0,
            backupData: null,
            isShowErrorPDFFile: false,
            isShowErrorOriFile: false,

            addDepartement: []
        }
    },
    computed: {
        possibleDocuments: function(){
            let _ = this
            return _.documents.filter( function (data) {
                return _.searchPossibleDocument ? 
                            data.document_number && 
                            data.title && 
                            data.id != _.modelRegistration.id && 
                            _.relatedDocuments.map( x => x.id ).indexOf(data.id) == -1  && 
                            ( 
                                data.document_number.toLowerCase().indexOf(_.searchPossibleDocument.toLowerCase()) > -1 || 
                                data.title.toLowerCase().indexOf(_.searchPossibleDocument.toLowerCase()) > -1 
                            )
                        :
                            data.document_number && 
                            data.title && 
                            data.id != _.modelRegistration.id && 
                            _.relatedDocuments.map( x => x.id ).indexOf(data.id) == -1
            })
        },
        selectedDocuments: function(){
            let _ = this
            return _.documents.filter( function (data) {
                return _.searchSelectedDocument ? 
                            data.document_number && 
                            data.title && 
                            data.id != _.modelRegistration.id && 
                            _.relatedDocuments.map( x => x.id ).indexOf(data.id) != -1  && 
                            ( 
                                data.document_number.toLowerCase().indexOf(_.searchSelectedDocument.toLowerCase()) > -1 || 
                                data.title.toLowerCase().indexOf(_.searchSelectedDocument.toLowerCase()) > -1 
                            )
                        :
                            data.document_number && 
                            data.title && 
                            data.id != _.modelRegistration.id && 
                            _.relatedDocuments.map( x => x.id ).indexOf(data.id) != -1
            })
        },
        relatedDepartments: function(){
            if (!this.modelRegistration) return;
            
            let departementIds = []

            this.modelRegistration.parent_departement.forEach(parent => {
                departementIds.push(parent[parent.length-1].id)
            });

            return departementIds.length == 0 ? null : departementIds
        },
        selectedParentDepartement: function() {
            let _ = this 
            
            let parentIds = []

            _.modelRegistration.parent_departement.forEach(elm => {
                parentIds.push(elm[elm.length-1].id)
            });

            return parentIds;
        }
    },
    methods: {
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        disabledBeforeEffectiveDate(date) {
            const today = new Date( this.$moment(this.modelRegistration.effective_date).add(1, 'days') );
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        disabledDateForApprovalDate(date) {
            const today = new Date( this.$moment() );
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
        disabledDateForReviewerDate(date) {
            var maxDate = new Date(Math.max.apply(null, this.modelRegistration.approval.map( x => this.$moment(x.date) )));

            const today = new Date( this.$moment(maxDate) );
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
        disabledDateForOriginatorDate(date) {
            var maxDate = new Date(Math.max.apply(null, this.modelRegistration.reviewer.map( x => this.$moment(x.date) )));
            const today = new Date( this.$moment(maxDate) );
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
        isJsonString(str) {
            try {
                JSON.parse(str)
            } catch (e) {
                return false
            }
            return true
        },
        customLabel( user ) {
            if ( this.isJsonString(user.position_id) ) {
                let position = JSON.parse(user.position_id)
                return user.user_detail + (position[0] ? ' - ' + position[0].position : '') + ' (' + user.employee_id + ')';
            } else {
                return user.user_detail
            }
        },
        customLabelSection( data ) {
            return data.code + ' - ' + data.title
        },
        back() {
            let _ = this
            _.$root.$emit('showForm', false)
        },
        getDocumentType() {
            let _ = this
            _.axios.get('/v2/repositories/master-file?showFile=false')
                .then(resp=>{
                    _.types = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocumentSection() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then(resp=>{
                    _.sections = []

                    const data = resp.data.data.filter( x => x.code != 'ZZ' )
                    _.sections.push({ choices: data })
                    _.backupSections = data
                    _.backupSections2 = JSON.parse(JSON.stringify(_.sections))
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocumentAccessLevel() {
            let _ = this
            _.axios.get('/v1/repositories/role-all')
                .then(resp=>{
                    _.accessLevels = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async getDocumentNumber() {
            let _ = this

            const isValid = await this.$refs.observerReserveNumber.validate()
            if (!isValid){
                let errors = []

                Object.keys(_.$refs.observerReserveNumber.errors).forEach(error => {
                    if (_.$refs.observerReserveNumber.errors[error].length > 0) {
                        errors.push(_.$refs.observerReserveNumber.errors[error][0])
                    }
                });
                _.$swal.fire({
                    title: 'Silakan periksa kembali, terdapat field yang belum terisi.',
                    text: errors.join(', '),
                    icon: 'error',
                    heightAuto: false
                })
                return;
            }

            let types = []
            _.modelReserveNumber.types.filter(x => x).forEach(elm => {
                types.push(JSON.parse(elm))
            })

            let sections = []
            _.modelReserveNumber.sections.filter(x => x).forEach(elm => {
                sections.push(JSON.parse(elm))
            })

            let str = "";
            types.forEach(elm => {
                str += elm.code + '-'
            })
            sections.forEach(elm => {
                str += elm.code + '-'
            })
            str = str.substring(0, str.length-1)

            _.axios.post('/v1/repositories/document-number', { code: decodeURI(str) })
                .then(resp=>{
                    let docNum = resp.data
                    let zeroFilled = docNum.code + '-' + docNum.number.toString().padStart(3, '0')
                    let doc = ""

                    _.code = docNum.code

                    doc += zeroFilled
                    str += zeroFilled
                    
                    _.modelReserveNumber.document_number = decodeURI(doc)
                    // _.modelReserveNumber.document_number_id = docNum.id
                    // _.modelReserveNumber.initial = docNum.initial
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getOriginators() {
            let _ = this
            _.axios.get('/v1/users')
                .then(resp=>{
                    _.originators = resp.data.data.filter( x => x.level == 'user' )
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async execute() {
            let _ = this

            const isValid = await this.$refs.observerExecute.validate()
            if (!isValid){
                return
            }

            let types = []
            _.modelReserveNumber.types.filter(x => x).forEach(elm => {
                types.push(JSON.parse(elm))
            })

            let sections = []
            _.modelReserveNumber.sections.filter(x => x).forEach(elm => {
                const data = JSON.parse(elm)
                delete data.children

                sections.push(data)
            })

            let data = {
                // id              : _.modelReserveNumber.document_number_id,
                document_number : _.modelReserveNumber.document_number,
                title           : _.modelReserveNumber.title,
                type            : JSON.stringify(types),
                originator      : JSON.stringify(_.modelReserveNumber.originators),
                section         : sections[sections.length-1].id,
                parent_section  : JSON.stringify(sections),
                status          : 0,
                initial         : _.modelReserveNumber.initial ? _.modelReserveNumber.initial : 1,
                initial_rev     : 0,
                access_level    : _.selectedAccessLevel.length > 1 ? JSON.stringify(_.selectedAccessLevel) : null,
                code            : _.code
            }

            _.axios.post('/v1/repositories/add', data)
                .then( (resp) => {
                    _.detailDocument = resp.data.data
                    _.$root.$emit('page', 1)
                    _.resetModelReserveNumber()
                    _.getDocuments()

                    _.selectedPossibleDocuments = []
                    _.selectedRelatedDocuments = []
                    _.searchPossibleDocument = ''
                    _.searchSelectedDocument = ''

                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus dokumen ini?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.put('/v1/repositories/delete/' + x.id, { is_delete: 'true' })
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        chooseType( type ) {
            this.modelReserveNumber.types[0] = JSON.stringify(type)
            if (type.children && type.children.length > 0) {
                this.subTypes.push( { children: type.children } )
            }
        },
        onChangeSubType( ev, index ) {
            let type = JSON.parse(ev.target.value)

            for (let i = index+1; i < this.subTypes.length; i++) {
                this.subTypes.splice(i, 1)
            }

            if (type.children && type.children.length > 0) {
                this.subTypes.push( { children: type.children } )
            }
        },
        onChangeSection( ev, index ) {
            let section = JSON.parse(ev.target.value)

            this.modelReserveNumber.sections.splice(index+1, this.sections.length)

            this.addDepartement.splice(index+1, this.sections.length)

            this.sections.splice(index+1, this.sections.length)

            if (section.children && section.children.length > 0) {
                this.sections.push( { choices: section.children } )
            }
        },
        resetModelReserveNumber() {
            let _ = this
            _.modelReserveNumber = {
                initial: null,
                title: null,
                document_number: null,
                document_number_id: null,
                accessLevels: null,
                types: [],
                sections: [],
                originators: []
            }
            _.subTypes = []
            _.sections = JSON.parse(JSON.stringify(_.backupSections2))
            _.state = 'index'
        },
        setModelRegistration( x ) {
            let _ = this

            let type = JSON.parse(x.type)

            _.modelRegistration = {
                ...x,
                access_level        : x.access_level,
                revision_code       : this.numToSSColumn(x.initial),
                effective_date      : _.$moment().format('YYYY-MM-DD'),
                next_review_date    : _.$moment().add(type[type.length-1].expire, (type[type.length-1].periode ? type[type.length-1].periode : 'week')).subtract(1, 'days').format('YYYY-MM-DD'),
                type                : x.type ? JSON.parse(x.type) : null,
                section             : x.section ? JSON.parse(x.section) : null,
                originator          : x.originator ? JSON.parse(x.originator) : null,
                reviewer            : x.reviewer ? JSON.parse(x.reviewer) : null,
                approval            : x.approval ? JSON.parse(x.approval) : null,
                pelaksana           : x.pelaksana ? JSON.parse(x.pelaksana) : null,
                departement         : x.departement ? JSON.parse(x.departement) : [],
                related_document    : x.related_document ? JSON.parse(x.related_document).map(x => x.id) : null,
                parent_departement  : x.parent_departement ? JSON.parse(x.parent_departement) : [],
                parent_section      : x.parent_section ? JSON.parse(x.parent_section) : null,
            }

            if (x.related_document) {
                _.modelRegistration.related_document.forEach(elm => {
                    _.relatedDocuments.push({ id: elm })
                });
            }

            _.revision_code = _.numToSSColumn(x.initial)

            let accessLevel = x.access_level ? JSON.parse(x.access_level) : null
            let selected = null
            if (accessLevel) {
                selected = accessLevel.map( x => x.selected ).indexOf( true )
            }
            _.showAccessLevel = accessLevel ? accessLevel[selected].position : ''

            _.modelRegistration.history_document = ''
            _.modelRegistration.note = ''
            _.modelRegistration.file_name = null
            _.modelRegistration.file_original_name = null

            if ( x.access_level ) {
                _.accessLevels = JSON.parse(x.access_level)
                _.accessLevels.sort((a, b) => a.position.localeCompare(b.position))
            }
        },
        deleteModelRegistrationOriginator( index ) {
            this.modelRegistration.originator.splice( index, 1)
        },
        deleteModelRegistrationReviewer( index ) {
            this.modelRegistration.reviewer.splice( index, 1)
        },
        deleteModelRegistrationApprover( index ) {
            this.modelRegistration.approval.splice( index, 1)
        },
        deleteModelRegistrationPosition( index ) {
            this.modelRegistration.pelaksana.splice( index, 1)
        },
        getDocuments() {
            let _ = this
            _.axios.get('/v2/document/doc_report?page=all')
                .then(resp=>{
                    const dateNow = _.$moment()
                    
                    _.documents = resp.data.data.map( x => {
                        return {
                            ...x,
                            initial: _.$moment(x.effective_date) > dateNow ? parseInt(x.initial)-1 : parseInt((x.initial)),
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        onClickPossibleDocument( id ) {
            let _ = this
            let searchDocument = _.selectedPossibleDocuments.map( x => x.id ).indexOf(id)
            if (searchDocument != -1) { // If Exists
                _.selectedPossibleDocuments.splice( searchDocument, 1 )
            } else {
                _.selectedPossibleDocuments.push({ id: id })
            }
        },
        onClickRelatedDocument( id ) {
            let _ = this
            let searchDocument = _.selectedRelatedDocuments.map( x => x.id ).indexOf(id)
            if (searchDocument != -1) { // If Exists
                _.selectedRelatedDocuments.splice( searchDocument, 1 )
            } else {
                _.selectedRelatedDocuments.push({ id: id })
            }
        },
        addToRelatedDocuments() {
            let _ = this

            _.selectedPossibleDocuments.forEach(elm => {
                _.relatedDocuments.push({ id: elm.id })
            });

            _.selectedPossibleDocuments = []
            _.selectedRelatedDocuments = []
        },
        addToPossibleDocuments() {
            let _ = this

            _.selectedRelatedDocuments.forEach(elm => {
                let searchDocument = _.relatedDocuments.map( x => x.id ).indexOf(elm.id)
                _.relatedDocuments.splice( searchDocument, 1)
            });

            _.selectedPossibleDocuments = []
            _.selectedRelatedDocuments = []
        },
        handleFilePDFUpload(event){
            this.file = event.target.files[0]

            let formData = new FormData()
            formData.append('files', this.file)
            formData.append('field', 'PDF')
            formData.append('document_number', this.modelRegistration.document_number)
            formData.append('initial', (parseInt(this.modelRegistration.initial) - 1))

            this.uploadProgressPDF = 0
            this.isShowErrorPDFFile = false

            this.axios.post('/v1/documents/upload', formData, 
                { 
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: function( progressEvent ) {
                        this.uploadProgressPDF = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) )
                        this.$forceUpdate()
                    }.bind(this)
                })
                .then(resp => {
                    this.modelRegistration.file_name = resp.data.filename
                }).catch(err => {
                    this.isShowErrorPDFFile = true
                    console.log(err.response)
                })
        },
        handleFileOriginalUpload(event){
            this.file = event.target.files[0]

            let formData = new FormData()
            formData.append('files', this.file)
            formData.append('field', 'ORI')
            formData.append('document_number', this.modelRegistration.document_number)
            formData.append('initial', (parseInt(this.modelRegistration.initial) - 1))

            this.uploadProgressOriginal = 0
            this.isShowErrorOriFile = false

            this.axios.post('/v1/documents/upload', formData, 
                { 
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: function( progressEvent ) {
                        this.uploadProgressOriginal = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) )
                        this.$forceUpdate()
                    }.bind(this)
                })
                .then(resp => {
                    this.modelRegistration.file_original_name = resp.data.filename
                }).catch(err => {
                    this.isShowErrorOriFile = true
                    console.log(err.response)
                })
        },
        async register() {
            let _ = this

            const isValid = await this.$refs.formNewRegistration.validate()
            if (!isValid){
                let errors = []

                Object.keys(_.$refs.formNewRegistration.errors).forEach(error => {
                    if (_.$refs.formNewRegistration.errors[error].length > 0) {
                        errors.push(_.$refs.formNewRegistration.errors[error][0])
                    }
                });
                _.$swal.fire({
                    title: 'Silakan periksa kembali, terdapat field yang belum terisi.',
                    text: errors.join(', '),
                    icon: 'error',
                    heightAuto: false
                })
                return;
            }

            let related_document = []
            if ( _.relatedDocuments.length > 0 ) {
                _.relatedDocuments.forEach(elm => {
                    let searchDocument = _.documents.map( x => x.id ).indexOf(elm.id)
                    if (searchDocument != -1) {
                        related_document.push({
                            id: _.documents[searchDocument].id,
                            document_number: _.documents[searchDocument].document_number,
                            initial: _.documents[searchDocument].initial,
                            title: _.documents[searchDocument].title
                        })
                    }
                })
            }

            let data = {
                id: _.modelRegistration.id,
                formerly: _.modelRegistration.formerly,
                effective_date: _.modelRegistration.effective_date,
                next_review_date: _.modelRegistration.next_review_date,
                history_document: _.modelRegistration.history_document,
                type: _.modelRegistration.type ? JSON.stringify(_.modelRegistration.type) : null,
                section: _.modelRegistration.parent_section ? JSON.stringify(_.modelRegistration.parent_section) : null,
                originator: _.modelRegistration.originator ? JSON.stringify(_.modelRegistration.originator) : null,
                reviewer: _.modelRegistration.reviewer ? JSON.stringify(_.modelRegistration.reviewer) : null,
                approval: _.modelRegistration.approval ? JSON.stringify(_.modelRegistration.approval) : null,
                pelaksana: _.modelRegistration.pelaksana ? JSON.stringify(_.modelRegistration.pelaksana) : null,
                departement: _.relatedDepartments ? JSON.stringify(_.relatedDepartments) : null,
                related_document: related_document.length > 0 ? JSON.stringify(related_document) : null,
                file_name: _.modelRegistration.file_name,
                file_original_name: _.modelRegistration.file_original_name,
                note: _.modelRegistration.note,
                doc_related_ids: related_document.length > 0 ? related_document.map( x => x.id) : [],
                parent_departement: _.modelRegistration.parent_departement.length > 0 ? JSON.stringify(_.modelRegistration.parent_departement) : null,
            }

            _.axios.put('/v1/repositories/document-update/' + data.id, data)
                .then(() => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: 'Successfully save data'
                    })
                    _.state = 'index'
                    _.$root.$emit('page', 1)
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        handleAccessLevel()
        {
            let _ = this
            
            let isStop = false
            let i = 0

            _.selectedAccessLevel = []
            _.selectedAccessLevel.push( JSON.parse(_.modelReserveNumber.accessLevel) )
            
            while (isStop == false) {
                let searchRole = _.accessLevels.map(x => x.id).indexOf(_.selectedAccessLevel[i].id)
                if ( searchRole != -1 ) {
                    if ( _.accessLevels[searchRole].main_id ) {
                        let searchUpperRole = _.accessLevels.map(x => x.id).indexOf(parseInt(_.accessLevels[searchRole].main_id))
                        if ( searchUpperRole != -1 ) {
                            _.selectedAccessLevel.push(_.accessLevels[searchUpperRole])
                            i += 1
                        } else {
                            isStop = true
                        }
                    } else {
                        isStop = true
                    }
                } else {
                    isStop = true
                }
            }

            let kedalaman = _.selectedAccessLevel.length
            _.selectedAccessLevel = []
            let main_ids = [ null ]
            for (let i = 0; i < kedalaman; i++) {
                let hehe = []
                main_ids.forEach(main_id => {
                    let parents = _.accessLevels.filter( x => x.main_id == main_id )
                    parents.forEach(parent => {
                        _.selectedAccessLevel.push({ ...parent, selected: false })
                        hehe.push(parent.id)
                    });
                });
                main_ids = hehe
            }

            let selected = JSON.parse(_.modelReserveNumber.accessLevel)
            let searchSelected = _.selectedAccessLevel.map( x => x.id ).indexOf( selected.id )
            if ( searchSelected != -1 ) {
                _.selectedAccessLevel[searchSelected].selected = true
            }
        },
        addToSelectedDepartement() {
            let _ = this

            let departements = []
            _.addDepartement.forEach(departement => {
                let data = JSON.parse(departement);
                delete data.children;
                departements.push(data);
            });
            _.modelRegistration.parent_departement.push(departements)
            _.getDocumentSection()

            _.addDepartement = []

            if (_.modelRegistration.parent_departement.length > 1) {
                let parentIds = []

                _.modelRegistration.parent_departement.forEach(elm => {
                    parentIds.push(elm[elm.length-1].id)
                });

                const lastSelectedParentDepartment = parentIds[parentIds.length - 1]

                let deletedParentDepartment = []

                for (let i = 0; i < _.modelRegistration.parent_departement.length-1; i++) {
                    const elm = _.modelRegistration.parent_departement[i];
                    for (let z = 0; z < elm.length; z++) {
                        if (elm[z].id == lastSelectedParentDepartment) {
                            deletedParentDepartment.push(i)
                        }
                    }
                }

                for (let i = deletedParentDepartment.length-1; i >= 0; --i) {
                    _.modelRegistration.parent_departement.splice(deletedParentDepartment[i], 1)
                }
            }
        },
        deleteChildrenDepartement(parentIndex, childrenIndex) {
            const totalChildren = this.modelRegistration.parent_departement[parentIndex].length

            if (childrenIndex == 0) {
                this.modelRegistration.parent_departement.splice(parentIndex, 1)
                return;
            } else if ((childrenIndex + 1) < totalChildren) {
                for (let i = totalChildren; i >= childrenIndex; i--) {
                    this.modelRegistration.parent_departement[parentIndex].splice(i,1)
                }
                return;
            }

            this.modelRegistration.parent_departement[parentIndex].splice(childrenIndex,1)
        },
        deleteParentDepartement(parentIndex) {
            this.modelRegistration.parent_departement.splice(parentIndex,1)
        }
    },
    watch: {
        state: function() {
            let _ = this
            if (_.state == 'formNewRegistration') {
                _.$root.$emit('showForm', true)
            } else {
                _.$root.$emit('showForm', false)
            }
        },
        'modelReserveNumber.accessLevel': function() {
            this.handleAccessLevel()
        }
    },
    mounted() {
        let _ = this

        _.getDocumentAccessLevel()
        _.getDocumentType()
        _.getDocumentSection()
        _.getOriginators()
        _.getDocuments()

        _.$root.$on("showFormRegistration", (data) => {
            _.selectedPossibleDocuments = []
            _.selectedRelatedDocuments = []
            _.searchPossibleDocument = ''
            _.searchSelectedDocument = ''
            _.relatedDocuments = []

            _.state = 'formNewRegistration'
            _.setModelRegistration(data)
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("showFormRegistration")
    },
}
</script>