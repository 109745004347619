<template>
    <div class="card card-custom">
        <div class="pl-1 pt-1">
            <p class="f-12 font-600 mb-0">Filter By:
                <span class="pull-right text-primary mr-1 pointer" data-target="#modalFilterDocument" data-toggle="modal">
                    Show Filter
                </span>
            </p>
        </div>
        <hr>
        <div class="card-body pl-1 pr-1 pt-0 pb-0 mb-1">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <p class="f-10 mb-0 align-self-center mr-1">Entries</p>
                            <select class="form-control col-2 f-10 mr-1" v-model="perPage">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option :value="table.lists.length">Show All</option>
                            </select>
                        </div>
                    </div>
                    <div class="col text-right">
                        <div class="row justify-content-end">
                            <input type="text" class="form-control col-6 mr-1" placeholder="Search..." v-model="search">
                            <button class="btn btn-outline-primary btn-sm" @click="download('export_expired')">Export Excel</button>
                            <!-- <button v-if="exportExcelUser" class="btn btn-outline-primary btn-sm" @click="downloadUser(exportExcelUser)">Export Excel</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="(data, key) in table.headers" :key="key" class="text-left">{{ data }}</th>
                    </tr>
                </thead>
                <tbody v-if="dataToDisplay.length > 0">
                    <tr v-for="(data, key) in dataToDisplay" :key="key">
                        <td v-for="(column, kcolumn) in table.columns" :key="kcolumn" style="overflow-wrap: anywhere;">
                            <span v-if="(column == 'counter')">
                                {{ (dataTable.lists.meta.current_page - 1) * dataTable.lists.meta.per_page + key + 1 }}
                            </span>
                            <span v-else-if="!data[column] && column != 'overdue'">-</span>
                            <span v-else-if="column == 'created_at' || column == 'updated_at' || column == 'next_review_date' || column == 'effective_date' || column == 'obsolete_date' || column == 'terminated_date'
                                || column == 'first_access_date' || column == 'last_access_date'">
                                {{ data[column] | moment("YYYY/MM/DD") }}
                            </span>
                            <span v-else-if="column == 'understand_date'">
                                <span v-if="data.has_understand">{{ data[column] | moment("YYYY/MM/DD") }}</span>
                                <span v-else>-</span>
                            </span>
                            <span v-else-if="column == 'meta.reserve_date'">
                                {{ data.meta.reserve_date | moment("YYYY/MM/DD") }}
                            </span>
                            <span v-else-if="column == 'photo'">
                                <img v-if="data[column] != null" :src="data[column]" class="avatar-small border-0 pointer" @click="lihat(data[column])"> 
                            </span>
                            <span v-else-if="column == 'description'">
                                {{ html_striptag(data[column]).length > 100 ? html_striptag(data[column]).substring(0, 100) + '...' : html_striptag(data[column])}}
                            </span>
                            <span v-else-if="column == 'price'">
                                {{ data[column] | currency }}
                            </span>
                            <span v-else-if="column == 'total'">
                                {{ data[column] | numFormat }}
                            </span>
                            <span v-else-if="column == 'document_number' && $route.name == 'Dashboard'" class="text-primary font-600 pointer" @click="previewDocument(data)">
                                <u>{{ data.document_number }}-{{ numToSSColumn(data.initial) }}</u>
                            </span>
                            <span v-else-if="column == 'document_number'" class="text-primary font-600">
                                <u>{{ data.document_number }}-{{ numToSSColumn(data.initial) }}</u>
                            </span>
                            <span v-else-if="column == 'type'">
                                <div v-if="isJsonString(data.type)">
                                    <span class="mr-1" v-for="type in JSON.parse(data.type)" :key="type.id">
                                        {{ type.code }}
                                    </span>
                                </div>
                            </span>
                            <span v-else-if="column == 'section'">
                                <div v-if="isJsonString(data.section)">
                                    <span class="mr-1" v-for="section in JSON.parse(data.section)" :key="section.id">
                                        {{ section.code }}
                                    </span>
                                </div>
                            </span>
                            <span v-else-if="column == 'originator'">
                                <ul class="mb-0 pl-0" style="list-style: none;" v-if="isJsonString(data.originator)">
                                    <li v-for="originator in JSON.parse(data.originator)" :key="originator.id">
                                        {{originator.user_detail}}
                                    </li>
                                </ul>
                            </span>
                            <span v-else-if="column == 'position'">
                                <div v-if="isJsonString(data.originator)">
                                    <div v-for="originator in JSON.parse(data.originator)" :key="originator.id">
                                        <ul class="mb-0 pl-0" style="list-style: none;" v-if="isJsonString(originator.position_id)">
                                            <li v-for="position in JSON.parse(originator.position_id)" :key="position.id">
                                                {{ position.position }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                            <span v-else-if="column == 'position_id'">
                                <ul class="mb-0 pl-0" style="list-style: none;" v-if="isJsonString(data.position_id)">
                                    <li v-for="position in JSON.parse(data.position_id)" :key="position.id">
                                        {{ position.position }}
                                    </li>
                                </ul>
                            </span>
                            <span v-else-if="column == 'overdue'">
                                <span v-if="data.effective_date">
                                    {{ getDiff(data.effective_date) }} Days
                                </span>
                                <span v-else>-</span>
                            </span>
                            <span v-else-if="column == 'user_id'">
                                <ul class="m-0 pl-1">
                                    <li v-for="(email, index) in JSON.parse(data[column])" :key="index">{{email}}</li>
                                </ul>
                            </span>
                            <span v-else-if="column == 'terminated_status'">
                                <span class="text-danger">-</span>
                            </span>
                            <span v-else-if="column == 'meta.document_number'" class="text-primary font-600">
                                <u>{{ data.meta.document_number }}-{{ data.initial ? numToSSColumn(data.initial) : numToSSColumn(data.meta.initial) }}</u>
                            </span>
                            <span v-else-if="column == 'meta.title'" class="text-primary font-600">
                                {{ data.meta.title }}
                            </span>
                            <span v-else-if="column == 'meta.type'">
                                <div v-if="isJsonString(data.meta.type)">
                                    <span class="mr-1" v-for="type in JSON.parse(data.meta.type)" :key="type.id">
                                        {{ type.code }}
                                    </span>
                                </div>
                            </span>
                            <span v-else-if="column == 'meta.section'">
                                <div v-if="isJsonString(data.meta.section)">
                                    <span class="mr-1" v-for="section in JSON.parse(data.meta.section)" :key="section.id">
                                        {{ section.code }}
                                    </span>
                                </div>
                            </span>
                            <span v-else-if="column == 'meta.originator'">
                                <ul class="mb-0 pl-0" style="list-style: none;" v-if="isJsonString(data.meta.originator)">
                                    <li v-for="originator in JSON.parse(data.meta.originator)" :key="originator.id">
                                        {{originator.user_detail}}
                                    </li>
                                </ul>
                            </span>
                            <span v-else-if="column == 'meta.position'">
                                <div v-if="isJsonString(data.meta.originator)">
                                    <div v-for="originator in JSON.parse(data.meta.originator)" :key="originator.id">
                                        <ul class="mb-0 pl-0" style="list-style: none;" v-if="isJsonString(originator.position_id)">
                                            <li v-for="position in JSON.parse(originator.position_id)" :key="position.id">
                                                {{ position.position }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                            <span v-else v-html="data[column] == null ? '-' : data[column]"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col mb-1" v-if="dataToDisplay.length > 0">
                <div class="row pl-2">
                    <p class="f-10 mb-0 align-self-center" v-if="currentPage != totalPages">
                        Showing {{ (currentPage - 1) * perPage + 1 }} to {{ (currentPage * perPage) }} from {{ dataTable.length ? dataTable.length : 0 }} entries
                    </p>
                    <p class="f-10 mb-0 align-self-center" v-else>
                        Showing {{ (currentPage - 1) * perPage + 1 }} to {{ (currentPage * perPage) - ( perPage*currentPage - dataTable.length) }} from {{ dataTable.length ? dataTable.length : 0 }} entries
                    </p>
                    <div class="col text-right">
                        <ul class="pagination mb-0 pull-right">
                            <li class="paginate_button page-item previous" :class="{ disabled: currentPage <= 1 }">
                                <button type="button" class="page-link" @click.prevent="renderList(currentPage - 1)"></button>
                            </li>

                            <li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
                                <button type="button" class="page-link" @click.prevent="renderList(page)">{{ page }}</button>
                            </li>

                            <li class="paginate_button page-item next" :class="{ disabled: currentPage >= totalPages }">
                                <button type="button" class="page-link" @click.prevent="renderList(currentPage + 1)"></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="f-12 text-center" v-if="dataToDisplay.length == 0">There is no data</p>
        </div>
        <div class="modal fade text-left show" id="modalFilterDocument" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Filter</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">Effective Date</label>
                                    <div class="row">
                                        <div class="col-6">
                                            <date-picker
                                                v-model="model.filter.effective_date_start"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="From"
                                                input-class="form-control"
                                                class="w-100">
                                            </date-picker>
                                        </div>
                                        <div class="col-6">
                                            <date-picker
                                                v-model="model.filter.effective_date_end"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="To"
                                                input-class="form-control"
                                                class="w-100">
                                            </date-picker>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-6" style="border-right: 1px solid #f5f5f5">
                                <div v-for="(type, index) in types" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Type' : 'Sub Type ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.types[index]" @change="onChangeType($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexType) in type.choices" :key="indexType">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(section, index) in sections" :key="index">
                                    <div class="form-group">
                                        <label for="">{{ index == 0 ? 'Section' : 'Sub Section ' + (index)}}</label>
                                        <select class="form-control" v-model="dataFilter.sections[index]" @change="onChangeSection($event, index)">
                                            <option :value="JSON.stringify(choice)" v-for="(choice, indexSection) in section.choices" :key="indexSection">
                                                {{ choice.code }} - {{ choice.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-1">
                            <button class="btn btn-outline-primary f-12" @click="resetFilter()">Reset</button> &nbsp;
                            <button class="btn btn-primary f-12" data-dismiss="modal" @click="doFilter()">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            table: {
                headers: ['Document Number', 'Title', 'Originator', 'Effective Date', 'Next Review Date', 'Baca', 'Mengerti', 'Akses', 'Overdue'],
                columns: ['document_number', 'title', 'originator', 'effective_date', 'next_review_date', 'first_access_date', 'understand_date', 'last_access_date', 'overdue'],
                lists: [],
            },
            perPage: 10,
            pageToOpen: 1,
            currentPage: 1,
            dataToDisplay: [],
            search: '',
            model: {
                filter: {
                    effective_date_start: '',
                    effective_date_end: '',
                    type: '',
                    group: '',
                    position: '',
                    section: ''
                }
            },
            types: [],
            sections: [],
            dataFilter: {
                sections: [],
                types: []
            },
            backup: {
                sections: [],
                types: []
            }
        }
    },
    computed: {
        dataTable() {
            let _ = this
            let allData = _.table.lists

            if ( _.search ) {
                allData = allData.filter(function(data) {
                    return data.document_number.toLowerCase().indexOf(_.search.toLowerCase()) > -1
                        || data.title.toLowerCase().indexOf(_.search.toLowerCase()) > -1
                })
            }

            if ( !isNaN(Date.parse(_.model.filter.effective_date_start)) && !isNaN(Date.parse(_.model.filter.effective_date_end)) ) {
                allData = allData.filter(function(data) {
                    return data.effective_date == _.model.filter.effective_date_start && 
                        data.next_review_date.split('T')[0] == _.model.filter.effective_date_end
                })
            } else if ( !isNaN(Date.parse(_.model.filter.effective_date_start)) ) {
                allData = allData.filter(function(data) {
                    return data.effective_date == _.model.filter.effective_date_start
                })
            } else if ( !isNaN(Date.parse(_.model.filter.effective_date_end)) ) {
                allData = allData.filter(function(data) {
                    return data.next_review_date.split('T')[0] == _.model.filter.effective_date_end
                })
            }

            if ( _.model.filter.section ) {
                allData = allData.filter(function(data) {
                    return JSON.parse(data.section).map( x => x.code_unique ).includes(_.model.filter.section)
                })
            }

            if ( _.model.filter.type ) {
                allData = allData.filter(function(data) {
                    return JSON.parse(data.type).map( x => x.code ).includes(_.model.filter.type)
                })
            }

            return allData
        },
        totalPages(){
            return this.dataTable.length && (this.dataTable.length > this.perPage) ? Math.ceil(this.dataTable.length/this.perPage) : 1;
        },
        start(){
            return (this.pageToOpen - 1) * this.perPage;
        },
        stop(){
            if((this.dataTable.length - this.start) >= this.perPage){
                return (this.pageToOpen * this.perPage) - 1;
            } else{
                return this.dataTable.length - 1;
            }
        },
        pages() {
          let current         = this.currentPage
          let last            = this.totalPages
          let delta           = 2
          let left            = current - delta
          let right           = current + delta + 1
          let range           = []
          let rangeWithDots   = []  
          let l               = 0              

          for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
              range.push(i);
            }
          }

          for (let i of range) {
            if (l) {
              if (i - l === 2) {
                rangeWithDots.push(l + 1);
              } else if (i - l !== 1) {
                rangeWithDots.push('...');
              }
            }
            rangeWithDots.push(i);
            l = i;
          }

          return rangeWithDots;
        }
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/v2/dashboard/already_expired')
                .then(resp=>{
                    let response = resp.data.data
                    response.sort(function (a, b) {
                        if (a.effective_date > b.effective_date) return 1;
                        if (a.effective_date < b.effective_date) return -1;
                        if (a.document_number > b.document_number) return 1;
                        if (a.document_number < b.document_number) return -1;
                    });
                    
                    _.table.lists = response
                    this.renderList()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        renderList(pageNumber=1){
            this.dataToDisplay = [];
            
            if(this.dataTable.length){
                let _ = this;
                return new Promise(function(res){
                    _.pageToOpen = pageNumber;
                    for(let i = _.start; i <= _.stop; i++){
                        _.dataToDisplay.push(_.dataTable[i]);
                    }
                    res();
                }).then(function(){
                    _.currentPage = _.pageToOpen;
                }).catch(function(){
                    console.log('render err');
                });                  
            }
        },
        getDiff(x) {
            let _ = this
            let expiredAt = _.$moment(x).format('YYYY-MM-DD') + ' 00:00:00'
            let now = _.$moment().format('YYYY-MM-DD') + ' 00:00:00'
            let diff = (_.$moment(expiredAt).diff(_.$moment(now), 'days')) * -1

            return diff > 0 ? '+' + diff : diff
        },
        isCurrentPage(page){
          return this.currentPage === page
        },
        getDocumentType() {
            let _ = this
            _.axios.get('/v2/repositories/master-file')
                .then(resp=>{
                    _.types.push({ choices: resp.data.data })
                    _.backup.types.push({ choices: resp.data.data })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocumentSection() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=false')
                .then(resp=>{
                    _.sections.push({ choices: resp.data.data })
                    _.backup.sections.push({ choices: resp.data.data })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        download(params) {
            let _ = this
            _.axios.get('/v2/document/' + params
                + '?search=' + _.search
                + '&effective_date_start=' + _.model.filter.effective_date_start
                + '&effective_date_end=' + _.model.filter.effective_date_end
                + '&group=' + _.model.filter.section
                + '&type=' + _.model.filter.type
                + '&modul=dashboard'
                ,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = params + '-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        onChangeType( ev, index ) {
            let type = JSON.parse(ev.target.value)

            for (let i = index+1; i < this.types.length; i++) {
                this.types.splice(i, 1)
            }

            if (type.children.length > 0) {
                this.types.push( { choices: type.children } )
            }
        },
        onChangeSection( ev, index ) {
            let section = JSON.parse(ev.target.value)

            this.dataFilter.sections.splice(index+1, this.sections.length)

            this.sections.splice(index+1, this.sections.length)

            if (section.children && section.children.length > 0) {
                this.sections.push( { choices: section.children } )
            }
        },
        resetFilter() {
            this.model.filter = {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                section: '',
            },
            this.dataFilter = {
                types: [],
                sections: [],
            }
            this.types = JSON.parse(JSON.stringify(this.backup.types))
            this.sections = JSON.parse(JSON.stringify(this.backup.sections))
            this.renderList()
        },
        doFilter() {
            let _ = this

            _.model.filter.type = ''
            _.dataFilter.types.forEach(elm => {
                let type = JSON.parse(elm)
                _.model.filter.type  = type.code
            })
            _.model.filter.type = _.model.filter.type.trim()

            _.model.filter.section = ''
            _.dataFilter.sections.forEach(elm => {
                let section = JSON.parse(elm)
                _.model.filter.section  = section.code_unique
            })
            _.renderList()
        },
        isJsonString(str) {
            try {
                JSON.parse(str)
            } catch (e) {
                return false
            }
            return true
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded p-1'
                }
            })
        },
        html_striptag(str) {
            return str.replace(/(<([^>]+)>)/gi, "")
        },
        previewDocument( data ) {
            let _ = this
            _.$root.$emit('searchFile', data)
        },
    },
    watch: {
        perPage: function(){
            this.renderList();
        },
        search: function(){
            this.renderList();
        },
    },
    mounted() {
        let _ = this

        _.index()
        _.getDocumentType()
        _.getDocumentSection()
    },
}
</script>