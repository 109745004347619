<template>
    <div>
        <p class="font-700 f-10" style="margin-bottom: 5px;" v-if="!isPreview">
            <span class="pointer" 
                v-if="state.slug != 'dashboard'" 
                @click="reset()">
                <i class="fa fa-arrow-left mr-1"></i>
                Back
            </span> 
            {{ state.title }}
        </p>
        <p class="font-700 f-10 pointer" style="margin-bottom: 10px;" data-target="#modalUnderstanding" data-toggle="modal" data-backdrop="static" data-keyboard="false" @click="isClosed=true" 
            v-else-if="!activeFile.has_understand && activeFile.is_must_read && loggedUser.level != 'user-dokumen'">
            <i class="fa fa-arrow-left mr-1"></i> Back
        </p>
        <p class="font-700 f-10 pointer" style="margin-bottom: 10px;"
            @click="isPreview = false, activeFile = null" v-else>
            <i class="fa fa-arrow-left mr-1"></i> Back
        </p>
        <div class="card card-custom pl-1 pb-0 pr-1 pt-1" id="embed">
            <div v-if="isPreview" class="text-center" style="height: 86vh">
                <embed
                    type="application/pdf"
                    :src="dataPDF.url"
                    width="100%"
                    height="100%"/>
            </div>
            <div v-else-if="state.slug == 'dashboard'">
                <div class="card card-custom mb-1" v-for="(data, index) in dashboards" :key="index">
                    <p class="font-600 mb-0 f-10" style="padding: 5px 10px;" :style="{ background: data.header, color: data.color }">{{ data.label }} 
                        <span class="pull-right pointer" :style="{ color: data.color }" @click="state = data, limit = 10, search = '', seeAll( data.modul )">See All {{ data.total > 99 ? '99+' : data.total }}</span>
                    </p>
                    <div class="table-responsive">
                        <table class="table" id="dashboard" :style="{ background: data.background }">
                            <thead>
                                <tr>
                                    <th width="150px">Document Number</th>
                                    <th width="150px">Title</th>
                                    <th width="150px">Originator</th>
                                    <th width="100px">Effective Date</th>
                                    <th width="100px">Next Review Date</th>
                                    <th width="100px">Baca</th>
                                    <th width="100px">Mengerti</th>
                                    <th width="100px">Akses</th>
                                    <th width="100px" class="text-left">Overdue</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="document in data.data.slice(0,5)" :key="document.id">
                                    <td class="text-primary font-600 pointer" @click="searchFile(document)">
                                        <u>{{ generrateDocumentNumber(document).length > 100 ? generrateDocumentNumber(document).substring(0, 100) + '...' : generrateDocumentNumber(document)}}
                                        </u>
                                    </td>
                                    <td>{{ document.title.length > 100 ? document.title.substring(0, 100) + '...' : document.title}}</td>
                                    <td>
                                        <ul class="mb-0 pl-0" style="list-style: none;">
                                            <li v-for="originator in JSON.parse(document.originator)" :key="originator.id">
                                                {{originator.user_detail}}
                                            </li>
                                        </ul>
                                    </td>
                                    <td v-if="document.effective_date">{{ document.effective_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="document.next_review_date">{{ document.next_review_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="document.first_access_date">{{ document.first_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="document.has_understand">{{ document.understand_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="document.last_access_date">{{ document.last_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="document.effective_date">{{ getDiff(document.effective_date) }} Days</td>
                                    <td v-else>-</td>
                                </tr>
                                <tr v-if="data.data.length == 0">
                                    <td colspan="9" class="text-center">There is no data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="font-600 mb-0 f-10" style="padding: 5px 10px;" :style="{ background: state.header, color: state.color }">{{ state.label }}</p>
                <AlreadyExpired v-if="state.modul == 'already_expired'"/>
                <Table
                    v-else
                    :withCard="true"
                    :dataTable="table"
                    :filter="true"
                    :limit="true"
                    :search="true"
                    :exportExcel="downloadParams"
                    :pagination="true">
                </Table>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalUnderstanding" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Notification</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <div class="text-center">
                            <p class="f-16">Sudahkah Anda memahami isi dokumen ini?</p>
                            <button class="btn btn-primary font-400 f-10" @click="understanding(1)" data-dismiss="modal">No</button>&nbsp;
                            <button class="btn text-primary font-400 f-10" @click="understanding(2)" data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    #dashboard table {
        /* border: 1px solid black; */
        table-layout: fixed;
        /* width: 200px; */
    }

    #dashboard th,
    #dashboard td {
        /* border: 1px solid black; */
        /* width: 100px; */
        overflow: hidden;
    }
</style>
<script>
import Table from '@/components/Table'
import AlreadyExpired from './AlreadyExpired.vue'

export default {
    components: {
        Table,
        AlreadyExpired
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
                modul: _.modul,
                search: _.search,
                effective_date_start: _.filter.effective_date_start,
                effective_date_end: _.filter.effective_date_end,
                type: _.filter.type,
                group: _.filter.group
            }
            return data
        }
    },
    data() {
        return {
            state: {
                title: 'Dashboard',
                color: '#FFFFFF',
                slug: 'dashboard'
            },
            isPreview: false,
            dataPDF: {
                url: null
            },
            dashboards: [],
            activeFile: null,
            table: {
                headers: ['Document Number', 'Title', '', '', 'Originator', 'Position', 'Reserve Date', 'Action'],
                columns: ['document_number', 'title', 'type', 'section', 'originator', 'position', 'created_at'],
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                group: ''
            },
            modul: '',
            isClosed: false,
            files: [],
            redirectTo: null,
            loggedUser: JSON.parse(localStorage.getItem('user')),
        }
    },
    methods: {
        generrateDocumentNumber(document){
            return document.document_number + "-" + this.numToSSColumn(document.initial)
        },
        getDiff(x)
        {
            let _ = this
            let expiredAt = _.$moment(x).format('YYYY-MM-DD') + ' 00:00:00'
            let now = _.$moment().format('YYYY-MM-DD') + ' 00:00:00'
            let diff = (_.$moment(expiredAt).diff(_.$moment(now), 'days')) * -1

            return diff > 0 ? '+' + diff : diff
        },
        reset() {
            let _ = this
            _.state = {
                title: 'Dashboard',
                color: '#FFFFFF',
                slug: 'dashboard'
            }
            _.index()
        },
        isAllowedExtension( filename, allowedExtension ) {
            var fileExtension = filename.split('.').pop().toLowerCase();
            var isValidFile = false;

            for(var index in allowedExtension) {
                if(fileExtension === allowedExtension[index]) {
                    isValidFile = true; 
                break;
                }
            }

            return isValidFile;
        },
        preview( url ) {
            let _ = this
            
            if ( _.isAllowedExtension( url, ['pdf'] )) {
                _.dataPDF.url = url
                _.isPreview = true
                _.$forceUpdate()
            } else {
                _.isPreview = false
                _.$swal.fire({
                    title: 'Silakan klik tombol Download untuk mengunduh dokumen ini',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Download'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.open(url, '_blank');

                        if (!_.activeFile.has_understand && _.activeFile.is_must_read && _.loggedUser.level != 'user-dokumen') {
                            window.$('#modalUnderstanding').modal({backdrop: 'static', keyboard: false});
                        }
                    }
                })
            }
        },
        searchFile( data ) {
            let _ = this
            let search = _.files.map( x => x.document_number ).indexOf( (data.code_unique ? data.code_unique : data.document_number ) )
            if (search != -1) {
                let file = _.files[search]

                if (file.related_document) {
                    let related = JSON.parse(file.related_document)

                    for (let i = 0; i < related.length; i++) {
                        const elm = related[i];
                        let hehe = _.files.map( x => x.id ).indexOf( elm.id )
                        if (hehe != -1) {
                            related[i] = _.files[hehe]
                            _.$forceUpdate()
                        }
                    }

                    file.related_document = JSON.stringify(related)
                }
                
                _.preview( file.file_url )

                _.activeFile = file
                _.redirectTo = null
            } else {
                _.$swal.fire({
                    title: 'Anda tidak memiliki akses ke dokumen ini',
                    icon: 'warning',
                    showCancelButton: false,
                })
                _.isPreview = false
                _.activeFile = null
            }
        },
        getFileTree() {
            let _ = this
            _.axios.get('/v1/repositories/document?modul=dashboard')
                .then( resp => {
                    _.files = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async index() {
            let _ = this
            
            let dashboardData = null
            await _.axios.get('/v1/repositories/dashboard')
                .then(resp=>{
                    dashboardData = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })

            _.dashboards = []
            for (let i = 0; i < dashboardData.length; i++) {
                const elm = dashboardData[i];
                await _.axios.get('/v2/dashboard/' + elm.modul)
                    .then(res=>{
                        if ( res.data.data.data ) {
                            let response = res.data.data.data.map(function( x ) {
                                return {
                                    ...x,
                                    initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                                    effective_date: x.is_edit_disable == true ? x.effective_date_history : x.effective_date
                                }
                            })

                            _.dashboards.push({ ...elm, data: response, total: res.data.data.meta.total})
                        } else {
                            let response = res.data.data
                            response.sort(function (a, b) {
                                if (a.effective_date > b.effective_date) return 1;
                                if (a.effective_date < b.effective_date) return -1;
                                if (a.document_number > b.document_number) return 1;
                                if (a.document_number < b.document_number) return -1;
                            });

                            response = response.map(function( x ) {
                                return {
                                    ...x,
                                    initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                                    effective_date: x.is_edit_disable == true ? x.effective_date_history : x.effective_date
                                }
                            })

                            _.dashboards.push({ ...elm, data: response, total: response.length})
                        }
                    })
            }
        },
        access() {
            let _ = this
            let user = JSON.parse(localStorage.getItem('user'))

            let request = {
                edm_document_id: _.activeFile.id,
                employee_id: user.employee_id
            }
            _.axios.post('/v2/document/access', request)
                .then(() => {
                })
        },
        understanding( status ) {
            let _ = this
            _.axios.put('/v1/repositories/update-user-doc/' + _.activeFile.id, { status: status })
                .then(()=>{
                    if (_.isClosed == true) {
                        _.isPreview = false
                        _.activeFile = null
                        // _.index()
                    }

                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                    _.getFileTree()
                    _.$root.$emit("updateFile", true)
                    if ( _.state.id ) {
                        _.seeAll(_.state.modul)
                    }

                    if (_.redirectTo) {
                        _.searchFile( _.redirectTo )
                    }

                    _.index()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        seeAll( modul ) {
            let _ = this

            if (modul == 'already_expired') return;

            _.table = {
                headers: ['Document Number', 'Title', 'Originator', 'Effective Date', 'Next Review Date', 'Baca', 'Mengerti', 'Akses', 'Overdue'],
                columns: ['document_number', 'title', 'originator', 'effective_date', 'next_review_date', 'first_access_date', 'understand_date', 'last_access_date', 'overdue'],
                lists: []
			}

            _.modul = modul

            _.axios.get('/v2/dashboard/' + modul +
                    '?search=' + _.search + 
                    '&page=' + _.page + 
                    '&limit=' + _.limit +
                    '&effective_date_start=' + _.filter.effective_date_start +
                    '&effective_date_end=' + _.filter.effective_date_end +
                    '&type=' + _.filter.type +
                    '&group=' + _.filter.group
                )
                .then(resp=>{
                    _.table.lists = resp.data.data
                    _.table.lists.data = _.table.lists.data.map(function( x ) {
                        return {
                            ...x,
                            initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                            effective_date: x.is_edit_disable == true ? x.effective_date_history : x.effective_date
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        }
    },
    mounted() {
        let _ = this
        _.index()
        _.getFileTree()

        _.$root.$on("previewFile", (data) => {
            if (_.activeFile && (data.id != _.activeFile.id)) {
                _.isClosed = false

                if (!_.activeFile.has_understand && _.activeFile.is_must_read && _.loggedUser.level != 'user-dokumen') {
                    _.redirectTo = data
                    window.$('#modalUnderstanding').modal({backdrop: 'static', keyboard: false});
                } else {
                    _.searchFile(data)
                }
            } else {
                _.searchFile(data)
            }
        })
        
        _.$root.$on("searchFile", (data) => {
            _.searchFile( data )
        })
        _.$root.$on("search", (data) => {
            _.search = data
            _.seeAll(_.state.modul)
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.seeAll(_.state.modul)
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.seeAll(_.state.modul)
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.seeAll(_.state.modul)
        })
    },
    beforeDestroy() {
        let _ = this
        _.$root.$off("previewFile")
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")
    },
    watch: {
        activeFile: function()
        {
            let _ = this
            _.$root.$emit("updateFileDetails", _.activeFile)
            if ( _.activeFile ) {
                _.access()
            }
        }
    }
}
</script>