<template>
    <div>
        <Table v-if="!userDetail"
            :withCard="false"
            :dataTable="table"
            :filterUser="true"
            :limit="true"
            :search="true"
            :pagination="true"
            :exportExcel="downloadParams">

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th>Document Number</th>
                        <th>Title</th>
                        <th v-for="(data, index) in maxColumn.type" :key="makeid(10)+index">{{ index == 0 ? 'Type' : 'Sub Type ' + index }}</th>
                        <th v-for="(data, index) in maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Originator</th>
                        <th>Position</th>
                        <th>Effective Date</th>
                        <th>Next Review Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:tbody>
                <tbody v-if="table.lists">
                    <tr v-for="(data, index) in table.lists.data" :key="makeid(10)+index">
                        <td class="text-primary font-600">
                            <u>{{ data.document_number }}-{{ numToSSColumn(data.initial) }}</u>
                        </td>
                        <td style="overflow-wrap: anywhere;">{{ data.title }}</td>
                        <td v-for="(dt, i) in maxColumn.type" :key="makeid(10)+i">
                            <span >
                                {{ JSON.parse(data.type)[i] ? JSON.parse(data.type)[i].code : '-' }}
                            </span>
                        </td>
                        <td v-for="(dt, i) in maxColumn.section" :key="makeid(10)+i">
                            <span >
                                {{ data.parent_section && JSON.parse(data.parent_section)[i] ? JSON.parse(data.parent_section)[i].code : '-' }}
                            </span>
                        </td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="originator in JSON.parse(data.originator)" :key="makeid(10)+originator.id">
                                    {{originator.user_detail}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <div v-for="originator in JSON.parse(data.originator)" :key="makeid(10)+originator.id">
                                <ul class="mb-0 pl-0" style="list-style: none;">
                                    <li v-for="position in JSON.parse(originator.position_id)" :key="makeid(10)+position.id">
                                        {{ position.position }}
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td>{{ data.effective_date | moment("YYYY/MM/DD") }}</td>
                        <td>{{ (data.effective_date_history?( new Date(String(data.effective_date_history)) >= new Date() ? data.next_review_date_history : data.next_review_date ): data.next_review_date ) | moment("YYYY/MM/DD") }}</td>
                        <td>
                            <div class="btn-action-block">
                                <button class="btn p-0 f-18 text-primary mr-1" title="Detail" @click="getDocuments(data)">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </Table>

        <section v-if="userDetail">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <button class="btn btn-sm font-600" style="font-size: 16px;" @click="back()">
                            <i class="fa fa-arrow-left mr-1"></i> Documents Report
                        </button>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-outline-primary" @click="download()">Export Excel</button>
                    </div>
                </div>
            </div>

            <div class="card mb-0">
                <div class="card-body pb-0">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left">Document Number</th>
                                    <th class="text-left">Title</th>
                                    <th class="text-left">Effective Date</th>
                                    <th class="text-left">Next Review Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left"><u>{{ userDetail.document_number }}-{{ numToSSColumn(userDetail.initial) }}</u></td>
                                    <td class="text-left">{{ userDetail.title }}</td>
                                    <td class="text-left">{{ userDetail.effective_date | moment('YYYY/MM/DD') }}</td>
                                    <td class="text-left">{{ userDetail.next_review_date | moment('YYYY/MM/DD') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card mb-0">
                <div class="card-body pb-0">
                    <p class="font-600 f-10" style="padding: 5px 10px;" :style="{ background: '#FFEFEF', color: '#FF5D5D' }">Belum Baca</p>
                    <TableCustom
                        :id="'tableBelumBaca'"
                        :withCard="false"
                        :dataTable="tableBelumBaca"
                        :limit="true"
                        :pagination="true"
                        :custom="true">

                        <template v-slot:thead>
                            <thead>
                                <tr>
                                    <th style="width: 20%">Employee ID</th>
                                    <th style="width: 20%">Name</th>
                                    <th style="width: 20%" class="text-left" v-for="(data, index) in tableBelumBaca.maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                                    <th style="width: 10%">Baca</th>
                                    <th style="width: 10%">Mengerti</th>
                                    <th style="width: 10%">Akses</th>
                                    <th style="width: 10%">Overdue</th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:tbody>
                            <tbody>
                                <tr v-for="(data, index) in tableBelumBaca.lists.data" :key="makeid(10)+index">
                                    <td>{{ data.employee_id }}</td>
                                    <td>{{ data.user_detail }}</td>
                                    <td v-for="(dt, i) in tableBelumBaca.maxColumn.section" :key="makeid(10)+i">
                                        <span >
                                            {{ JSON.parse(data.section)[i] ? JSON.parse(data.section)[i].code : '-' }}
                                        </span>
                                    </td>
                                    <td v-if="data.first_access_date">{{ data.first_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="data.has_understand">{{ data.understand_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="data.last_access_date">{{ data.last_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="userDetail.effective_date">{{ getDiff(userDetail.effective_date) }} Days</td>
                                    <td v-else>-</td>
                                </tr>
                            </tbody>
                        </template>
                    </TableCustom>
                </div>
            </div>
            <hr>
            <div class="card mb-0">
                <div class="card-body pb-0">
                    <p class="font-600 f-10" style="padding: 5px 10px;" :style="{ background: '#E9F0F8', color: '#1D4E9E' }">Belum Mengerti</p>
                    <TableCustom
                        :id="'tableBelumMengerti'"
                        :withCard="false"
                        :dataTable="tableBelumMengerti"
                        :limit="true"
                        :pagination="true"
                        :custom="true">

                        <template v-slot:thead>
                            <thead>
                                <tr>
                                    <th style="width: 20%">Employee ID</th>
                                    <th style="width: 20%">Name</th>
                                    <th style="width: 20%" class="text-left" v-for="(data, index) in tableBelumMengerti.maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                                    <th style="width: 10%">Baca</th>
                                    <th style="width: 10%">Mengerti</th>
                                    <th style="width: 10%">Akses</th>
                                    <th style="width: 10%">Overdue</th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:tbody>
                            <tbody>
                                <tr v-for="(data, index) in tableBelumMengerti.lists.data" :key="makeid(10)+index">
                                    <td>{{ data.employee_id }}</td>
                                    <td>{{ data.user_detail }}</td>
                                    <td v-for="(dt, i) in tableBelumMengerti.maxColumn.section" :key="makeid(10)+i">
                                        <span >
                                            {{ JSON.parse(data.section)[i] ? JSON.parse(data.section)[i].code : '-' }}
                                        </span>
                                    </td>
                                    <td v-if="data.first_access_date">{{ data.first_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="data.has_understand">{{ data.understand_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="data.last_access_date">{{ data.last_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="userDetail.effective_date">{{ getDiff(userDetail.effective_date) }} Days</td>
                                    <td v-else>-</td>
                                </tr>
                            </tbody>
                        </template>
                    </TableCustom>
                </div>
            </div>
            <hr>
            <div class="card mb-0">
                <div class="card-body pb-0">
                    <p class="font-600 f-10" style="padding: 5px 10px;" :style="{ background: '#EBF9F5', color: '#36C697' }">Sudah Mengerti</p>
                    <TableCustom
                        :id="'tableSudahMengerti'"
                        :withCard="false"
                        :dataTable="tableSudahMengerti"
                        :limit="true"
                        :pagination="true"
                        :custom="true">

                        <template v-slot:thead>
                            <thead>
                                <tr>
                                    <th style="width: 20%">Employee ID</th>
                                    <th style="width: 20%">Name</th>
                                    <th style="width: 20%" class="text-left" v-for="(data, index) in tableSudahMengerti.maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                                    <th style="width: 10%">Baca</th>
                                    <th style="width: 10%">Mengerti</th>
                                    <th style="width: 10%">Akses</th>
                                    <th style="width: 10%">Overdue</th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:tbody>
                            <tbody>
                                <tr v-for="(data, index) in tableSudahMengerti.lists.data" :key="makeid(10)+index">
                                    <td>{{ data.employee_id }}</td>
                                    <td>{{ data.user_detail }}</td>
                                    <td v-for="(dt, i) in tableSudahMengerti.maxColumn.section" :key="makeid(10)+i">
                                        <span >
                                            {{ JSON.parse(data.section)[i] ? JSON.parse(data.section)[i].code : '-' }}
                                        </span>
                                    </td>
                                    <td v-if="data.first_access_date">{{ data.first_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="data.has_understand">{{ data.understand_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="data.last_access_date">{{ data.last_access_date | moment('YYYY/MM/DD') }}</td>
                                    <td v-else>-</td>
                                    <td v-if="userDetail.effective_date">{{ getDiff(userDetail.effective_date) }} Days</td>
                                    <td v-else>-</td>
                                </tr>
                            </tbody>
                        </template>
                    </TableCustom>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Table from '@/components/TableTypeSection'
import TableCustom from '@/components/TableCustom'

export default {
    components: { 
        Table,
        TableCustom 
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
                modul: 'doc_report',
                search: _.search,
                effective_date_start: _.filter.effective_date_start,
                effective_date_end: _.filter.effective_date_end,
                type: _.filter.type,
                group: _.filter.group
            }
            return data
        }
    },
    data() {
        return {
            table: {
                headers: ['Document Number', 'Title', '', '', 'Originator', 'Position', 'Reserve Date', 'Action'],
                columns: ['document_number', 'title', 'type', 'section', 'originator', 'position', 'created_at'],
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                group: '',
                position: ''
            },
            userDetail: null,
            dashboards: [],
            tableBelumBaca: {
                headers: ['Employee ID', 'Name', 'Section'],
                columns: ['employee_id', 'user_detail', 'section'],
                lists: [],
                maxColumn: {
                    section: 1
                }
            },
            tableBelumMengerti: {
                headers: ['Employee ID', 'Name', 'Section'],
                columns: ['employee_id', 'user_detail', 'section'],
                lists: [],
                maxColumn: {
                    section: 1
                }
            },
            tableSudahMengerti: {
                headers: ['Employee ID', 'Name', 'Section'],
                columns: ['employee_id', 'user_detail', 'section'],
                lists: [],
                maxColumn: {
                    section: 1
                }
            },
            
            pagetableBelumBaca: 1,
            limittableBelumBaca: 10,

            pagetableBelumMengerti: 1,
            limittableBelumMengerti: 10,

            pagetableSudahMengerti: 1,
            limittableSudahMengerti: 10,
            maxColumn: {
                type: 1,
                section: 1
            }
        }
    },
    methods: {
        getDiff(x) {
            let _ = this
            let expiredAt = _.$moment(x).format('YYYY-MM-DD') + ' 00:00:00'
            let now = _.$moment().format('YYYY-MM-DD') + ' 00:00:00'
            let diff = (_.$moment(expiredAt).diff(_.$moment(now), 'days')) * -1

            return diff > 0 ? '+' + diff : diff
        },
        isJsonString(str) {
            try {
                JSON.parse(str)
            } catch (e) {
                return false
            }
            return true
        },
        index() {
            let _ = this
            _.axios.get('/v2/document/doc_report' +
                    '?search=' + _.search + 
                    '&page=' + _.page + 
                    '&limit=' + _.limit +
                    '&effective_date_start=' + _.filter.effective_date_start +
                    '&effective_date_end=' + _.filter.effective_date_end +
                    '&type=' + _.filter.type +
                    '&group=' + _.filter.group +
                    '&position=' + _.filter.position
                )
                .then(resp=>{
                    _.table.lists = resp.data.data

                    _.maxColumn = {
                        type: 1,
                        section: 1
                    }

                    _.table.lists.data.forEach(elm => {
                        if ( _.maxColumn.type < JSON.parse(elm.type).length ) {
                            _.maxColumn.type = JSON.parse(elm.type).length
                        }

                        if ( _.maxColumn.section < JSON.parse(elm.parent_section).length ) {
                            _.maxColumn.section = JSON.parse(elm.parent_section).length
                        }
                    })

                    _.table.lists.data = _.table.lists.data.map(function( x ) {
                        return {
                            ...x,
                            initial: x.is_edit_disable == true ? parseInt(x.initial)-1 : parseInt((x.initial)),
                            effective_date: x.is_edit_disable == true ? x.effective_date_history : x.effective_date,
                            // next_review_date: x.is_edit_disable == true ? x.next_review_date_history : x.next_review_date,
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getDocuments(data) {
            let _ = this
            _.userDetail = data

            _.getTableBelumBaca()
            _.getTableBelumMengerti()
            _.getTableSudahMengerti()
        },
        getTableBelumBaca() {
            let _ = this
            _.axios.get('/v2/document/document_report_unread/' + _.userDetail.id + '?page=' + _.pagetableBelumBaca + '&limit=' + _.limittableBelumBaca)
                .then(resp=>{
                    _.tableBelumBaca.lists = resp.data.data

                    _.tableBelumBaca.maxColumn = {
                        section: 1
                    }

                    _.tableBelumBaca.lists.data.forEach(elm => {
                        if ( _.tableBelumBaca.maxColumn.section < JSON.parse(elm.section).length ) {
                            _.tableBelumBaca.maxColumn.section = JSON.parse(elm.section).length
                        }
                    })

                    console.log(_.tableBelumBaca.maxColumn)
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getTableBelumMengerti() {
            let _ = this
            _.axios.get('/v2/document/document_report_not_understand/' + _.userDetail.id + '?page=' + _.pagetableBelumMengerti + '&limit=' + _.limittableBelumMengerti)
                .then(resp=>{
                    _.tableBelumMengerti.lists = resp.data.data

                    _.tableBelumMengerti.maxColumn = {
                        section: 1
                    }

                    _.tableBelumMengerti.lists.data.forEach(elm => {
                        if ( _.tableBelumMengerti.maxColumn.section < JSON.parse(elm.section).length ) {
                            _.tableBelumMengerti.maxColumn.section = JSON.parse(elm.section).length
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getTableSudahMengerti() {
            let _ = this
            _.axios.get('/v2/document/document_report_understand/' + _.userDetail.id + '?page=' + _.pagetableSudahMengerti + '&limit=' + _.limittableSudahMengerti)
                .then(resp=>{
                    _.tableSudahMengerti.lists = resp.data.data

                    _.tableSudahMengerti.maxColumn = {
                        section: 1
                    }

                    _.tableSudahMengerti.lists.data.forEach(elm => {
                        if ( _.tableSudahMengerti.maxColumn.section < JSON.parse(elm.section).length ) {
                            _.tableSudahMengerti.maxColumn.section = JSON.parse(elm.section).length
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        back() {
            let _ = this
            _.userDetail = null
            _.pagetableBelumBaca = 1
            _.limittableBelumBaca = 10
            _.pagetableBelumMengerti = 1
            _.limittableBelumMengerti = 10
            _.pagetableSudahMengerti = 1
            _.limittableSudahMengerti = 10
        },
        download() {
            let _ = this

            _.axios.get('/v2/document/threepoint_export/' + _.userDetail.id,{ responseType: 'arraybuffer' })
            .then(resp => {
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'document-report-' + _.$moment().format('DDMMYYYYhhmmss') + '.xlsx'
                link.click()
            })
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    mounted() {
        let _ = this        
        _.index()
        _.$root.$on("search", (data) => {
            _.search = data
            _.index()
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.index()
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.index()
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.index()
        })

        _.$root.$on("pagetableBelumBaca", (data) => {
            _.pagetableBelumBaca = data
            _.getTableBelumBaca()
        })
        _.$root.$on("limittableBelumBaca", (data) => {
            _.limittableBelumBaca = data
            _.pagetableBelumBaca = 1
            _.getTableBelumBaca()
        })
        
        _.$root.$on("pagetableBelumMengerti", (data) => {
            _.pagetableBelumMengerti = data
            _.getTableBelumMengerti()
        })
        _.$root.$on("limittableBelumMengerti", (data) => {
            _.limittableBelumMengerti = data
            _.pagetableBelumMengerti = 1
            _.getTableBelumMengerti()
        })
        
        _.$root.$on("pagetableSudahMengerti", (data) => {
            _.pagetableSudahMengerti = data
            _.getTableSudahMengerti()
        })
        _.$root.$on("limittableSudahMengerti", (data) => {
            _.limittableSudahMengerti = data
            _.pagetableSudahMengerti = 1
            _.getTableSudahMengerti()
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")

        _.$root.$off("pagetableBelumBaca")
        _.$root.$off("limittableBelumBaca")

        _.$root.$off("pagetableBelumMengerti")
        _.$root.$off("limittableBelumMengerti")

        _.$root.$off("pagetableSudahMengerti")
        _.$root.$off("limittableSudahMengerti")
    },
}
</script>