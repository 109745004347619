<template>
    <div>
        <Table
            :withCard="true"
            :dataTable="table"
            :filter="true"
            :limit="true"
            :search="true"
            :exportExcel="downloadParams"
            :pagination="true">

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th>Document Number</th>
                        <th>Title</th>
                        <th v-for="(data, index) in maxColumn.type" :key="makeid(10)+index">{{ index == 0 ? 'Type' : 'Sub Type ' + index }}</th>
                        <th v-for="(data, index) in maxColumn.section" :key="makeid(10)+index">{{ index == 0 ? 'Section' : 'Sub Section ' + index }}</th>
                        <th>Originator</th>
                        <th>Position</th>
                        <th>Reserve Date</th>
                        <!-- <th>Next Review Date</th> -->
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:tbody>
                <tbody v-if="table.lists">
                    <tr v-for="(data, index) in table.lists.data" :key="makeid(10)+index">
                        <td class="text-primary font-600">
                            <u>{{ data.document_number }}-{{ numToSSColumn(data.initial) }}</u>
                        </td>
                        <td style="overflow-wrap: anywhere;">{{ data.title }}</td>
                        <td v-for="(dt, i) in maxColumn.type" :key="makeid(10)+i">
                            <span >
                                {{ JSON.parse(data.type)[i] ? JSON.parse(data.type)[i].code : '-' }}
                            </span>
                        </td>
                        <td v-for="(dt, i) in maxColumn.section" :key="makeid(10)+i">
                            <span >
                                {{ data.parent_section && JSON.parse(data.parent_section)[i] ? JSON.parse(data.parent_section)[i].code : '-' }}
                            </span>
                        </td>
                        <td>
                            <ul class="mb-0 pl-0" style="list-style: none;">
                                <li v-for="originator in JSON.parse(data.originator)" :key="makeid(10)+originator.id">
                                    {{originator.user_detail}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <div v-for="originator in JSON.parse(data.originator)" :key="makeid(10)+originator.id">
                                <ul class="mb-0 pl-0" style="list-style: none;">
                                    <li v-for="position in JSON.parse(originator.position_id)" :key="makeid(10)+position.id">
                                        {{ position.position }}
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td>{{ data.reserve_date | moment("YYYY/MM/DD") }}</td>
                        <!-- <td>{{ data.next_review_date | moment("YYYY/MM/DD") }}</td> -->
                        <td>
                            <div class="btn-action-block">
                                <button class="btn p-0 f-18 text-success mr-1" title="Register" @click="showForm(data)">
                                    <img :src="$siteUrl + '/assets/img/icon/send.png'" alt="" class="img-fluid">
                                </button>
                                <button class="btn p-0 f-18 text-danger" title="Delete" @click="destroy(data)">
                                    <img :src="$siteUrl + '/assets/img/icon/trash.png'" alt="" class="img-fluid">
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </Table>
    </div>
</template>
<script>
import Table from '@/components/TableTypeSection'

export default {
    components: { 
        Table 
    },
    computed: {
        downloadParams: function() {
            let _ = this
            let data = {
                page: 1,
                limit: _.table.lists && _.table.lists.meta ? _.table.lists.meta.total : 0,
                modul: 'reservation',
                search: _.search,
                effective_date_start: _.filter.effective_date_start,
                effective_date_end: _.filter.effective_date_end,
                type: _.filter.type,
                group: _.filter.group
            }
            return data
        }
    },
    data() {
        return {
            table: {
                lists: null
			},
            search: '',
            page: 1,
            limit: 10,
            filter: {
                effective_date_start: '',
                effective_date_end: '',
                type: '',
                group: ''
            },
            maxColumn: {
                type: 1,
                section: 1
            }
        }
    },
    methods: {
        showForm(data) {
            let _ = this
            _.$root.$emit('showFormRegistration', data)
        },
        index() {
            let _ = this
            _.axios.get('/v2/document/reservation' +
                    '?search=' + _.search + 
                    '&page=' + _.page + 
                    '&limit=' + _.limit +
                    '&effective_date_start=' + _.filter.effective_date_start +
                    '&effective_date_end=' + _.filter.effective_date_end +
                    '&type=' + _.filter.type +
                    '&group=' + _.filter.group
                )
                .then(resp=>{
                    _.table.lists = resp.data.data

                    _.maxColumn = {
                        type: 1,
                        section: 1
                    }

                    _.table.lists.data.forEach(elm => {
                        if ( _.maxColumn.type < JSON.parse(elm.type).length ) {
                            _.maxColumn.type = JSON.parse(elm.type).length
                        }

                        if ( elm.parent_section && _.maxColumn.section < JSON.parse(elm.parent_section).length ) {
                            _.maxColumn.section = JSON.parse(elm.parent_section).length
                        }
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus dokumen ini?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.put('/v1/repositories/delete/' + x.id, { is_delete: 'true' })
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
    },
    mounted() {
        let _ = this        
        _.index()
        _.$root.$on("search", (data) => {
            _.search = data
            _.index()
        })
        _.$root.$on("limit", (data) => {
            _.limit = data
            _.index()
        })
        _.$root.$on("page", (data) => {
            _.page = data
            _.index()
        })
        _.$root.$on("filter", (data) => {
            _.filter = data
            _.index()
        })
    },
    beforeDestroy(){
        let _ = this
        _.$root.$off("search")
        _.$root.$off("limit")
        _.$root.$off("page")
        _.$root.$off("filter")
    },
}
</script>