<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Label</th>
                    <th>Font Color</th>
                    <th>Header Background Color</th>
                    <th>Background Color</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="dashboard in dashboards" :key="dashboard.id">
                    <td class="font-500">{{ dashboard.label }}</td>
                    <td><div class="row col"><div class="square" :style="{ background: dashboard.color }"></div>{{ dashboard.color }}</div></td>
                    <td><div class="row col"><div class="square" :style="{ background: dashboard.header }"></div>{{ dashboard.header }}</div></td>
                    <td><div class="row col"><div class="square" :style="{ background: dashboard.background }"></div>{{ dashboard.background }}</div></td>
                    <td style="white-space: nowrap;">
                        <button class="btn p-0 f-18 text-primary" data-toggle="modal" data-target="#modalDashboard" title="Edit" @click="detail = JSON.parse(JSON.stringify(dashboard)), backupDetail = JSON.parse(JSON.stringify(dashboard))">
                            <img :src="$siteUrl + '/assets/img/icon/edit.png'" alt="" class="img-fluid">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="modal fade text-left show" id="modalDashboard" tabindex="-1" role="dialog" v-if="detail">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <p class="modal-title align-self-center">Edit Label</p>
                        <span class="pull-right pointer" data-dismiss="modal"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="modal-body p-1">
                        <ValidationObserver ref="observer">
                            <form @submit.prevent="update()">
                                <ValidationProvider name="Nama Label" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="">Nama Label</label>
                                        <input type="text" class="form-control" v-model="detail.label">
                                        <small class="f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="form-group">
                                    <ValidationProvider name="Font Color" rules="required" v-slot="{ errors }">
                                        <label for="">Font Color</label>
                                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control border-right-0 bg-white" v-model="detail.color">
                                            <span class="input-group-append pointer">
                                                <div class="input-group-text pl-1 pr-1 pt-0 pb-0">
                                                    <v-input-colorpicker v-model="detail.color"/>
                                                </div>
                                            </span>
                                            <small class="w-100 f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <ValidationProvider name="Header Background Color" rules="required" v-slot="{ errors }">
                                        <label for="">Header Background Color</label>
                                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control border-right-0 bg-white" v-model="detail.header">
                                            <span class="input-group-append pointer">
                                                <div class="input-group-text pl-1 pr-1 pt-0 pb-0">
                                                    <v-input-colorpicker v-model="detail.header"/>
                                                </div>
                                            </span>
                                            <small class="w-100 f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <ValidationProvider name="Background Color" rules="required" v-slot="{ errors }">
                                        <label for="">Background Color</label>
                                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control border-right-0 bg-white" v-model="detail.background">
                                            <span class="input-group-append pointer">
                                                <div class="input-group-text pl-1 pr-1 pt-0 pb-0">
                                                    <v-input-colorpicker v-model="detail.background"/>
                                                </div>
                                            </span>
                                            <small class="w-100 f-10 text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="text-center mt-1">
                                    <button class="btn text-primary btn-sm" type="button" @click="detail = JSON.parse(JSON.stringify(backupDetail))">Reset</button>
                                    <button class="btn btn-primary text-primary btn-sm" type="submit">Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputColorPicker from 'vue-native-color-picker'

export default {
    components: {
        "v-input-colorpicker": InputColorPicker
    },
    data() {
        return {
            dashboards: [],
            detail: null,
            backupDetail: null
        }
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/v1/repositories/dashboard')
                .then(resp=>{
                    _.dashboards = resp.data.data
                    _.hide_modal()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async update() {
            let _ = this
            
            const isValid = await this.$refs.observer.validate()
            if (!isValid){
                return
            }

            _.axios.put('/v1/repositories/dashboard/' + _.detail.id, _.detail)
                .then(()=>{
                    _.index()
                    _.$notify({
                        type: 'success',
                        title: 'Success!'
                    })
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        hide_modal() {
            window.$('#modalDashboard').hide()
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
        },
    },
    mounted() {
        let _ = this

        _.index()
    },
}
</script>